import React, { useState, useEffect } from "react";
import useInput from "../utils/hooks/use-input";
import {
  nameMask,
  cpfMask,
  cnpjMask,
  dateMask,
  percentageMask,
  formatToBrMoney,
  formatToPhone,
  justNumbers,
  credit_type_enumList,
  interest_type_enumList,
  isNaturalDocumentNumber,
  isLegalDocumentNumber,
  isNaturalName,
  isLegalname,
  isEmail,
  isDate,
  isPhone,
  valueIsNotZero,
  percentageIsNotZero,
  isNotZero,
  getConfirmationIcon,
  credit_translations,
  formFieldsHandler,
  getDateFormated,
  getAnnualInterestRate,
  buildSCRSettings,
  authorization_type_enumList
} from "../utils/utils";
import { useTranslation } from "react-i18next";
import "../../../assets/styles/generalStyles.scss";
import FormTitle from "../utils/Components/FormComponents/FormTitle";
import FormField from "../utils/Components/FormComponents/FormField";
import FormDropdownList from "../utils/Components/FormComponents/FormDropdownList";
import FormButton from "../utils/Components/FormComponents/FormButton";
import FormUploadFile from "../utils/Components/FormComponents/FormUploadFile";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import Button from "../utils/Components/Button";
import {
  buildInputSettings
} from "./InputSettings";
import moment from 'moment';

const InputContent = (props) => {

  const {
    business_group_key,
    company_key,
    setSelectCompany,
    setPageParams,
    onClose,
    person_type,
    local_storage_object_preffix
  } = props;

  const { t } = useTranslation();

  const [formSubmissionPending, setFormSubmissionPending] = useState(false);
  const [formSubmissionStatus, setFormSubmissionStatus] = useState(null);

  const handleBackClick = () => {
    setSelectCompany({
      status: true,
    })
  };

  const handleReturn = () => {
    onClose();
    setPageParams({
      page: 1,
      filters: JSON.parse(
        sessionStorage.getItem(
          local_storage_object_preffix + "PageParams"
        )
      ).filters,
    })
  };

  const [formFields, setFormFields] = useState(undefined)

  useEffect(() => {
    axios
      .get('/dash/credit_analysis/company')
      .then(response=>{
        let companiesList = {...response.data}
        companiesListHandler(companiesList,company_key,business_group_key, person_type)
      })
      .catch(error => {
        console.log((error.response || {}).status)
      })
  },[company_key,business_group_key, person_type])

  const companiesListHandler = (companiesList, company_key, business_group_key, person_type) => {
    const scr_input_settings = buildSCRSettings(companiesList, business_group_key)
    const input_settings = buildInputSettings(company_key, scr_input_settings)
    setFormFields(formFieldsHandler(person_type, input_settings, company_key))
  }

  const {
    value: enteredNaturalDocumentNumber,
    isValid: enteredNaturalDocumentNumberIsValid,
    hasError: naturalDocumentNumberInputHasError,
    valueChangeHandler: naturalDocumentNumberChangeHandler,
    inputBlurHandler: naturalDocumentNumberBlurHandler,
    reset: resetNaturalDocumentNumberInput,
  } = useInput(isNaturalDocumentNumber);

  const {
    value: enteredLegalDocumentNumber,
    isValid: enteredLegalDocumentNumberIsValid,
    hasError: legalDocumentNumberInputHasError,
    valueChangeHandler: legalDocumentNumberChangeHandler,
    inputBlurHandler: legalDocumentNumberBlurHandler,
    reset: resetLegalDocumentNumberInput,
  } = useInput(isLegalDocumentNumber);

  const {
    value: enteredNaturalName,
    isValid: enteredNaturalNameIsValid,
    hasError: naturalNameInputHasError,
    valueChangeHandler: naturalNameChangeHandler,
    inputBlurHandler: naturalNameBlurHandler,
    reset: resetNaturalNameInput,
  } = useInput(isNaturalName);

  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: emailInputHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmailInput,
  } = useInput(isEmail);

  const {
    value: enteredLegalName,
    isValid: enteredLegalNameIsValid,
    hasError: legalNameInputHasError,
    valueChangeHandler: legalNameChangeHandler,
    inputBlurHandler: legalNameBlurHandler,
    reset: resetLegalNameInput,
  } = useInput(isLegalname);

  const {
    value: enteredSignerDocumentNumber,
    isValid: enteredSignerDocumentNumberIsValid,
    hasError: signerDocumentNumberInputHasError,
    valueChangeHandler: signerDocumentNumberChangeHandler,
    inputBlurHandler: signerDocumentNumberBlurHandler,
    reset: resetSignerDocumentNumberInput,
  } = useInput(isNaturalDocumentNumber);

  const {
    value: enteredSignerName,
    isValid: enteredSignerNameIsValid,
    hasError: signerNameInputHasError,
    valueChangeHandler: signerNameChangeHandler,
    inputBlurHandler: signerNameBlurHandler,
    reset: resetSignerNameInput,
  } = useInput(isNaturalName);

  const [enteredAuthorizationType, setAuthorizationType] = useState('email');
  const authorizationTypeChangeHandler = (enteredAuthorizationType) => {
    setAuthorizationType(enteredAuthorizationType);
  };

  const {
    value: enteredSignerPhone,
    isValid: enteredSignerPhoneIsValid,
    hasError: signerPhoneInputHasError,
    valueChangeHandler: signerPhoneChangeHandler,
    inputBlurHandler: signerPhoneBlurHandler,
    reset: resetSignerPhoneInput,
  } = useInput(isPhone);

  const {
    value: enteredClientSince,
    isValid: enteredClientSinceIsValid,
    hasError: clientSinceInputHasError,
    valueChangeHandler: clientSinceChangeHandler,
    inputBlurHandler: clientSinceBlurHandler,
    reset: resetClientSinceInput,
  } = useInput(isDate);

  const {
    value: enteredMonthlyIncome,
    isValid: enteredMonthlyIncomeIsValid,
    hasError: monthlyIncomeInputHasError,
    valueChangeHandler: monthlyIncomeChangeHandler,
    inputBlurHandler: monthlyIncomeBlurHandler,
    reset: resetMonthlyIncomeInput,
  } = useInput(valueIsNotZero);

  const {
    value: enteredMonthlyRevenue,
    isValid: enteredMonthlyRevenueIsValid,
    hasError: monthlyRevenueInputHasError,
    valueChangeHandler: monthlyRevenueChangeHandler,
    inputBlurHandler: monthlyRevenueBlurHandler,
    reset: resetMonthlyRevenueInput,
  } = useInput(valueIsNotZero);

  const [enteredCreditType, setCreditType] = useState('clean');
  const creditTypeChangeHandler = (enteredCreditType) => {
    setCreditType(enteredCreditType);
  };

  const {
    value: enteredAmount,
    isValid: enteredAmountIsValid,
    hasError: amountInputHasError,
    valueChangeHandler: amountChangeHandler,
    inputBlurHandler: amountBlurHandler,
    reset: resetAmountInput,
  } = useInput(valueIsNotZero);

  const {
    value: enteredMonthlyInterestRate,
    isValid: enteredMonthlyInterestRateIsValid,
    hasError: monthlyInterestRateInputHasError,
    valueChangeHandler: monthlyInterestRateChangeHandler,
    inputBlurHandler: monthlyInterestRateBlurHandler,
    reset: resetMonthlyInterestRateInput,
  } = useInput(percentageIsNotZero);

  const [enteredInterestType, setInterestType] = useState("not_applied");
  const interestTypeChangeHandler = (enteredInterestType) => {
    setInterestType(enteredInterestType);
  };

  const [enteredUploadFile, setUploadFile] = useState(false);
  const fileChangeHandler = (enteredUploadFile) => {
    setUploadFile(enteredUploadFile);
  };

  const {
    value: enteredCDIPercentage,
    isValid: enteredCDIPercentageIsValid,
    hasError: CDIPercentageInputHasError,
    valueChangeHandler: CDIPercentageChangeHandler,
    inputBlurHandler: CDIPercentageBlurHandler,
    reset: resetCDIPercentageInput,
  } = useInput(percentageIsNotZero);

  const {
    value: enteredNumberOfInstallments,
    isValid: enteredNumberOfInstallmentsIsValid,
    hasError: numberOfInstallmentsInputHasError,
    valueChangeHandler: numberOfInstallmentsChangeHandler,
    inputBlurHandler: numberOfInstallmentsBlurHandler,
    reset: resetNumberOfInstallmentsInput,
  } = useInput(isNotZero);

  const formIsValid = () => {
    if (formFields["natural_document_number"]&&!enteredNaturalDocumentNumberIsValid) return false
    else if (formFields["natural_person_name"]&&!enteredNaturalNameIsValid) return false
    else if (formFields["legal_document_number"]&&!enteredLegalDocumentNumberIsValid) return false
    else if (formFields["legal_name"]&&!enteredLegalNameIsValid) return false
    else if (formFields["signer_document_number"]&&!enteredSignerDocumentNumberIsValid) return false
    else if (formFields["signer_name"]&&!enteredSignerNameIsValid) return false
    else if (formFields["authorization_type"]&&((enteredAuthorizationType==='sms')||(enteredAuthorizationType==='whatsapp'))&&!enteredSignerPhoneIsValid) return false
    else if (formFields["authorization_type"]&&(enteredAuthorizationType==='email')&&!enteredEmailIsValid) return false
    else if (formFields["upload_PDF_base64"]&&!enteredUploadFile) return false
    else if (formFields["client_since"]&&!enteredClientSinceIsValid) return false
    else if (formFields["monthly_income"]&&!enteredMonthlyIncomeIsValid) return false
    else if (formFields["monthly_revenue"]&&!enteredMonthlyRevenueIsValid) return false
    else if (formFields["credit_proposal_amount"]&&!enteredAmountIsValid) return false
    else if ((enteredInterestType === ("price")||enteredInterestType === ("pre_fixed"))&&!enteredMonthlyInterestRateIsValid) return false
    else if ((enteredInterestType === ("cdi_plus")||enteredInterestType === ("cdi_percentage"))&&!enteredCDIPercentageIsValid) return false
    else if (formFields["number_of_installments"]&&!enteredNumberOfInstallmentsIsValid) return false
    else return true
  }

  const resetAllFields = () => {
    resetNaturalDocumentNumberInput();
    resetLegalDocumentNumberInput();
    resetNaturalNameInput();
    resetLegalNameInput();
    resetEmailInput();
    resetSignerDocumentNumberInput();
    resetSignerNameInput();
    resetSignerPhoneInput();
    resetClientSinceInput();
    resetMonthlyIncomeInput();
    resetMonthlyRevenueInput();
    resetAmountInput();
    resetMonthlyInterestRateInput();
    resetCDIPercentageInput();
    resetNumberOfInstallmentsInput();
  };

  const buildPayloadObject = () => {
    let payload = {};
    if (formFields["natural_document_number"]) {payload["document_number"] = cpfMask(enteredNaturalDocumentNumber)}
    if (formFields["natural_person_name"]) {payload["name"] = enteredNaturalName}
    if (formFields["legal_document_number"]) {payload["document_number"] = cnpjMask(enteredLegalDocumentNumber)}
    if (formFields["legal_name"]) {payload["legal_name"] = enteredLegalName}

    let signed_documents_company_keys = ["789-789-789", "2255c314-4dd4-4b8c-bb6a-507fe19b3a49", "43f57d03-e736-4593-ad7b-76a7dd8567bf", "28dc9fda-a27a-46be-91e1-20ab94739a29", "b5605939-5c52-4e6d-8ac3-e5dd98daf72b", "b81a015d-640e-4d11-8921-1ab46f168c8d", "7e4cd65b-4b71-4521-a64e-e1f11839dbe3	"]
    if (signed_documents_company_keys.some(company_key_item => company_key_item === company_key)) {
      payload["scr_parameters"] = {
        "authorization_type":"external_authorization"
      }
    }

    else {
      if (formFields["authorization_type"]) {payload["scr_parameters"] = {"authorization_type":enteredAuthorizationType}}
      if (formFields["signer_document_number"]) {payload["scr_parameters"]["signers"] = [{}]}
      if (formFields["signer_document_number"]) {payload["scr_parameters"]["signers"][0]["document_number"] = cpfMask(enteredSignerDocumentNumber)}
      if (formFields["signer_name"]) {payload["scr_parameters"]["signers"][0]["name"] = enteredSignerName}

      if (person_type==="np"&&formFields["authorization_type"]&&enteredAuthorizationType==='email') {payload["email"] = enteredEmail}
      if (person_type==="lp"&&formFields["authorization_type"]&&enteredAuthorizationType==='email') {payload["scr_parameters"]["signers"][0]["email"] = enteredEmail}

      let phoneObject = {
        "area_code":String(justNumbers(enteredSignerPhone).slice(0,2)),
        "number":String(justNumbers(enteredSignerPhone).slice(2,11)),
        "international_dial_code":"55",
        "type": "mobile"
      }
      if (person_type==="np"&&(enteredAuthorizationType==='sms'||enteredAuthorizationType==='whatsapp')) {
        payload["phones"]=[phoneObject]
      }
      if (person_type==="lp"&&(enteredAuthorizationType==='sms'||enteredAuthorizationType==='whatsapp')) {
        payload["scr_parameters"]["signers"][0]["phone"]=phoneObject
      }
    }
    if (formFields["upload_PDF_base64"]) {payload["upload_PDF_base64"] = enteredUploadFile.base64}
    if (formFields["client_since"]) {payload["client_since"] = getDateFormated(dateMask(enteredClientSince))}
    if (formFields["monthly_income"]) {payload["monthly_income"] = Number(enteredMonthlyIncome.replace(/[,.R$ ]/g, ''))}
    if (formFields["monthly_revenue"]) {payload["monthly_revenue"] = Number(enteredMonthlyRevenue.replace(/[,.R$ ]/g, ''))}
    if (formFields["credit_type"]) {payload["credit_type"] = enteredCreditType}
    if (formFields["credit_proposal_amount"]) {
      payload['financial']= {}
      payload["financial"]["amount"] = Number(enteredAmount.replace(/[,.R$ ]/g, ''))
      payload["financial"]["currency"] = "BRL"
    }
    if ((enteredInterestType ==="price")||(enteredInterestType ==="pre_fixed")) {
      payload["financial"]["interest_type"] = enteredInterestType
      payload["financial"]["annual_interest_rate"] = getAnnualInterestRate(Number(enteredMonthlyInterestRate.replace(/[,. %]/g, '')/100))
    }
    if ((enteredInterestType ==="cdi_plus")||(enteredInterestType ==="cdi_percentage")) {
      payload["financial"]["interest_type"] = enteredInterestType
      payload["financial"]["cdi_percentage"] = Number(enteredCDIPercentage.replace(/[,. %]/g, ''))/100
    }
    if (formFields["number_of_installments"]) {payload["financial"]["number_of_installments"] = Number(enteredNumberOfInstallments)}
    payload["credit_request_date"] = moment(new Date()).format()
    console.log(payload)
    return payload;
  };

  const formSubmissionHandler = (event) => {
    event.preventDefault();
    if (!formIsValid) {return}
    const payload = buildPayloadObject();
    let requestHeaders = { headers: { company_key: company_key } };
    setFormSubmissionPending(true);
    let inputObject = credit_translations.analyze_dialog_type[person_type]
    axios
      .post(
        "/dash/credit_analysis/" + inputObject.post_endpoint,
        payload,
        requestHeaders
      )
      .then((response) => {
        setFormSubmissionPending(false);
        if (response.data.analysis_status === "pending") {
          setFormSubmissionStatus({ success: true, message: t("pending_message") });
        } else {
          setFormSubmissionStatus({ success: true, message: t("success_message") });
          resetAllFields();
        }
      })
      .catch((error) => {
        setFormSubmissionPending(false);
        let errorMessage = t("error_message");
        if ((error.response || {}).status === 400) {
          const { message } = error.response.data;
          if (message === "Invalid document number for SCR consult") {
            errorMessage = t("scr_invalid_document_number_message");
          }
          else if (message === "Invalid signer document number for SCR consult") {
            errorMessage = t("scr_invalid_signer_document_number_message");
          }
        }
        if ((error.response || {}).status === 401) {
            if (((error.response || {}).data || {}).message === "maximum_demo_analysis_limit_reached") {
                errorMessage = "O limite máximo de requisições teste na sua conta demonstração foi excedido."
            }
            else if (((error.response || {}).data || {}).message === "account_manual_requests_unauthorized") {
                errorMessage = "Sua conta não permite análises manuais."
            }
        }
        setFormSubmissionStatus({ success: false, message: errorMessage });
      });
    };

  if ((formFields === undefined) || (formSubmissionPending)) {
    return (
      <div style={{ display: "flex", flexGrow: "1" }}>
        <CircularProgress style={{ margin: "auto" }} />
      </div>
    );
  }

  if (formSubmissionStatus != null) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          flexGrow: "1",
        }}
      >
        <div style={{ margin: "auto" }}>
          {getConfirmationIcon(formSubmissionStatus.success)}
        </div>
        <div style={{ margin: "auto" }}>{formSubmissionStatus.message}</div>
        <Button
          button_option={"standard"}
          onClick={handleReturn}
          buttonLabel={t("go_back_action")}
        />
      </div>
    );
  }

  return (
    <form onSubmit={formSubmissionHandler}>
      {person_type ==="np"&&<FormTitle value={"natural_person_consulted"} />}
      {person_type ==="lp"&&<FormTitle value={"legal_person_consulted"} />}
      {formFields["natural_document_number"]&&
        <FormField
          labelValue={"natural_person_document_number"}
          onChange={naturalDocumentNumberChangeHandler}
          onBlur={naturalDocumentNumberBlurHandler}
          fieldValue={cpfMask(enteredNaturalDocumentNumber)}
          hasError={naturalDocumentNumberInputHasError}
        />}
      {formFields["natural_person_name"]&&
        <FormField
          labelValue={"natural_person_name"}
          onChange={naturalNameChangeHandler}
          onBlur={naturalNameBlurHandler}
          fieldValue={nameMask(enteredNaturalName)}
          hasError={naturalNameInputHasError}
        />}
      {formFields["legal_document_number"]&&
        <FormField
          labelValue={"legal_person_document_number"}
          onChange={legalDocumentNumberChangeHandler}
          onBlur={legalDocumentNumberBlurHandler}
          fieldValue={cnpjMask(enteredLegalDocumentNumber)}
          hasError={legalDocumentNumberInputHasError}
        />}
      {formFields["legal_name"]&&
        <FormField
          labelValue={"legal_name"}
          onChange={legalNameChangeHandler}
          onBlur={legalNameBlurHandler}
          fieldValue={enteredLegalName}
          hasError={legalNameInputHasError}
        />}
      {formFields["client_since"]&&
        <FormField
          labelValue={"client_since"}
          onChange={clientSinceChangeHandler}
          onBlur={clientSinceBlurHandler}
          fieldValue={dateMask(enteredClientSince)}
          hasError={clientSinceInputHasError}
        />}
      {formFields["monthly_income"]&&
        <FormField
          labelValue={"monthly_income"}
          onChange={monthlyIncomeChangeHandler}
          onBlur={monthlyIncomeBlurHandler}
          fieldValue={formatToBrMoney(justNumbers(enteredMonthlyIncome))}
          hasError={monthlyIncomeInputHasError}
        />}
      {formFields["monthly_revenue"]&&
        <FormField
          labelValue={"monthly_revenue"}
          onChange={monthlyRevenueChangeHandler}
          onBlur={monthlyRevenueBlurHandler}
          fieldValue={formatToBrMoney(justNumbers(enteredMonthlyRevenue))}
          hasError={monthlyRevenueInputHasError}
        />}
      {formFields["scr_consent_data_title"]&&person_type === "np"&&<FormTitle value={"natural_person_scr_consent_data"} />}
      {formFields["scr_consent_data_title"]&&person_type === "lp"&&<FormTitle value={"legal_person_scr_consent_data"} />}
      {formFields["signer_document_number"]&&
        <FormField
          labelValue={"signer_document_number"}
          onChange={signerDocumentNumberChangeHandler}
          onBlur={signerDocumentNumberBlurHandler}
          fieldValue={cpfMask(enteredSignerDocumentNumber)}
          hasError={signerDocumentNumberInputHasError}
        />}
      {formFields["signer_name"]&&
        <FormField
          labelValue={"signer_name"}
          onChange={signerNameChangeHandler}
          onBlur={signerNameBlurHandler}
          fieldValue={nameMask(enteredSignerName)}
          hasError={signerNameInputHasError}
        />}
      {formFields["authorization_type"]&&
        <FormDropdownList
          listName={"authorization_type"}
          items={authorization_type_enumList}
          onChangeFilter={authorizationTypeChangeHandler}
          selected={enteredAuthorizationType}
        />}
      {formFields["authorization_type"]&&(enteredAuthorizationType==='email') &&
        <FormField
          labelValue={"signer_email"}
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
          fieldValue={enteredEmail}
          hasError={emailInputHasError}
        />}
      {formFields["authorization_type"]&&((enteredAuthorizationType==='sms')||(enteredAuthorizationType==='whatsapp'))&&
        <FormField
          labelValue={"signer_phone"}
          onChange={signerPhoneChangeHandler}
          onBlur={signerPhoneBlurHandler}
          fieldValue={formatToPhone(justNumbers(enteredSignerPhone))}
          hasError={signerPhoneInputHasError}
        />}
      {formFields["upload_PDF_base64"]&&
        <FormUploadFile
          labelValue={`${t("document_owner_consent")} (${t(
            "maximum_file_size_allowed"
          )}: 3MB)`}
          onDone={fileChangeHandler.bind(this)}
          accept=".pdf"
          fileSize={3000000}
        />}
      {(formFields["credit_proposal_amount"]||formFields["credit_type"])&&
      <FormTitle value={"collect_financial_data"} />}
      {formFields["credit_type"]&&
        <FormDropdownList
          listName={"credit_type"}
          items={credit_type_enumList}
          onChangeFilter={creditTypeChangeHandler}
          selected={enteredCreditType}
        />}
      {formFields["credit_proposal_amount"]&&
        <FormField
          labelValue={"credit_proposal_amount"}
          onChange={amountChangeHandler}
          onBlur={amountBlurHandler}
          fieldValue={formatToBrMoney(justNumbers(enteredAmount))}
          hasError={amountInputHasError}
        />}
      {formFields["interest_type"]&&
        <FormDropdownList
          listName={"interest_type"}
          items={interest_type_enumList}
          onChangeFilter={interestTypeChangeHandler}
          selected={enteredInterestType}
        />}
        {(enteredInterestType === ("cdi_plus")||enteredInterestType === ("cdi_percentage"))&&
        <FormField
          labelValue={"cdi_percentage"}
          onChange={CDIPercentageChangeHandler}
          onBlur={CDIPercentageBlurHandler}
          fieldValue={percentageMask(justNumbers(enteredCDIPercentage))}
          hasError={CDIPercentageInputHasError}
        />}
        {(enteredInterestType === ("price")||enteredInterestType === ("pre_fixed"))&&
        <FormField
          labelValue={"monthly_interest_rate"}
          onChange={monthlyInterestRateChangeHandler}
          onBlur={monthlyInterestRateBlurHandler}
          fieldValue={percentageMask(justNumbers(enteredMonthlyInterestRate))}
          hasError={monthlyInterestRateInputHasError}
        />}
        {formFields["number_of_installments"]&&
        <FormField
            labelValue={"number_of_installments"}
            onChange={numberOfInstallmentsChangeHandler}
            onBlur={numberOfInstallmentsBlurHandler}
            fieldValue={justNumbers(enteredNumberOfInstallments)}
            hasError={numberOfInstallmentsInputHasError}
          />}
      <FormButton
        formIsValid={formIsValid()}
        business_group_key={business_group_key}
        handleBackClick={handleBackClick}
        onSubmit={formSubmissionHandler}
      />
    </form>
  )
} 

export default InputContent;
