import styled from "styled-components";


export const AccessAccountButton = styled.button`
  font-size: 20px;
  line-height: 16px;
  margin: auto;
  justify-content: center;
  align-items: center;
  color:rgb(4, 22, 114);
  border: 2px solid #0b1f82;
  background: transparent;
  padding: 10px 20px;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  border-radius:10px;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.12));
  &:hover {
    background: #0b1f82;
    color: white;
  }
`;


