import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 21px;
    border: 1px solid hsla(0,0%,80%,.8);
    background-color: white;
    border-radius: 10px;
    width: 100%;
    overflow-x: hidden;
    position: relative;
`

export const Title = styled.p`
    margin: 0 0 24px 0;
    font-size: 16px;
    color: #0b1f82;
    font-weight: 600;
    line-height: 20px;
`;

export const Wrapper = styled.div`
    width: 100%;
    border-radius: 8px;
    background-color: #fafafa;
    border: 1px solid rgba(204, 204, 204, 0.8);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 24px;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap;
    row-gap: 24px;
`;

export const CardTitle = styled.p`
    width: 100%;
    color: #0b1f82;
    font-weight: 600;
    padding-bottom: 16px;
    border-bottom: 1px solid #dfdfdf;
`;

export const PartnerName = styled.p`
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #0b1f82;
`;

export const CardDescription = styled.p`
    margin: 0 0 8px 0;
    font-size: 14px;
`;

export const Data = styled.p`
    font-size: 14px;
    color: #444;
    margin: 0;
`;

export const GreenBadge = styled.div`
    background-color: #6dd67d55;
    color: #00c41d;
    font-weight: bold;
    border-radius: 4px;
    width: fit-content;
    padding: 0 8px;
    margin: auto;

`;
export const RedBadge = styled.div`
    background-color: #ff828255;
    font-weight: bold;
    color: #a60505;
    border-radius: 4px;
    width: fit-content;
    padding: 0 8px;
    margin: auto;
`;

export const Toggle = styled.div`
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;
    color: #aaa;
`;


export const Button = styled.button``;