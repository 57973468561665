import React from 'react'
import alarmIcon from "../../../../../assets/cardEntryIcons/alarm.svg"
import {
    getSeverityStatus, getSeverityColor,
    getAlertStatusText, getAlertStatusColor, getEventTypeName, getAccountLabel
} from "../../../utils/utilsAlert"
import AccountMonitoringTrigger from './Triggers/AccountMonitoringTrigger'
import { Tooltip} from "@material-ui/core";

function AccountMonitoringAlertsCard(props) {

    return (
        <div className="analysisCard">
            <div style={{ display: "flex" }}>
                <img className="cardTitleIconStyle" style={{ maxHeight: "19px" }} src={alarmIcon} alt="alarmIcon Logo" />
                <span className={["blueText", "titleSize", "labelSpace"].join(" ")}>Resumo do Alerta</span>
                <div className={["listCardItem", "normalText", "normalSmallSize"].join(" ")} style={{ marginLeft: "auto" }} >
                    <div className="statusTag" style={{ backgroundColor: getSeverityColor(props.alert.risk_level), fontSize: "15px" }}>
                        {getSeverityStatus(props.alert.risk_level)}
                    </div>
                </div>
            </div>
            <div className="internalCardContainer" style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex" }}>
                    <div className={["normalText", "normalMediumSize3", "noWrap"].join(" ")}>
                        Tópico de Monitoramento:
                    </div>
                    <div
                        className={["normalText", "normalMediumSize3", "labelSpace", "bold"].join(" ")}
                    >
                        {getEventTypeName(props.alert.event_type)}
                    </div>
                </div>
                <div style={{ display: "flex", paddingTop: "10px" }}>
                    <div className={["normalText", "normalMediumSize3", "noWrap"].join(" ")}>
                        Tipo de Conta:
                    </div>
                    <div
                        className={["normalText", "normalMediumSize3", "labelSpace", "bold"].join(" ")}
                    >
                        {getAccountLabel(props.alert.triggers[0].category)}
                    </div>
                </div>
                <div style={{ display: "flex", paddingTop: "10px" }}>
                    <div className={["normalText", "normalMediumSize3", "noWrap"].join(" ")}>
                        Status:
                    </div>
                    <div
                        className={["normalText", "normalMediumSize3", "labelSpace", "bold"].join(" ")}
                        style={{ color: getAlertStatusColor(props.alert.alert_status) }}
                    >
                        {getAlertStatusText(props.alert.alert_status)}
                    </div>
                </div>
            </div>
            <div className="internalCardContainer" style={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className={["blueText", "normalMediumSize3"].join(" ")}>
                        Gatilhos:
                    </div>
                    <Tooltip title={<p className="tooltipText">{"Quantidade total de gatilhos por alerta"}</p>}>
                        <div style={{ marginLeft: '5px', fontSize:"23px" }}>
                            {props.alert.triggers.length}
                        </div>
                    </Tooltip>
                </div>
                <AccountMonitoringTrigger triggers={props.alert.triggers} />
            </div>
        </div>
    )
}

export default AccountMonitoringAlertsCard


