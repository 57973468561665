import React, {useContext} from "react";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import {getSeverityColor, getSeverityStatus, 
        getSeverityStatusSiglum, getAlertStatusColor, 
        getAlertStatusText, getAlertStatusTextSiglum} from "../../utils/utilsAlert"
import moment from 'moment'
import Tooltip from "@material-ui/core/Tooltip";
import AuthContext from "../../../../context/auth-context";
import {getEventTypeName} from "../../utils/utilsAlert"

function AlertCard(props){

    let business_group_key = useContext(AuthContext).user_data.business_group_key 

    return (
        <Card className="listCard">
            <Link
                className={["listCardItem", "link", "subtitleSize", "breakWord", "bold"].join(" ")}
                style={{flex:"2 1 100px", textAlign:"center"}}
                to={"/account-monitoring-alert/" + props.alert.alert_key}
            >
                {(props.alert.link_key||props.alert.alert_key)}
            </Link>
            {business_group_key&&
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"2 1 180px", textAlign:"center"}}
            >
                {props.alert.company_name}
            </div>
            }
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 180px", textAlign:"center"}}
            >
                {props.alert.event_type ?
                    getEventTypeName(props.alert.event_type)
                : "-"}
            </div> 
            <Tooltip title={<p className="tooltipText">Quantidade de gatilhos no alerta</p>}>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
            >
                <div className="triggerNumberStyle">
                    {props.alert.triggers_count}
                </div>               
            </div>
            </Tooltip>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
            >
                <Tooltip title={<p className="tooltipText">{"Severidade do alerta: " + getSeverityStatus(props.alert.risk_level)}</p>}>
                    <div className={["circle", "small"].join(" ")} style={{backgroundColor:getSeverityColor(props.alert.risk_level)}}>
                        {getSeverityStatusSiglum(props.alert.risk_level)}
                    </div>
                </Tooltip>
            </div>
            <div 
                className={["listCardItem", "normalText", "normalMediumSize", "bold"].join(" ")} 
                style={{flex:"1 1 45px", textAlign:"center", cursor: "default"}}  
            >
                <Tooltip title={<p className="tooltipText">{getAlertStatusText(props.alert.alert_status)}</p>}>
                    <div className={["circle", "small"].join(" ")} style={{backgroundColor:getAlertStatusColor(props.alert.alert_status)}}>
                        {getAlertStatusTextSiglum(props.alert.alert_status)}
                    </div>
                </Tooltip>
            </div>
            <div 
                className={["listCardItem", "normalText", "subtitleSize"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                {moment(props.alert.occurrence_date).format("DD/MM/YYYY HH:mm:ss")}
            </div>
        </Card>
    )
}

export default AlertCard