import React, { useEffect, useReducer, useCallback, useContext, useState } from "react";
import {
  dataFetchReducer,
  useStateConnectedToCache,
} from "../../Utils/requestUtils";
import Filter from "./Filter/Filter";
import Pagination from "@material-ui/lab/Pagination";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import AlertCard from "./AlertCard/AlertCard";
import ErrorBoundary from "../../Utils/ErrorBoundary";
import {setAlertsInSessionStorage, 
        setGetAlertsParamsInSessionStorage } from "../utils/utilsAlert" 
import AuthContext from "../../../context/auth-context";
import { useHistory } from "react-router-dom";
import CardListHeader from "./CardListHeader/CardListHeader";

function AccountMonitoringAlertsInbox(props) {

    let history = useHistory();

    let roles = useContext(AuthContext).user_data.roles;
    if (!(['read_account_monitoring_alerts'].some(role => roles.includes(role)))) {
        history.push("");
    }

    let local_storage_object_preffix = "account_monitoring_alerts";

    const [pageParams, setPageParams] = useStateConnectedToCache(
        local_storage_object_preffix
    );

    const eventGroup = "account_monitoring"

    const [pageState, dispatchPageState] = useReducer(dataFetchReducer, {
        fetchedData: null,
        isLoading: true,
        isError: false,
    });

    const [paramsObj, setParamsObj] = useState() 

    const doRequest = useCallback(
        (payload) => {
            dispatchPageState({type: "data_fetch_init"})
            axios.get("/dash/alerts/alerts", {
                params: payload
            }).then(response=>{
                dispatchPageState({
                    type: "data_fetch_success",
                    payload: response.data
                })
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchPageState({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchPageState({type: "data_fetch_failure_404"})  
                else dispatchPageState({type: "data_fetch_failure"})    
            })
        },[]
    )

    useEffect(() => {
        let payload = {
            "page_number": pageParams.page - 1,
            "page_rows": 25,
            "event_group": eventGroup
        }
        Object.keys(pageParams.filters).forEach((key,index) => {
            payload[key] = pageParams.filters[key]
        })
        const timer_ = setTimeout(() => {
            doRequest(payload)
            setParamsObj({...payload}) 
        }, 300);
        return () => {
            clearTimeout(timer_)
		}	
    },[doRequest, pageParams])

    const handleChangePage = (event, page, filters) => {
		if (pageState.page === page) {
			return
		}
		setPageParams({page: page, filters: filters})
	}


    const contentContainer = (dataList) => {

		let nr_of_cards = dataList['data'].length
		let cards = dataList['data'].map((alert) => (
			<ErrorBoundary key={alert.alert_key}>
				<AlertCard eventGroup={eventGroup} alert={alert}/>
			</ErrorBoundary>
		))

		if (nr_of_cards === 0) {
			return (
			<div className='emptyContainer'>
				<p className={["blueText", "subtitleSize"].join(" ")}>
					Nada a ser analisado
				</p>
			</div>
			)
		}

		else {
            return (
                <div className='cardContainer'>
                    <CardListHeader />
                    {cards}
                </div>
            )
		}
	}

    if (pageState.fetchedData) {

    setAlertsInSessionStorage(pageState.fetchedData['data']) 
    setGetAlertsParamsInSessionStorage(paramsObj, pageState.fetchedData["number_of_pages"]) 
    
		return (
			<div style={{ width: "auto", paddingBottom: "30px" }}>
				<div style={{display:"flex"}}>
					<div >
						<p className={["blueText", "titleSize"].join(" ")}>ALERTAS</p>
					</div>	
				</div>				
				<ErrorBoundary doNothing>
                    <div style={{display:"flex"}}>
                        <Filter 
                            local_storage_object_preffix={local_storage_object_preffix} 
                            setPageParams={setPageParams}
                            eventGroup={eventGroup}
                        />
                    </div>
                </ErrorBoundary>
				<div>{contentContainer(pageState.fetchedData)}</div>
				<div>
                    <Pagination 
                        className="pagination" 
                        page={pageParams.page} 
                        count={parseInt(pageState.fetchedData["number_of_pages"])}
                        onChange={(e, page) => handleChangePage(e, page, pageParams.filters)}
                    />
				</div>
			</div>
		);
	}
	else if (pageState.isError){
		return (
			<div style={{ width: "auto" }}>
				<div style={{display:"flex"}}>
					<div >
						<p className={["blueText", "titleSize"].join(" ")}>ALERTAS</p>
					</div>		
				</div>
                <div style={{height: "55vh", display:"flex"}}>
                    <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                        {pageState.errorMessage}
                    </p>
                </div>
			</div>
		)
	}
	else if (pageState.isLoading) {
		return(
			<div style={{ width: "auto" }}>
				<p className={["blueText", "titleSize"].join(" ")}>ALERTAS</p>
				<div className='circularProgressCenter'>
					<CircularProgress />
				</div>
			</div>
		)
    }
    else return null
}

export default AccountMonitoringAlertsInbox;