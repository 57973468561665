import React, { useEffect, useContext, useReducer, useState } from "react";
import { dataFetchReducer } from "../../Utils/requestUtils";
import { useParams } from "react-router-dom";
import axios from "axios";
import TransactionCard from "./TransactionCard/TransactionCard"
import ClientCard from "./ClientCard/ClientCard"
import EnvolvedParties from "./EnvolvedParties/EnvolvedParties"
import TimerBox from "./TimerBox/TimerBox"
import ScoreBox from "./ScoreBox/ScoreBox"
import moment from "moment"
import ErrorBoundary from "../../Utils/ErrorBoundary";
import AuthContext from "../../../context/auth-context"
import {useHistory} from "react-router-dom"

import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Tooltip from "@material-ui/core/Tooltip";
import RufraCard from "./RufraCard/RufraCard";
import DeviceScan from "./DeviceScan/DeviceScan";
import DeloreanCard from "./DeloreanCard/DeloreanCard"


      
function Transaction(props){
    let history = useHistory()

    let roles = useContext(AuthContext).user_data.roles
    if (!roles.includes("read_pre_pix_transactions")){
        history.push("")
    }

    let { transaction_key } = useParams();

    const [transaction, dispatchTransaction] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )
    useEffect(() => {
		dispatchTransaction({type: "data_fetch_init"})
		const timer = setTimeout(() => {
			axios.get('/dash/account_event/pre_pix_transaction/' + transaction_key).then( response => {
                dispatchTransaction({
                    type: "data_fetch_success",
                    payload: response.data
                })   
			}).catch(error => {
                if ((error.response || {}).status === 403) dispatchTransaction({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchTransaction({type: "data_fetch_failure_404"})  
                else dispatchTransaction({type: "data_fetch_failure"})    
			})	
		}, 500);
		return () => {
			clearTimeout(timer)
        }
    }, [transaction_key])

    const transactionsArray = JSON.parse(sessionStorage.getItem('preTransactionsArray')) || []
    
    const [indexState, setIndexState] = useState(transactionsArray.findIndex(transaction => transaction === transaction_key))

    const handleNextTransactionButtonClick = () => {
        let index = transactionsArray.findIndex(transaction => transaction === transaction_key)
        setIndexState(index+1)
        transaction_key = transactionsArray[index+1]
        history.push("/pre-pix-transaction/" + transaction_key)
    }

    const handlePreviousTransactionButtonClick = () => {
        let index = transactionsArray.findIndex(transaction => transaction === transaction_key)
        setIndexState(index-1)
        transaction_key = transactionsArray[index-1]
        history.push("/pre-pix-transaction/" + transaction_key)
    }

    if (transaction.fetchedData){        
        transaction.fetchedData.manual_analysis_limit_date = moment().add(2,"minutes")
        
        return (
            <div style={{minWidth:"900px", paddingBottom: "30px"}}>
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <div style={{display:"flex", flexDirection:"column", width: "100%"}}>
                            <div style={{display:"flex", justifyContent: "space-between"}}>
                                <div className={["blueText", "titleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                    Transação {transaction.fetchedData.id}
                                </div>
                            </div>
                            <div className={["labelText", "subtitleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                {moment(transaction.fetchedData.event_date).format("DD/MM/YYYY - HH:mm:ss")}
                            </div>
                        </div>
                    </ErrorBoundary>
                </div>
                {transactionsArray.length ? 
                <div className="analysisCardContainer" style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}> 
                    <div>
                        {indexState !== 0 &&
                            <Tooltip title={<p className="tooltipText">Transação Anterior</p>}>
                            <div 
                                className={["circle", 'small'].join(" ")} 
                                style={{backgroundColor:  'rgb(211, 206, 206)', cursor: "pointer"}}
                                onClick={handlePreviousTransactionButtonClick}
                            >
                                <ArrowBack />
                            </div>
                            
                            </Tooltip>}
                    </div>
                    <div>
                        {indexState !== (transactionsArray.length - 1) &&
                            <Tooltip title={<p className="tooltipText">Próxima Transação</p>}>
                            <div 
                                className={["circle", 'small'].join(" ")} 
                                style={{backgroundColor:  'rgb(211, 206, 206)', cursor: "pointer", marginRight: "1px"}}
                                onClick={handleNextTransactionButtonClick}
                            >
                                <ArrowForward />
                            </div>
                            </Tooltip>}
                        {indexState === (transactionsArray.length - 1) &&
                            <div 
                                className={["button", "onlyboarder", "normalMediumSize"].join(" ")}
                                style={{cursor: "pointer", marginRight: "1px", width:"150px"}}
                                onClick={() => history.push("/pre-pix-transactions-inbox")}
                            >
                                Voltar para Transações
                            </div>
                        }
                    </div>
                </div> : null}
                <div style={{display: "flex", flexDirection:"row", margin: "10px 0px"}}>
                    <div style={{width: "33%", flexDirection:"column", display:"flex"}}>
                        <div className="analysisCardContainer" style={{flexGrow: "1"}}>
                            <ErrorBoundary>
                                <TransactionCard transaction={transaction.fetchedData} />
                            </ErrorBoundary>      
                        </div>
                        <div className="analysisCardContainer" style={{flexGrow: "1"}}>
                            <ErrorBoundary>
                                <ClientCard transaction={transaction.fetchedData} />
                            </ErrorBoundary>      
                        </div>
                    </div>
                    <div style={{width: "67%"}}>
                        <div style={{display:"flex"}}>
                            <div style={{width: "100%", flexDirection:"column", display:"flex"}}>
                                <div className="analysisCardContainer" style={{flexGrow: "1"}}>
                                    <ErrorBoundary>
                                        <TimerBox transaction={transaction.fetchedData}/>
                                    </ErrorBoundary>
                                </div>
                                <div className="analysisCardContainer" style={{flexGrow: "1"}}>
                                    <ErrorBoundary>
                                        <ScoreBox 
                                            analysis_events={transaction.fetchedData.analysis_events}
                                            analysis_status={transaction.fetchedData.analysis_status}
                                        />
                                    </ErrorBoundary>
                                </div>
                            </div>
                        </div>
                        <div className="analysisCardContainer" style={{ minHeight: "250px"}}>
                            <ErrorBoundary>
                                <EnvolvedParties transaction={transaction.fetchedData} />
                            </ErrorBoundary>   
                        </div>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection:"row", margin: "10px 0px"}}>
                    <div style={{width: "100%", flexDirection:"column", display:"flex"}}>
                        {transaction.fetchedData.event_data.source ?
                        <div className="analysisCardContainer" style={{flexGrow: "1", display: 'flex', flexDirection: "column"}}>
                            <ErrorBoundary>
                                <DeviceScan transaction={transaction.fetchedData} />
                            </ErrorBoundary>
                        </div>
                        : null}
                    </div>
                </div>

                { transaction.fetchedData.analysis_status_events && transaction.fetchedData.analysis_status_events[0].decision_metadata && transaction.fetchedData.analysis_status_events[0].decision_metadata.checker_rufra_data ?
                <div className="analysisCardContainer" style={{flexGrow: "1"}}>
                    <ErrorBoundary>
                        <RufraCard rufra_data={transaction.fetchedData.analysis_status_events[0]?.decision_metadata.checker_rufra_data}/>
                    </ErrorBoundary>
                </div>
                : null}
                {
					transaction.fetchedData.analysis_status_events && transaction.fetchedData.analysis_status_events[0].decision_metadata && transaction.fetchedData.analysis_status_events[0].decision_metadata.delorean &&
					<div className="analysisCardContainer" style={{flexGrow: "1"}}>
						<ErrorBoundary>
							<DeloreanCard rufra_data={transaction.fetchedData.analysis_status_events[0].decision_metadata.delorean}/>
						</ErrorBoundary>
                	</div> 
				}
            </div>
        )
    }
	else if(transaction.isError){
		return (
            <div style={{height: "80vh", display:"flex"}}>
                <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                    {transaction.errorMessage}
                </p>
            </div>
		)
	}
  
  return null;
}

export default Transaction