import React,{useContext} from "react";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import moment from 'moment'
import {formatAsMoney, getAgencyInfo } from "../../utils/utils"
import {getReportIndicatorIcon, getTransactionType, getAnalysisStatusColor, getAnalysisStatusSiglum, getAnalysisStatus, getReasonDescription } from "../../utils/utilsTransaction"
import Tooltip from "@material-ui/core/Tooltip";
import AuthContext from "../../../../context/auth-context";

function TransactionCard(props){

    let is_fraud = (props.transaction.fraud_status === "fraud")

    let counter_part_account_object = (props.transaction.event_data.transaction_direction === 'received' ? 
                            props.transaction.event_data.source_account : 
                            props.transaction.event_data.destination_account)

    let [institution_name] = getAgencyInfo (counter_part_account_object) 

    let {user_data} = useContext(AuthContext)

    return (
        <Card className="listCard">
            <Link
                className={
                    is_fraud ? 
                    ["listCardItem", "linkRed", "subtitleSize", "breakWord", "bold"].join(" ") :
                    ["listCardItem", "link", "subtitleSize", "breakWord", "bold"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
                to={"/pre-pix-transaction/" + props.transaction.id}
            >
                {is_fraud ?
                    <Tooltip title={<p className="tooltipText">Transação reportada como fraude</p>}>
                        <div style={{display:"flex", justifyContent: "center", alignItems: "center"}}>
                            {props.transaction.id}
                        </div>
                    </Tooltip> :
                    <div>
                        {props.transaction.id}
                    </div>
                    }
            </Link>
            {user_data.business_group_key &&
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
            >
                {props.transaction.company_name}
            </div>}
            <div 
                className={["listCardItem", "normalText"].join(" ")} 
                style={{flex:"1 1 80px", textAlign:"center"}}  
            >
                {formatAsMoney(props.transaction.event_data.amount * 100, "-")}
            </div>
            <div 
                className={["listCardItem", "normalText"].join(" ")}
                style={{flex:"1 1 45px", textAlign:"center"}}
            >
                {getReportIndicatorIcon(props.transaction.infraction_reports)}
            </div>
            <div 
                className={["listCardItem", "normalText", "noWrap"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                {(props.transaction.event_data.client || {}).document_number || "-"}
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")} 
                style={{flex:"1 1 80px", textAlign:"center"}}  
            >
                {getTransactionType(props.transaction.event_data.transaction_direction)}
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")} 
                style={{flex:"1 1 100px", textAlign:"center"}}  
            >
                {institution_name ? 
                <Tooltip title={<p className="tooltipText">{institution_name}</p>}>
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <div className={["textOverflowInbox","centerText","labelSpace"].join(" ")}>{institution_name}</div>
                    </div>
                </Tooltip> : (counter_part_account_object || {}).participant}
            </div>
            <div 
                className={["listCardItem", "normalText", "bold"].join(" ")} 
                style={{flex:"1 1 50px", textAlign:"center", cursor: "default"}}  
            >
                <Tooltip title={<p className="tooltipText" style={{textAlign: "center"}}>
                    {getAnalysisStatus(props.transaction.analysis_status)}
                    <br/>{getReasonDescription(props.transaction)}
                </p>}>
                <div className={["circle", "small"].join(" ")} style={{backgroundColor:getAnalysisStatusColor(props.transaction.analysis_status)}}>
                    {getAnalysisStatusSiglum(props.transaction.analysis_status)}
                </div>
                </Tooltip>
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
            >
                {moment(props.transaction.event_date).format("DD/MM/YYYY HH:mm:ss")}
            </div>
        </Card>
    )
}

export default TransactionCard