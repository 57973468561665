import React, {useState, useContext, useEffect} from 'react'
import { useTranslation } from "react-i18next";
import {GetAlertsComponent,setObjectByPath, getConstantTextFields, validateConstantValues, translateType, colorVelvet} from "./utils"
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import InputMask from "react-input-mask";
import RulesContext from "../../../../context/rules-context"
import _ from "lodash"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import DeleteIcon from '@material-ui/icons/Delete';
import TimeDeltaTextField from './Components/TimeDeltaTextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@mui/material/Button';
import VariableWhiteIcon from "../../../../assets/rulesIcon/variable_white.svg"
import ConstantNumberWhite from "../../../../assets/rulesIcon/constant_number_white.svg"
import Switch from '@mui/material/Switch';
import v4 from 'uuid';
import moment from 'moment';

export function EventFieldCachedAccumulatorBox (props) {
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleConfigurationOpen} = props
    let {handleNodeRemove} = props
    let rulesList = useContext(RulesContext)
    let accumulated_event = rulesList.fields_mapper_list.filter(rule => rule.path === node.node.properties.accumulated_field_path)[0]
    let referencedString = "";

    if (node.node.properties.referenced_field_paths && node.node.properties.referenced_field_paths.length > 0) {
        referencedString = referencedString + node.node.properties.referenced_field_paths.reduce((acc, path, idx, src) => {
            let event = rulesList.fields_mapper_list.filter(rule => rule.path === path)[0]
            let description = (event || {description:""}).description
            if (idx === 0) acc = acc + description
            else if (idx === src.length -1 && !node.node.properties.static_fields) acc = acc + " e " + description
            else acc = acc + ", " + description
            return acc
        }, "")
    }

    if (node.node.properties.static_fields) {
        if (node.node.properties.referenced_field_paths && node.node.properties.referenced_field_paths.length > 0) referencedString = referencedString + ", "
        referencedString = referencedString + node.node.properties.static_fields.reduce((acc, item, idx, src) => {
            let event = rulesList.fields_mapper_list.filter(rule => rule.path === item.field_path)[0]
            let description = (event || {description:""}).description
            if (idx === 0) acc = acc + description
            else if (idx === src.length -1) acc = acc + " e " + description
            else acc = acc + ", " + description
            return acc
        }, "")
    }


    function formatTimedelta(timedelta) {
        const duration = moment.duration(timedelta);

        const years = duration.years();
        const months = duration.months();
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
      
        let formattedString = '';
    
        if (years > 0) {
            formattedString += `${years} ${t('years')}`;
        }

        if (months > 0) {
            if (formattedString.length > 0) {
                formattedString += ', '
            }
            formattedString += `${months} ${t('months')}`;
        }

        if (days > 0) {
            if (formattedString.length > 0) {
                formattedString += ', '
            }
            formattedString += `${days} ${t('days')}`;
        }
      
        if (hours > 0) {
            if (formattedString.length > 0) {
                formattedString += ', '
            }
            formattedString += `${hours} ${t('hours')}`;
        }
      
        if (minutes > 0) {
            if (formattedString.length > 0) {
                formattedString += ', '
            }
            formattedString += `${minutes} ${t('minutes')}`;
        }
      
        if (seconds > 0) {
            if (formattedString.length > 0) {
                formattedString += ', '
            }
            formattedString += `${seconds} ${t('seconds')}`;
        }
      
        return formattedString.trim();
    }

    return (
        <div>
            {!read_only ?
                <div style={{display:"flex", alignItems: "center"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                            {t("Acumular")} {t((accumulated_event || {description:""}).description)} {t("para o mesmo")} {t(referencedString)}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {node.node.properties.timedelta_value 
                            ? 
                            t(" nos últimos ") + formatTimedelta(node.node.properties.timedelta_value)
                            :
                            t(" em todo o histórico")}
                            </div>
                        </div>
                        {(node.node.alerts || []).length > 0?
                        <GetAlertsComponent alerts={node.node.alerts} />  : null}       
                    </div>
                    <IconButton style={{width: 32, height: 32}} aria-label="duplicate" onClick={() => props.handleNodeDuplication(node)}>
                        <ContentCopyIcon style={{width: 20, height: 20}} />
                    </IconButton>
                    <IconButton style={{width: 32, height: 32}} aria-label="close" onClick={() => handleNodeRemove(node)}>
                        <CloseIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </div>
                :
                <div style={{display:"flex"}}>
                    <div 
                        style={{display:"flex", width:"100%", cursor:"pointer", margin: "0px"}} 
                        onClick={() => handleConfigurationOpen(node)}
                    >
                        <div style={{display: "flex",flexDirection: "column",margin: "auto 0px"}}>
                            <div className={["normalText","normalMediumSize"].join(" ")}>
                            {t("Acumular")} {t((accumulated_event || {description:""}).description)} {t("para o mesmo")} {t(referencedString)}
                            </div>
                            <div className={["normalText","normalSmallSize"].join(" ")}>
                            {node.node.properties.timedelta_value 
                            ? 
                            t(" nos últimos ") + formatTimedelta(node.node.properties.timedelta_value)
                            :
                            t(" em todo o histórico")}
                            </div>
                        </div>    
                    </div>
                </div>
            }
        </div>
    )
}

export function EventFieldCachedAccumulatorConfiguration(props){
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 280,
            },
        },
    };
    
    const { t } = useTranslation();
    let {node} = props
    let {read_only} = props
    let {handleNodeChange} = props
    let {handleConfigurationClose} = props
    let {userVariables} = props
    let rulesList = useContext(RulesContext)

    let settingsFilters = (useContext(RulesContext).boxes[node.node.type].settings || {}).filters || []
    
    const toIsoDateTime = (formattedTime) => {
        if (!formattedTime) return formattedTime;
        if (formattedTime.length < 19) return formattedTime;
        const year   = formattedTime.substring(6, 10);
        const month  = formattedTime.substring(3, 5);
        const day    = formattedTime.substring(0, 2);
        const hour   = formattedTime.substring(11, 13);
        const minute = formattedTime.substring(14, 16);
        const second = formattedTime.substring(17, 19);
        return `${year}-${month}-${day}T${hour}:${minute}:${second}.000Z`;
    }

    const toFormattedDateTime = (isoTime) => {
        if (!isoTime) return isoTime;
        if (isoTime.length < 24) return isoTime;
        const year   = isoTime.substring(0, 4);
        const month  = isoTime.substring(5, 7);
        const day    = isoTime.substring(8, 10);
        const hour   = isoTime.substring(11, 13);
        const minute = isoTime.substring(14, 16);
        const second = isoTime.substring(17, 19);
        return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
    }

    const completeComparisonSet = [
        {value: "greater", label: "Maior que"},
        {value: "less", label: "Menor que"},
        {value: "greater_or_equal", label: "Maior ou igual a"},
        {value: "less_or_equal", label: "Menor ou igual a"},
        {value: "equal", label: "Igual a"},
        {value: "different", label: "Diferente de"}
    ];

    const reducedComparisonSet = [
        {value: "equal", label: "Igual a"},
        {value: "different", label: "Diferente de"}
    ];
    
    const obtainPathVariableType = (pathVariable) => {
        for (let i = 0; i < rulesList.fields_mapper_list.length; i++) {
            if (rulesList.fields_mapper_list[i].path === pathVariable) {
                return rulesList.fields_mapper_list[i].type;
            }
        }
    }

    const encodeStaticData = (raw_data) => {
        const encodedStaticData = []
        const staticFields = raw_data.node.properties.static_fields
        for (let i = 0; i < staticFields.length; i++) {
            const staticField = staticFields[i]
            const selectedMode = staticField.user_variable_name ? "variable" : "constant"
            const encodedField = {
                field_path: staticField.field_path,
                comparison: staticField.comparison,
                value: staticField.value ?? null,
                user_variable_name: staticField.user_variable_name ?? null,
                mode: selectedMode
            }
            encodedStaticData.push(encodedField)
        }
        return encodedStaticData
    }

    const decodeStaticData = () => {
        const decodedStaticData = []
        for (let i = 0; i < staticData.length; i++) {
            const staticField = staticData[i]
            const decodedField = {
                field_path: staticField.field_path,
                comparison: staticField.comparison,
            }
            if (staticField.mode === "constant") {
                decodedField.value = staticField.value
            } else if (staticField.mode === "variable") {
                decodedField.user_variable_name = staticField.user_variable_name
            }
            decodedStaticData.push(decodedField)
        }
        return decodedStaticData
    }

    const [nodeState, setNodeState] = useState(node)
    const [staticFieldIndex, setStaticFieldIndex] = useState();
    const [staticData, setStaticData] = useState(nodeState.node.properties.static_fields && nodeState.node.properties.static_fields.length > 0 ? 
                                                 encodeStaticData(nodeState) : [])

    const handleAddDataItem = () => {
        if (staticData.length > 0) {
            setStaticData([...staticData, {field_path: null, comparison: null, value: null, user_variable_name: null, mode: "constant"}])
        } else {
            setStaticData([{field_path: null, comparison: null, value: null, user_variable_name: null, mode: "constant"}])
        }
    }

    const handleUpdateStaticData = (item, index) => {
        const updatedData = staticData.map((data, i) => {
            if (i === index) {
                return item;
            } else {
                return data;
            }
        })
        setStaticData(updatedData);
    }

    useEffect(() => {
        setNodeState(node)
    },[node])

    const validateAllValues = () => {
        let doesHaveDuplicates = nodeState.node.properties.referenced_field_paths.some(
            (val, i) => nodeState.node.properties.referenced_field_paths.indexOf(val) !== i
        )
        if (doesHaveDuplicates) return false

        let referenced_field_validation = nodeState.node.properties.referenced_field_paths.filter(
            path => !path || path.length === 0).length === 0
        let accumulated_field_validation = (nodeState.node.properties.accumulated_field_path && nodeState.node.properties.accumulated_field_path.length >0)
        let timedelta_validation = nodeState.node.properties.timedelta_value 
                                    ? 
                                    validateConstantValues("timedelta",nodeState.node.properties.timedelta_value)
                                    :
                                    true

        let static_field_validation = true
        if ((nodeState.node.properties.static_fields || []).length > 0) {
            const validated_fields = nodeState.node.properties.static_fields.map((field) => {
                if (!field.field_path || !field.comparison || (field.value && field.user_variable_name) || (!field.value && !field.user_variable_name)) return false
                return true
            })
            if (validated_fields.some((value) => value === false)) {
                static_field_validation = false
            }
        }
        return accumulated_field_validation && referenced_field_validation && timedelta_validation && static_field_validation
    }

    const handleAdd = () => {
        let new_node = _.cloneDeep(nodeState)
        new_node.node.properties.referenced_field_paths = [...new_node.node.properties.referenced_field_paths, ""]
        setNodeState(new_node)
    }

    const handleStaticFieldAdd = () => {
        handleAddDataItem()
        let new_node = _.cloneDeep(nodeState)
        if (!new_node.node.properties.static_fields) {
            new_node.node.properties.static_fields = [{field_path: null, comparison: null, value: null}]
        } else {
            new_node.node.properties.static_fields = [...new_node.node.properties.static_fields, {field_path: null, comparison: null, value: null}]
        }
        setNodeState(new_node)
    }

    const handleRemove = (index) => {
        let new_node = _.cloneDeep(nodeState)
        new_node.node.properties.referenced_field_paths.splice(index,1)
        setNodeState(new_node)
    }

    const handleDatasetChange = (e) => {
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node, "properties.dataset", e.target.value)
        setNodeState(new_node)
    }


    const handleStaticFieldRemove = (index) => {
        let new_node = _.cloneDeep(nodeState)
        new_node.node.properties.static_fields.splice(index,1)
        setNodeState(new_node)
        setStaticData(encodeStaticData(new_node) || [])
    }

    const handleReferenceEventChange = (e, index) => {
        let new_node = _.cloneDeep(nodeState)
        new_node.node.properties.referenced_field_paths.splice(index,1,e.target.value)
        setNodeState(new_node)
    }

    const handleEventChange = (e) => {
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,"properties.accumulated_field_path",e.target.value)
        setNodeState(new_node)
    }

    const handleBusinessGroupQueryChange = (e) => {
        let new_node = _.cloneDeep(nodeState)
        setObjectByPath(new_node.node,"properties.search_with_business_group_key", e.target.checked)
        setNodeState(new_node)
    }

    const handleStaticFieldChange = (data, index) => {
        let new_node = _.cloneDeep(nodeState);
        new_node.node.properties.static_fields.splice(index, 1, data)
        setNodeState(new_node)
    }

    const handleValueChange = (path, val) => {
        let new_node = _.cloneDeep(nodeState)
        if (val === "P") val = null
        setObjectByPath(new_node.node,path,val)
        setNodeState(new_node)
    }

    const handleFilterChange = (event, path) => {
        let new_node = _.cloneDeep(nodeState)

        const { value } = event.target
        
        if(!new_node.node.properties.filters){
            new_node.node.properties.filters = {}
        }

        if(!new_node.node.properties.filters[path]){
            new_node.node.properties.filters[path] =[]
        }

        new_node.node.properties.filters[path] = value;
        
        if(new_node.node.properties.filters[path].length === 0){
            delete new_node.node.properties.filters[path]
        }

        if(_.size(new_node.node.properties.filters)=== 0) {
            delete new_node.node.properties.filters
        }

        setNodeState(new_node)
    };

    let options_dictionary = {}
    for(let filter of settingsFilters){
        options_dictionary[filter.path] = {};
        for(let option of filter.options){
            options_dictionary[filter.path][option.enum] = option.description
        }
    }

    let filter_rendered_values = {}
    for(let filter of settingsFilters){
        let translated_values = []
        for(let selected_option of ((nodeState.node.properties.filters||{})[filter.path]||[])){
            translated_values.push(options_dictionary[filter.path][selected_option])
        }
        filter_rendered_values[filter.path] = translated_values.join(', ')
    }

    const handleSave = () => {
        handleConfigurationClose()
        handleNodeChange(nodeState)
    }

    useEffect(() => {
        if (staticData.length > 0) {
            if (typeof staticFieldIndex === "number") {
                const decodedStaticData = decodeStaticData()
                if (decodedStaticData[staticFieldIndex] && (decodedStaticData[staticFieldIndex].value || decodedStaticData[staticFieldIndex].user_variable_name)) {
                    handleStaticFieldChange(decodedStaticData[staticFieldIndex], staticFieldIndex)
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [staticData, staticFieldIndex])

    const create_dataset_array = (datasets_object) => {
        const dataset_array = []
        for (const [key, value] of Object.entries(datasets_object)) {
            dataset_array.push({key: key, description: value.description})
        }
        return dataset_array;
    }

    return (
        <div style={{flexGrow:"1", padding: "20px", display:"flex", flexDirection:"column", width: "100%"}}>
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>{t(node.node.description)}</span>
                <Tooltip title={<div className="tooltipText"><ul>{node.node.helperText.map((help,index) => (<li key={index}>{t(help)}</li>))}</ul></div>}>

                    <HelpIcon style={{fontSize: "14px", display:"flex", margin:"auto 5px"}}/>
                </Tooltip>
            </div>
            {nodeState && nodeState?.node?.instance?.rulesContext?.boxes?.event_field_cached_accumulator_box?.settings?.enable_business_group_query_option && 
                <div style={{display: "flex", flexDirection: "row", gap: 8, margin: "16px 0", width: "100%"}}>
                    <p className={["blueText", "labelSpace"].join(" ")} style={{width: "100%"}}>Buscar em todo o grupo de negócios</p>
                    <Switch
                        checked={nodeState.node.properties.search_with_business_group_key ?? false}
                        onChange={handleBusinessGroupQueryChange}
                    />
                </div>
            }
            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column", alignItems: "center"}}>
                {nodeState && nodeState.node.instance.rulesContext.boxes.event_field_cached_accumulator_box.settings ?
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: 8, marginBottom: 24, width: "80%"}}>
                    <p className={["blueText", "subtitleSize", "labelSpace"].join(" ")} style={{width: "100%", textAlign: "center"}}>{t('dataset')}</p>
                        <TextField
                            label={t('dataset')}
                            error={!nodeState.node.properties.dataset}
                            style={{textAlign: "center"}}
                            className="filterTextbox"
                            size="small" fullWidth
                            value={nodeState.node.properties.dataset || ""}
                            onChange={(e) => handleDatasetChange(e)}
                            select
                            InputProps={{
                                readOnly: read_only
                            }}
                        >
                            {
                                create_dataset_array(nodeState.node.instance.rulesContext.boxes.event_field_cached_accumulator_box.settings.datasets).map((dataset) => (
                                    <MenuItem key={v4()} value={dataset.key}>{dataset.description}</MenuItem>
                                ))
                            }
                        </TextField>
                    </div>
                : <></>}
                <TextField 
                    label={t("Campo Acumulado")}
                    style={{textAlign:"center", width:"80%", margin:"3px auto"}}
                    error={nodeState.node.properties.accumulated_field_path === ""}
                    className="filterTextbox" 
                    size="small" fullWidth 
                    value={nodeState.node.properties.accumulated_field_path || ""}
                    onChange={handleEventChange}
                    select
                    InputProps={{
                        readOnly: read_only
                      }}
                >
                    { rulesList.fields_mapper_list.filter(field => field.type === "number").map((field, index) => (
                        <MenuItem key={index} value={field.path}><em className="normalText">{t(field.description) + " ("+ t(translateType(field.type))+ ")"}</em></MenuItem>
                    ))}
                </TextField>
                {nodeState.node.properties.referenced_field_paths.map((row,index) => (
                    <div key={index} style={{display:"flex", width:"80%", margin:"3px auto"}}>
                        <TextField 
                            label={t("Campo de Referência")}
                            error={
                                nodeState.node.properties.referenced_field_paths[index] === "" || 
                                nodeState.node.properties.referenced_field_paths.filter(
                                    path => path === nodeState.node.properties.referenced_field_paths[index]
                                ).length >1
                            }
                            style={{textAlign:"center"}}
                            className="filterTextbox" 
                            size="small" fullWidth 
                            value={nodeState.node.properties.referenced_field_paths[index] || ""}
                            onChange={(e) => handleReferenceEventChange(e,index)}
                            select
                            InputProps={{
                                readOnly: read_only
                              }}
                        >
                            {rulesList.fields_mapper_list.map((field, field_index) => (
                                <MenuItem key={field_index} value={field.path}><em className="normalText">{t(field.description) + " ("+ t(translateType(field.type))+ ")"}</em></MenuItem>
                            ))}
                        </TextField>
                        {!read_only ?
                        <DeleteIcon onClick={() => handleRemove(index)} style={{fill:colorVelvet, margin:"auto 0px", cursor: "pointer"}}/>
                        : null}
                    </div>
                ))}
                {!read_only && 
                <div
                    style={{cursor:"pointer", textAlign:"center", width:"80%", margin:"3px auto"}}
                    onClick={handleAdd}
                    className={["blueText", "normalSMallSize"].join(" ")}
                >
                    {t("+ NOVA REFERÊNCIA")}
                </div>}
                <p className={["blueText", "subtitleSize", "labelSpace"].join(" ")} style={{width: "100%", textAlign: "center", marginTop: 16}}>{t("Referências Estáticas")}</p>
                {nodeState.node.properties.static_fields && nodeState.node.properties.static_fields.map((row, index) => (
                    <div key={index} style={{display:"flex", flexDirection: "column", width:"80%", margin:"3px auto"}}>
                        <TextField 
                            label={t("Tipo")}
                            error={nodeState.node.properties.static_fields[index] === "" || nodeState.node.properties.referenced_field_paths.filter(path => path === nodeState.node.properties.referenced_field_paths[index]).length >1}
                            style={{textAlign:"center"}}
                            className="filterTextbox" 
                            size="small" fullWidth 
                            value={staticData[index].field_path}
                            onChange={(e) => {
                                handleUpdateStaticData({field_path: e.target.value, comparison: null, value: null, user_variable_name: null, mode: "constant"}, index);
                                setStaticFieldIndex(index);
                            }}
                            select
                            InputProps={{
                                readOnly: read_only
                              }}
                        >
                            {rulesList.fields_mapper_list.map((field, field_index) => {
                                if (field.type !== "number" && field.type !== "string") {
                                    return <></>
                                }
                                return <MenuItem key={field_index} value={field.path}><em className="normalText">{t(field.description) + " ("+ t(translateType(field.type))+ ")"}</em></MenuItem>
                            })}
                        </TextField>
                        <TextField 
                            label={t("Comparação")}
                            error={nodeState.node.properties.static_fields[index] === "" || nodeState.node.properties.static_fields.filter(path => path === nodeState.node.properties.referenced_field_paths[index]).length > 1}
                            style={{textAlign:"center"}}
                            className="filterTextbox" 
                            size="small" fullWidth
                            value={staticData[index].comparison}
                            onChange={(e) => {
                                handleUpdateStaticData({field_path: staticData[index].field_path, comparison: e.target.value, value: staticData[index].value ?? null, user_variable_name: staticData[index].user_variable_name ?? null, mode: staticData[index].mode}, index);
                                setStaticFieldIndex(index);
                            }}
                            select
                            disabled={!obtainPathVariableType(staticData[index].field_path)}
                            InputProps={{
                                readOnly: read_only
                              }}
                        >
                            {obtainPathVariableType(staticData[index].field_path) !== 'string' && obtainPathVariableType(staticData[index].field_path) !== 'bool' ? completeComparisonSet.map((element) => {
                                return <MenuItem value={element.value}><em className="normalText">{t(element.label)}</em></MenuItem>
                            }) : reducedComparisonSet.map((element) => {
                                return <MenuItem value={element.value}><em className="normalText">{t(element.label)}</em></MenuItem>
                            })}
                        </TextField>
                        {staticData[index].comparison !== undefined && staticData[index].comparison !== null ?
                            <div style={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", gap: 8, minHeight: 48}}>
                                {staticData[index].mode === "constant" ?
                                    <div style={{width: "80%"}}>
                        {obtainPathVariableType(staticData[index].field_path) === 'bool' ?
                            <TextField
                                label={t("Valor")}
                                error={staticData[index].value === ""}
                                style={{textAlign:"center", height: 48}}
                                className="filterTextbox" 
                                size="small" fullWidth 
                                value={staticData[index].value ?? ""}
                                select
                                onChange={(e) => {
                                    handleUpdateStaticData({field_path: staticData[index].field_path, comparison: staticData[index].comparison, value: e.target.value, user_variable_name: null, mode: staticData[index].mode}, index)
                                    setStaticFieldIndex(index);
                                }}
                                InputProps={{
                                    readOnly: read_only
                                }}
                            >
                                <MenuItem value={true}><em className="normalText">Verdadeiro</em></MenuItem>
                                <MenuItem value={false}><em className="normalText">Falso</em></MenuItem>
                            </TextField> : obtainPathVariableType(staticData[index].field_path) === 'timedelta' ? 
                            <TimeDeltaTextField
                                label={t("Valor do período")}
                                value={staticData[index].value ? staticData[index].value : ""}
                                error={staticData[index].value ? !validateConstantValues("timedelta", staticData[index].value) : false}
                                valueChangeFunction={(value) => {
                                    handleUpdateStaticData({field_path: staticData[index].field_path, comparison: staticData[index].comparison, value: value, user_variable_name: null, mode: staticData[index].mode}, index)
                                    setStaticFieldIndex(index);
                                }}
                                read_only={read_only}
                                style={{width: "100%", height: 48}}
                            /> : obtainPathVariableType(staticData[index].field_path) === 'time' ?
                            <div className="internalCardContainer" style={{display:"flex", flexDirection: "column"}}>
                                <InputMask
                                    label={t('Valor')}
                                    style={{textAlign:"center", width:"100%", margin:"3px auto", height: 48}}
                                    error={!validateConstantValues("time", staticData[index].value)}
                                    value={toFormattedDateTime(staticData[index].value) ?? ""}
                                    {...getConstantTextFields('time').props}
                                    onChange={(e) => {
                                        handleUpdateStaticData({field_path: staticData[index].field_path, comparison: staticData[index].comparison, value: toIsoDateTime(e.target.value), user_variable_name: null, mode: staticData[index].mode}, index)
                                        setStaticFieldIndex(index);
                                    }}
                                    InputProps={{
                                        readOnly: read_only
                                    }}
                                />
                            </div> : obtainPathVariableType(staticData[index].field_path) === 'datetime' ?
                                <InputMask
                                    label={t('Valor')}
                                    style={{textAlign:"center", width:"100%", margin:"3px auto", height: 48}}
                                    error={!validateConstantValues("datetime", staticData[index].value ? staticData[index].value : false)}
                                    value={toFormattedDateTime(staticData[index].value) ?? ""}
                                    {...getConstantTextFields('datetime').props}
                                    onChange={(e) => {
                                        handleUpdateStaticData({field_path: staticData[index].field_path, comparison: staticData[index].comparison, value: toIsoDateTime(e.target.value), user_variable_name: null, mode: staticData[index].mode}, index)
                                        setStaticFieldIndex(index);
                                    }}
                                    InputProps={{
                                        readOnly: read_only
                                    }}
                            /> : obtainPathVariableType(staticData[index].field_path) === 'number' ?
                                <TextField
                                    label={t('Valor')}
                                    error={!validateConstantValues("number", staticData[index].value)}
                                    value={staticData[index].value ?? ""}
                                    className="filterTextBox"
                                    style={{height: 48}}
                                    size="small" fullWidth
                                    type="number"
                                    onChange={(e) => {
                                        handleUpdateStaticData({field_path: staticData[index].field_path, comparison: staticData[index].comparison, value: Number(e.target.value), user_variable_name: null, mode: staticData[index].mode},index)
                                        setStaticFieldIndex(index);
                                    }}
                                    InputProps={{readOnly: read_only}}
                            /> : obtainPathVariableType(staticData[index].field_path) === 'geo_point' ?
                                <TextField
                                    label={t('Valor')}
                                    error={!validateConstantValues("number", staticData[index].value)}
                                    value={staticData[index].value ?? ""}
                                    className="filterTextBox"
                                    style={{height: 48}}
                                    size="small" fullWidth
                                    type="number"
                                    onChange={(e) => {
                                        handleUpdateStaticData({field_path: staticData[index].field_path, comparison: staticData[index].comparison, value: e.target.value, user_variable_name: null, mode: staticData[index].mode},index)
                                        setStaticFieldIndex(index);
                                    }}
                                    InputProps={{readOnly: read_only}}
                            /> : obtainPathVariableType(staticData[index].field_path) ?
                            <TextField
                                label={t('Valor')}
                                error={!validateConstantValues("string", staticData[index].value)}
                                value={staticData[index].value ?? ""}
                                className="filterTextBox"
                                style={{height: 48}}
                                size="small" fullWidth
                                type="string"
                                onChange={(e) => {
                                    handleUpdateStaticData({field_path: staticData[index].field_path, comparison: staticData[index].comparison, value: e.target.value, user_variable_name: null, mode: staticData[index].mode},index)
                                    setStaticFieldIndex(index);
                                }}
                                InputProps={{readOnly: read_only}}
                            /> : null}
                            </div>
                                :   <TextField
                                        label={t('Variável')}
                                        error={false}
                                        value={staticData[index].user_variable_name ?? ""}
                                        style={{textAlign:"center"}}
                                        select fullWidth
                                        onChange={(e) => {
                                            handleUpdateStaticData({field_path: staticData[index].field_path, comparison: staticData[index].comparison, user_variable_name: e.target.value, mode: staticData[index].mode}, index)
                                            setStaticFieldIndex(index)
                                        }}
                                    >
                                        {userVariables.filter((variable) => variable.variable_type === obtainPathVariableType(staticData[index].field_path)).map((variable, index) => {
                                                return <MenuItem key={index} value={variable.variable_name}>{variable.variable_name}</MenuItem>
                                        })}
                                    </TextField>}
                                {staticData[index].mode === "constant" ?
                                    <Tooltip title="Trocar para variável">
                                        <Button variant='contained' sx={[{'&:hover': {backgroundColor: "#020f4d"}}, {maxWidth: 24, height: "100%", backgroundColor: "#0b1f82"}]}
                                                onClick={() => {
                                                    handleUpdateStaticData({field_path: staticData[index].field_path, comparison: staticData[index].comparison, value: null, user_variable_name: null, mode: "variable"}, index)
                                        }}>
                                            <img src={ConstantNumberWhite} style={{width: 20, height: 20}} alt="Trocar para variável"/>
                                        </Button>
                                    </Tooltip>
                                    :
                                    <Tooltip title="Trocar para valor constante">
                                        <Button variant='contained' sx={[{'&:hover': {backgroundColor: "#020f4d"}}, {maxWidth: 24, height: "100%", backgroundColor: "#0b1f82"}]}
                                                onClick={() => {
                                                    handleUpdateStaticData({field_path: staticData[index].field_path, comparison: staticData[index].comparison, value: null, user_variable_name: null, mode: "constant"}, index)
                                        }}>
                                            <img src={VariableWhiteIcon} style={{width: 20, height: 20}} alt="Trocar para valor constante"/>
                                        </Button>
                                    </Tooltip>
                                }                            
                            </div> : null}
                        <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", marginTop: "8px"}}>
                            <DeleteIcon onClick={() => handleStaticFieldRemove(index)} style={{fill: colorVelvet, margin: "auto 0px", cursor: "pointer"}}/>
                        </div>
                    </div>
                ))}
                {!read_only &&
                <div
                    style={{cursor:"pointer", margin:"16px auto"}}
                    onClick={handleStaticFieldAdd}
                    className={["blueText", "normalSMallSize"].join(" ")}
                >
                    {t("+ NOVA REFERÊNCIA ESTÁTICA")}
                </div>}

                {settingsFilters.map(filter => 
                    <FormControl key={filter.path} style={{textAlign:"center", width:"80%", margin:"3px auto"}}>
                    <InputLabel id="demo-multiple-checkbox-label">{t(filter.description)}</InputLabel>
                    <br></br>
                    <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={((nodeState.node.properties.filters||{})[filter.path]||[])}
                    onChange={(event) => handleFilterChange(event, filter.path)}
                    input={<Input />}
                    renderValue={(selected) => (filter_rendered_values[filter.path])}
                    MenuProps={MenuProps}
                    InputProps={{
                        readOnly: read_only
                      }}
                    >
                    {filter.options.map((option) => (
                        <MenuItem key={option.enum} value={option.enum} name={option.description}>
                        <Checkbox color="primary" checked={((nodeState.node.properties.filters||{})[filter.path]||[]).includes(option.enum)} />
                        <ListItemText primary={t(option.description)} />
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>)}
                <TimeDeltaTextField
                    value={nodeState.node.properties.timedelta_value ? nodeState.node.properties.timedelta_value : ""}
                    error={nodeState.node.properties.timedelta_value ? !validateConstantValues("timedelta",nodeState.node.properties.timedelta_value) : false}
                    valueChangeFunction={(value) => handleValueChange("properties.timedelta_value", value)}
                    label={t("Período de Tempo considerado")}
                    read_only={read_only}
                />
            </div>
            <div style={{display:"flex"}}>
                <div
                    className={["button", "onlyboarder", "normalText", "normalMediumSize"].join(" ")}
                    style={{width:"50%"}}
                    onClick={handleConfigurationClose}
                >
                    {read_only ? "X" : t("discard")}
                </div>
                {!read_only && 
                <div
                    className={
                        validateAllValues()
                        ?
                        ["button", "standard", "normalText", "normalMediumSize"].join(" ")
                        :
                        ["button", "standard", "normalText", "normalMediumSize","disabled"].join(" ")}
                    style={{width:"50%"}}
                    onClick={validateAllValues() ? handleSave : null}
                >
                    {t("save")}
                </div>}   
            </div>
        </div>
    )
}

export class EventFieldCachedAccumulatorBoxClass {
    constructor(rulesContext){
        this.rulesContext = rulesContext
        this.formatTimedelta = (timedelta) => {
            const duration = moment.duration(timedelta);
  
            const years = duration.years();
            const months = duration.months();
            const days = duration.days();
            const hours = duration.hours();
            const minutes = duration.minutes();
            const seconds = duration.seconds();
          
            let formattedString = '';
        
            if (years > 0) {
                formattedString += `${years} anos`;
            }

            if (months > 0) {
                if (formattedString.length > 0) {
                    formattedString += ', '
                }
                formattedString += `${months} meses`;
            }

            if (days > 0) {
                if (formattedString.length > 0) {
                    formattedString += ', '
                }
                formattedString += `${days} dias`;
            }
          
            if (hours > 0) {
                if (formattedString.length > 0) {
                    formattedString += ', '
                }
                formattedString += `${hours} horas`;
            }
          
            if (minutes > 0) {
                if (formattedString.length > 0) {
                    formattedString += ', '
                }
                formattedString += `${minutes} minutos`;
            }
          
            if (seconds > 0) {
                if (formattedString.length > 0) {
                    formattedString += ', '
                }
                formattedString += `${seconds} segundos`;
            }
          
            return formattedString.trim();
        }
    }
    validate(node){
        let put_error = false
        let alerts = []
        //Validate if node has one or two children
        if((node.node.children || []).length !== 0){
            put_error = true
            alerts.push("Este nó não deve possuir filhos")
        }
        if((node.node.properties.dataset || "").length === 0) {
            alerts.push("Este nó deve ter um conjunto de dados selecionado")
        }
        if(node.node.properties.accumulated_field_path === ""){
            put_error = true
            alerts.push("Clique neste nó, abra a configuração e escolha um Campo Acumulado")
        }
        if((node.node.properties.referenced_field_paths || []).length === 0 && (node.node.properties.static_fields || []).length === 0){
            put_error = true
            alerts.push("Este nó deve ter opções escolhidas dentro de Campo de Referência e/ou Campo de Referência Estática")
        }
        else{
            let doesHaveDuplicates = node.node.properties.referenced_field_paths.some((val, i) => node.node.properties.referenced_field_paths.indexOf(val) !== i)
            if (doesHaveDuplicates){
                put_error = true
                alerts.push("Este nó não deve ter Campos de Referência duplicados")
            }
            let invalidFields = node.node.properties.referenced_field_paths.filter(path => !path || path.length === 0)
            if (invalidFields.length > 0){
                put_error = true
                alerts.push("Todos os Campos de Referência deste nó devem ter opções válidas")
            }
        }
        if ((node.node.properties.static_fields || []).length > 0) {
            const validated_fields = node.node.properties.static_fields.map((field) => {
                if (!field.field_path || !field.comparison || (field.value && field.user_variable_name) || (!field.value && !field.user_variable_name)) return false
                return true
            })
            if (validated_fields.some((value) => value === false)) {
                put_error = true
                alerts.push("Seu campo estático possui algum valor faltante")
            }
        }
        if (node.node.properties.timedelta_value) {
            if (!validateConstantValues("timedelta",node.node.properties.timedelta_value)){
                put_error = true
                alerts.push("Deve ser fornecido um período de tempo válido")
            }
            if (this.rulesContext.boxes.event_field_cached_accumulator_box.settings && this.rulesContext.boxes.event_field_cached_accumulator_box.settings.timedelta_value) {
                const settingsDuration   = moment.duration(this.rulesContext.boxes.event_field_cached_accumulator_box.settings.timedelta_value)
                const propertiesDuration = moment.duration(node.node.properties.timedelta_value)
                if (propertiesDuration.asMilliseconds() > settingsDuration.asMilliseconds()) {
                    put_error = true
                    alerts.push("O período de tempo considerado por esse nó não pode exceder " + this.formatTimedelta(settingsDuration))
                }
            }
        }
        //Validate if parent is valid
        if (node.parentNode != null) {
            if(node.parentNode.on_error){
                put_error = true
                alerts.push("O nó superior está inválido")
            }
        }
        return {validated: !put_error, alerts: alerts}
    }

    getType(node){
        return "number"
    }
}
