import React from "react"
import CheckIcon from "@material-ui/icons/Check"
import ClearIcon from "@material-ui/icons/Clear"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import moment from "moment"
import Tooltip from "@material-ui/core/Tooltip"
import redCross from "../../../assets/cardEntryIcons/redCross.svg"
import greenCheck from "../../../assets/cardEntryIcons/greenCheck.svg"
import lockIcon from "../../../assets/cardEntryIcons/lockIcon.svg"
import html2canvas from "html2canvas"

export const colorBeige = "#e5ddcb"

export const colorBlueQIShadow = "rgb(79, 204, 237, 0.7)"

export const colorPinkQIShadow = "rgb(254, 64, 128, 0.7)"

export const colorDarkBlueQIShadow = "rgb(11, 31, 130, 0.7)"

export const getScoreInterpretation = (score, legal_nature) => {
  if (legal_nature === "natural_person") {
    if (score < 100) return "serasa_score_natural_person_up_to_100_interpretation"
    else if (score < 200) return "serasa_score_natural_person_between_101_and_200_interpretation"
    else if (score < 300) return "serasa_score_natural_person_between_201_and_300_interpretation"
    else if (score < 400) return "serasa_score_natural_person_between_301_and_400_interpretation"
    else if (score < 500) return "serasa_score_natural_person_between_401_and_500_interpretation"
    else if (score < 600) return "serasa_score_natural_person_between_501_and_600_interpretation"
    else if (score < 700) return "serasa_score_natural_person_between_601_and_700_interpretation"
    else if (score < 800) return "serasa_score_natural_person_between_701_and_800_interpretation"
    else if (score < 900) return "serasa_score_natural_person_between_801_and_900_interpretation"
    else if (score < 1000) return "serasa_score_natural_person_greater_than_901_interpretation"
  }
  else {
    if (score < 50) return 'serasa_score_legal_person_up_to_50_interpretation'
    else if (score < 100) return 'serasa_score_legal_person_between_51_and_100_interpretation'
    else if (score < 150) return 'serasa_score_legal_person_between_101_and_150_interpretation'
    else if (score < 200) return 'serasa_score_legal_person_between_151_and_200_interpretation'
    else if (score < 250) return 'serasa_score_legal_person_between_201_and_250_interpretation'
    else if (score < 300) return 'serasa_score_legal_person_between_251_and_300_interpretation'
    else if (score < 350) return 'serasa_score_legal_person_between_301_and_350_interpretation'
    else if (score < 400) return 'serasa_score_legal_person_between_351_and_400_interpretation'
    else if (score < 450) return 'serasa_score_legal_person_between_401_and_450_interpretation'
    else if (score < 500) return 'serasa_score_legal_person_between_451_and_500_interpretation'
    else if (score < 550) return 'serasa_score_legal_person_between_501_and_550_interpretation'
    else if (score < 600) return 'serasa_score_legal_person_between_551_and_600_interpretation'
    else if (score < 650) return 'serasa_score_legal_person_between_601_and_650_interpretation'
    else if (score < 700) return 'serasa_score_legal_person_between_651_and_700_interpretation'
    else if (score < 750) return 'serasa_score_legal_person_between_701_and_750_interpretation'
    else if (score < 800) return 'serasa_score_legal_person_between_751_and_800_interpretation'
    else if (score < 850) return 'serasa_score_legal_person_between_801_and_850_interpretation'
    else if (score < 900) return 'serasa_score_legal_person_between_851_and_900_interpretation'
    else if (score < 950) return 'serasa_score_legal_person_between_901_and_950_interpretation'
    else return 'serasa_score_legal_person_greater_than_951_interpretation'
  }
}

export const handleHTML2Img = (divId, setMethod) => {
  const html2Img = document.getElementById(divId)
  html2canvas(html2Img)
    .then((result) => {
      const imgData = result.toDataURL("image/jpg", 0.3)
      setMethod(imgData)
    })
    .catch((error) => console.error(error))
}

export const getCurrentAnalystColor = (analyst) => {
  if (analyst == null) {
    return colorGreen
  }
  else {
    return colorRed
  }
}

//General Format Utils
export const getCurrentAnalystText = (analyst) => {
  if (analyst == null) {
    return "Nenhum analista responsável por esse cadastro"
  }
  else {
    return (
      "O analista " + analyst.analyst_name + " está analisando esse cadastro"
    )
  }
}

export const translatePhoneType = (type) => {
  switch (type) {
    case "residential":
      return "Telefone Residencial"
    case "comercial":
      return "Telefone Comercial"
    case "mobile":
      return "Telefone Celular"
    default:
      return type
  }
}

export const accountStatusSiglum = (status) => {
  switch (status) {
    case "active":
      return { siglum: "A", color: colorGreen };
    case "deactivated":
      return { siglum: "D", color: colorRed };
    default:
      return { siglum: "N", color: colorGrey };
  }
};

export const accountStatusColor = (status) => {
  switch (status) {
    case "active":
      return colorGreen
    case "deactivated":
      return colorRed
    default:
      return colorGrey
  }
}

export const translateAccountStatus = (status) => {
  switch (status) {
    case "active":
      return "Ativa"
    case "deactivated":
      return "Desativada"
    default:
      return status
  }
}

export const accountAlertStatusSiglum = (status) => {
  switch (status) {
    case true:
      return (
        <ErrorOutlineIcon
          style={{
            verticalAlign: "middle",
            width: "50px",
            height: "30px",
            color: colorRed,
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        />

      )
    case false:
      return (
        <CheckIcon
          style={{
            verticalAlign: "middle",
            width: "50px",
            height: "30px",
            color: colorGreen,
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        />
      )
    default:
      return <img src={lockIcon} alt="lockIcon Logo" />
  }
}

export const accountAlertStatusColor = (status) => {
  switch (status) {
    case true:
      return colorGreen
    case false:
      return colorRed
    default:
      return colorGrey
  }
}

export const translateAlertAccountStatus = (status) => {
  switch (status) {
    case true:
      return "Conta possúi alertas em aberto"
    case false:
      return "Conta não possúi alertas em aberto"
    default:
      return "Analise de Alertas Desativada"
  }
}

export const getAccountMonitoringTopicEnum = (status) => {
  switch (status) {
    case "pep":
      return "Lista PEP"
    case "ofac":
      return "Lista OFAC"
    case "unsc":
      return "Lista UNSC"
    case "ibama":
      return "Lista IBAMA"
    case "ceis":
      return "Lista CEIS"
    case "cnep":
      return "Lista CNEP"
    case "receita_federal_status":
      return "Status Na Receita Federal"
    case "receita_federal_name":
      return "Nome Na Receita Federal"
    default:
      return "Tópico de Monitoramento não Encontrado"
  }
}

export const getAccountMonitoringTopicStatusEnum = (monitoring_topic, is_present) => {
  switch (monitoring_topic) {
    case "pep":
      return is_present
        ? "Um ou mais titulares da conta estão presentes na lista de PEP"
        : "Nenhum titular da conta está presente na lista de PEP";
    case "ofac":
      return is_present
        ? "Um ou mais titulares da conta estão presentes na lista da OFAC"
        : "Nenhum titular da conta está presente na lista da OFAC";
    case "unsc":
      return is_present
        ? "Um ou mais titulares da conta estão presentes na lista da UNSC"
        : "Nenhum titular da conta está presente na lista da UNSC";
    case "ibama":
      return is_present
        ? "Um ou mais titulares da conta estão presentes na lista do IBAMA"
        : "Nenhum titular da conta está presente na lista do IBAMA";
    case "cnep":
      return is_present
        ? "Um ou mais titulares da conta estão presentes na lista do CNEP"
        : "Nenhum titular da conta está presente na lista do CNEP";
    case "ceis":
      return is_present
        ? "Um ou mais titulares da conta estão presentes na lista do CEIS"
        : "Nenhum titular da conta está presente na lista do CEIS";
    case "receita_federal_status":
      return is_present
        ? "O status na Receita Federal de um ou mais titulares da conta não está regular"
        : "Todos os titulares da conta estão com status regular na Receita Federal";
    case "receita_federal_name":
      return is_present
        ? "O nome na Receita Federal de um ou mais titulares da conta divergiu"
        : "Todos os titulares da conta estão com nome regular na Receita Federal";
    default:
      return "Tópico de monitoramento desconhecido";
  }
};

export const getAnalysisStatus = (status) => {
  switch (status) {
    case "pending":
    case "in_queue":
      return "Em Processamento"
    case "waiting_for_data":
      return "Aguardando Dados"
    case "in_manual_analysis":
      return "Pendente"
    case "manually_approved":
      return "Aprovado Manualmente"
    case "automatically_approved":
      return "Aprovado Automaticamente"
    case "automatically_challenged":
      return "Contestado Automaticamente"
    case "manually_challenged":
      return "Contestado Manualmente"
    case "manually_reproved":
      return "Reprovado Manualmente"
    case "automatically_reproved":
      return "Reprovado Automaticamente"
    case "approved_by_time":
      return "Aprovado por tempo"
    case "not_analised":
      return "Não analisado"
    case "on_hold":
      return "Em Espera"
    default:
      return status
  }
}

export const getAnalysisStatusSiglum = (status) => {
  switch (status) {
    case "pending":
    case "in_queue":
      return "EP"
    case "waiting_for_data":
      return "AD"
    case "in_manual_analysis":
      return "P"
    case "manually_approved":
    case "manually_reproved":
    case "manually_challenged":
      return "M"
    case "automatically_approved":
    case "automatically_reproved":
    case "automatically_challenged":
      return "A"
    case "approved_by_time":
      return "T"
    case "not_analised":
      return "NA"
    case "on_hold":
      return "E"
    default:
      return "?"
  }
}

export const getProperMetadata = (status_events) => {
  let nonNullMetadata = status_events.filter(
    (event) => event.decision_metadata != null
  )
  if (nonNullMetadata.length === 0) return {}
  else if (nonNullMetadata.length === 1)
    return nonNullMetadata[0].decision_metadata
  let finalMetadata = nonNullMetadata.sort((a, b) =>
    moment(b.event_date).diff(moment(a.event_date))
  )
  return finalMetadata[0].decision_metadata
}

export const getGlobalAlertStatusColor = (status) => {
  switch (status) {
    case "automatically_approved":
    case "manually_approved":
    case "approved_by_time":
      return colorGreen
    case "automatically_reproved":
    case "manually_reproved":
      return colorRed
    case "pending":
    case "in_manual_analysis":
      return colorOrange
    case "on_hold":
    case "manually_challenged":
    case "automatically_challenged":
      return colorYellow
    default:
      return colorGrey
  }
}

export const translateGender = (gender) => {
  switch (gender) {
    case "male":
      return "masculino"
    case "female":
      return "feminino"
    default:
      return gender
  }
}

export const getIndicatorsFlagColor = (flag) => {
  switch (flag) {
    case "positive":
      return colorGreen
    case "negative":
      return colorRed
    case "attention":
    case "neutral":
      return colorYellow
    default:
      return colorGrey
  }
}

export const getSourceChannel = (status) => {
  switch (status) {
    case "app":
      return "Aplicativo"
    case "internet_banking":
      return "Internet Banking"
    default:
      return status
  }
}

export const getSourcePlatform = (status) => {
  switch (status) {
    case "android":
      return "Android"
    case "web":
      return "Web"
    case "ios":
      return "IOS"
    default:
      return status
  }
}

export const getDevicePlatformName = (channel, platform) => {
  let translated_channel = getSourceChannel(channel)
  let translated_platform = getSourcePlatform(platform)
  if (channel && platform)
    return translated_platform + " via " + translated_channel
  else if (channel) return translated_channel
  else if (platform) return translated_platform
  else return null
}

export const getAnalyzeDialogType = (type) => {
  if (type === "lp") {
    return {
      label: "CNPJ",
      validation_size: 18,
      mask_type: "99.999.999/9999-99",
      post_endpoint: "legal_person_analyze",
    }
  } else {
    return {
      label: "CPF",
      validation_size: 14,
      mask_type: "999.999.999-99",
      post_endpoint: "natural_person_analyze",
    }
  }
}

export const getConfirmationIcon = (status) => {
  if (status) {
    return (
      <CheckIcon
        className="fraudStatusIcon"
        style={{ color: colorGreen, width: "40px", height: "40px" }}
      />
    )
  } else {
    return (
      <ClearIcon
        className="fraudStatusIcon"
        style={{ color: colorRed, width: "40px", height: "40px" }}
      />
    )
  }
}

export const getCircleStatusIcon = (analysis_status) => {
  return (
    <Tooltip
      title={
        <p className="tooltipText">
          {analysis_status
            ? translateAccountStatus(analysis_status)
            : "Não analisado"}
        </p>
      }
    >
      <div
        className={["circle", "superSmall"].join(" ")}
        style={{ backgroundColor: accountStatusColor(analysis_status) }}
      />
    </Tooltip>
  )
}

export const getPartnerValidationIcon = (partner) => {
  if (partner.is_payload_partner && partner.is_bureau_partner) {
    return (
      <Tooltip title={<p className="tooltipText">Sócio validado em Bureaus</p>}>
        <CheckIcon style={{ color: colorGreen }} />
      </Tooltip>
    )
  } else if (partner.is_payload_partner && !partner.is_bureau_partner) {
    return (
      <Tooltip
        title={<p className="tooltipText">Sócio não validado em Bureaus</p>}
      >
        <ErrorOutlineIcon style={{ color: colorRed }} />
      </Tooltip>
    )
  } else if (!partner.is_payload_partner && partner.is_bureau_partner) {
    return (
      <Tooltip
        title={
          <p className="tooltipText">
            Sócio encontrado em Bureau não fornecido
          </p>
        }
      >
        <ErrorOutlineIcon style={{ color: colorYellow }} />
      </Tooltip>
    )
  } else {
    return (
      <Tooltip title={<p className="tooltipText">Informação Indiponível</p>}>
        <ErrorOutlineIcon style={{ color: colorGrey }} />
      </Tooltip>
    )
  }
}

export const getCardIcon = (type) => {
  switch (type) {
    case "positive":
    case false:
      return <CheckIcon style={{ color: colorGreen }} />
    case "negative":
    case true:
      return <ErrorOutlineIcon style={{ color: colorRed }} />
    case "neutral":
      return <ErrorOutlineIcon style={{ color: colorYellow }} />
    default:
      return <ErrorOutlineIcon />
  }
}

export const getCardIconCredit = (type) => {
  switch (type) {
    case "positive":
    case false:
    case "false":
      return <CheckIcon style={{ color: colorGreenCredit }} />
    case "negative":
    case true:
    case "true":
      return <ErrorOutlineIcon style={{ color: colorRed }} />
    case "neutral":
      return <ErrorOutlineIcon style={{ color: colorYellow }} />
    default:
      return <ErrorOutlineIcon />
  }
}

export const getBoolIcon = (bool) => {
  if (bool == null) return null
  if (bool) {
    return <img src={greenCheck} alt="GreenCheck Logo" />
  } else {
    return <img src={redCross} alt="redCross Logo" />
  }
}

export const getIndicatorsFlagIcon = (flag, margin = null) => {
  let margin_ = "auto"
  if (margin != null) {
    margin_ = margin
  }
  switch (flag) {
    case "positive":
      return (
        <CheckIcon
          style={{
            verticalAlign: "middle",
            width: "20px",
            height: "20px",
            color: colorGreen,
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: margin_,
            marginLeft: margin_,
          }}
        />
      )
    case "negative":
      return (
        <ErrorOutlineIcon
          style={{
            verticalAlign: "middle",
            width: "20px",
            height: "20px",
            color: colorRed,
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: margin_,
            marginLeft: margin_,
          }}
        />
      )
    case "neutral":
    case "attention":
      return (
        <ErrorOutlineIcon
          style={{
            verticalAlign: "middle",
            width: "20px",
            height: "20px",
            color: colorYellow,
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: margin_,
            marginLeft: margin_,
          }}
        />
      )
    default:
      return (
        <ErrorOutlineIcon
          style={{
            verticalAlign: "middle",
            width: "20px",
            height: "20px",
            color: colorGrey,
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: margin_,
            marginLeft: margin_,
          }}
        />
      )
  }
}

export const getGlobalAlertFlagIcon = (flag) => {
  switch (flag) {
    case false:
      return (
        <CheckIcon
          style={{
            verticalAlign: "middle",
            width: "20px",
            height: "20px",
            color: colorGreen,
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        />
      )
    case true:
      return (
        <ErrorOutlineIcon
          style={{
            verticalAlign: "middle",
            width: "20px",
            height: "20px",
            color: colorRed,
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        />
      )
    default:
      return (
        <ErrorOutlineIcon
          style={{
            verticalAlign: "middle",
            width: "20px",
            height: "20px",
            color: colorGrey,
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        />
      )
  }
}

export const getAccountMonitoringTopicIcon = (flag) => {
  switch (flag) {
    case false:
      return (
        <CheckIcon
          style={{
            verticalAlign: "middle",
            width: "20px",
            height: "20px",
            color: colorGreen,
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        />

      )
    case true:
      return (
        <ErrorOutlineIcon
          style={{
            verticalAlign: "middle",
            width: "20px",
            height: "20px",
            color: colorRed,
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        />

      )
    default:
      return (
        <ErrorOutlineIcon
          style={{
            verticalAlign: "middle",
            width: "20px",
            height: "20px",
            color: colorGrey,
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        />
      )
  }
}

export const getMonitoringTopicIcon = (key, value, name) => {

  if (key === "receita_federal_name") {
    const normalizeText = (text) =>
      text.toLowerCase().trim();

    value = normalizeText(value) === normalizeText(name) ? false : true;
  }

  switch (value) {
    case false:
      return (
        <CheckIcon
          style={{
            verticalAlign: "middle",
            width: "20px",
            height: "20px",
            color: colorGreen,
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        />
      )
    case true:
      return (
        <ErrorOutlineIcon
          style={{
            verticalAlign: "middle",
            width: "20px",
            height: "20px",
            color: colorRed,
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        />
      )
    case 'regular':
      return (
        <CheckIcon
          style={{
            verticalAlign: "middle",
            width: "20px",
            height: "20px",
            color: colorGreen,
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        />
      )
    default:
      return (
        <ErrorOutlineIcon
          style={{
            verticalAlign: "middle",
            width: "20px",
            height: "20px",
            color: colorRed,
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        />
      )
  }
}

export const getMonitoringTopicName = (type) => {
  switch (type) {
    case "is_pep":
      return "É Pessoa Politicamente Exposta"
    case "is_on_ofac_list":
      return "Está Presente na Lista Restritiva da OFAC"
    case "is_on_unsc_list":
      return "Está Presente na Lista Restritiva da UNSC"
    case "is_on_ibama_list":
      return "Está Presente na Lista Restritiva do IBAMA"
    case "is_on_cnep_list":
      return "Está Presente na Lista Restritiva do CNEP"
    case "is_on_ceis_list":
      return "Está Presente na Lista Restritiva do CEIS"
    case "receita_federal_status":
      return "Status Na Receita Federal"
    case "receita_federal_name":
      return "Nome Na Receita Federal"
    default:
      return "Informação não encontrada"
  }
}

export const getGlobalAlertStatusEnum = (type) => {
  switch (type) {
    case false:
      return "Cliente não está presente nas listas monitoradas"
    case true:
      return "Cliente está presente em uma ou mais listas monitoradas"
    default:
      return "Informação não encontrada"
  }
}

export const getTranslatedGlobalAlertStatus = (value, data_icon) => {
  if (typeof value === "string") {
    return <>{getTranslateReceitaFederalStatus(value)} {data_icon}</>; 
  }

  return value ? <>Sim {data_icon}</> : <>Não {data_icon}</>; 
};

const getTranslateReceitaFederalStatus = (value) => {
  switch (value) {
    case 'regular':
      return 'Regular'
    case 'suspended':
      return 'Suspenso'
    case 'not_analyzed':
      return 'Não Analisado'
    case 'null':
      return 'Nulo'
    case 'liquidating':
      return 'Em Liquidação'
    case 'inactive':
      return 'Inativo'
    case 'canceled':
      return 'Cancelado'
    case 'not_found':
      return 'Número de Documento Não Encontrado'
    case 'pending_regularization':
      return 'Pendente de Regularização'
    case 'inapt':
      return 'Inapto'
    case 'dead':
      return 'Falecido'
    case 'minor':
      return 'Menor de Idade'
    case 'irregular':
      return "Irregular"
    case 'invalid_birthdate':
      return 'Data de Nascimento Inválida'
    case 'document_number_invalid':
      return 'Número de Documento Inválido'
    default:
      return value
  }
}


export const formatBureauInfo = (object, identifier) => {
  let formatFunction
  switch (identifier) {
    case "endereços":
      formatFunction = formatAddress
      break
    case "telefones":
      formatFunction = formatPhone
      break
    case "emails":
      formatFunction = formatEmail
      break
    default:
  }
  return (
    <div>
      Outros {identifier}:
      <ul>
        {object.map((item, index) => (
          <li key={index}>
            {formatFunction(item)} - {item.description}
          </li>
        ))}
      </ul>
    </div>
  )
}

export const formatAddress = (addressObj, nullCase) => {
  if (addressObj == null) return nullCase

  let streetString =
    addressObj.street == null ? "" : titleCase(addressObj.street, "") + ", "
  let numberString = addressObj.number == null ? "" : addressObj.number + ", "
  let neighborhoodString =
    addressObj.neighborhood == null
      ? ""
      : titleCase(addressObj.neighborhood, "") + ", "
  let cityString = titleCase(addressObj.city, "")
  let stateString = addressObj.uf == null ? "" : " - " + addressObj.uf

  return (
    streetString + numberString + neighborhoodString + cityString + stateString
  )
}

export const formatPhone = (phoneObj, nullCase) => {
  if (phoneObj == null) return nullCase

  let ddiString =
    phoneObj.internation_dial_code == null
      ? ""
      : phoneObj.internation_dial_code + " "
  let dddString =
    phoneObj.area_code == null ? "" : "(" + phoneObj.area_code + ") "
  let numberString = phoneObj.number == null ? "" : phoneObj.number

  return ddiString + dddString + numberString
}

function formatPhoneNumber(phoneNumber) {
  if (phoneNumber.length === 7) {
    return phoneNumber.slice(0, 4) + "-" + phoneNumber.slice(4);
  } else if (phoneNumber.length === 8) {
    return phoneNumber.slice(0, 4) + "-" + phoneNumber.slice(4);
  } else {
    return phoneNumber;
  }
}

export const formatPhoneObject = (phone, nullCase) => {
  if (phone == null) return nullCase;
  let number = phone.number.toString();;
  let area_code = phone.area_code;
  let country_code = phone.country_code;

  let fomatted_phone = formatPhoneNumber(number);

  let phoneString = "+" + country_code + " " + area_code + " " + fomatted_phone;

  return (phoneString)
}

const formatEmail = (emailObj, nullCase) => {
  if (emailObj == null) return nullCase
  return emailObj.email == null ? "" : emailObj.email
}

export const setAllowChanges = (status, roles, type) => {
  if (type === "np") {
    if (!roles.includes("update_onboarding_natural_persons")) {
      return false
    }
  } else {
    if (!roles.includes("update_onboarding_legal_persons")) {
      return false
    }
  }

  switch (status) {
    case "pending":
    case "in_manual_analysis":
    case "on_hold":
      return true
    default:
      return false
  }
}

export function replaceAll(str, mapObj) {
  var re = new RegExp(Object.keys(mapObj).join("|"), "gi")

  return str.replace(re, function (matched) {
    return mapObj[matched.toLowerCase()]
  })
}

export function checkNested(obj /*, level1, level2, ... levelN*/) {
  if (obj == null) return false
  var args = Array.prototype.slice.call(arguments, 1)

  for (var i = 0; i < args.length; i++) {
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return false
    }
    obj = obj[args[i]]
  }
  return true
}
export const formatAsMoney = (value, nullCase = null) => {
  if (value == null) return nullCase
  return (
    "R$ " + (value / 100).toLocaleString("de-DE", { maximumFractionDigits: 2 })
  )
}

export const clientAge = (birthdate, nullCase = null) => {
  if (birthdate == null) return nullCase
  return (
    moment(birthdate).format("DD/MM/YYYY") +
    " (" +
    moment().diff(moment(birthdate), "years") +
    " anos)"
  )
}

export const titleCase = (str, nullCase) => {
  if (str == null) {
    return nullCase
  }
  var splitStr = str.toLowerCase().split(" ")
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(" ")
}

export const upperCase = (str, nullCase) => {
  if (str == null) {
    return nullCase
  }
  return str.toUpperCase()
}

export function validateCPF(cpf) {
  if (typeof cpf !== "string") return false
  cpf = cpf.replace(/[^\d]+/g, '')

  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  ) return false

  var sum = 0
  var remainder
  for (var count = 1; count <= 9; count++)
    sum = sum + parseInt(cpf.substring(count - 1, count)) * (11 - count)
  remainder = (sum * 10) % 11
  if ((remainder === 10) || (remainder === 11)) remainder = 0
  if (remainder !== parseInt(cpf.substring(9, 10))) return false

  sum = 0
  for (count = 1; count <= 10; count++)
    sum = sum + parseInt(cpf.substring(count - 1, count)) * (12 - count)
  remainder = (sum * 10) % 11
  if ((remainder === 10) || (remainder === 11)) remainder = 0
  if (remainder !== parseInt(cpf.substring(10, 11))) return false

  return true
}

export function validateCNPJ(cnpj) {
  if (typeof cnpj !== "string") return false
  cnpj = cnpj.replace(/[^\d]+/g, "")
  if (cnpj === "") return false
  if (cnpj.length !== 14) return false

  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false

  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  let digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado.toString() !== digitos.charAt(0)) return false

  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado.toString() !== digitos.charAt(1)) return false

  return true
}

export const getTableRowClass = (rowIndex) => {
  if (rowIndex % 2 === 0) {
    return "even"
  }

  return "odd"
}

export const colorGreenCredit = "rgb(27, 139, 61)"

export const colorGreen = "#73C580"

export const colorRed = "#D91A1A"

export const colorGrey = "rgb(170, 167, 153)"

export const colorYellow = "#DDBB08"

export const colorOrange = "#F77B21"

export const serasa_translations = {
  serasa_doc_status: {
    regular: {
      color: colorGreen,
    },
    void: {
      color: colorGrey,
    },
    not_found: {
      color: colorGrey,
    },
    inactive: {
      color: colorRed,
    },
    inapt: {
      color: colorRed,
    },
    liquidating: {
      color: colorRed,
    },
    dead: {
      color: colorRed,
    },
    suspended: {
      color: colorRed,
    },
    canceled: {
      color: colorRed,
    },
    pending_regularization: {
      color: colorRed,
    },
  },
  risk_level: {
    Iminente: colorRed,
    Relevante: colorRed,
    Considerável: colorRed,
    Médio: colorYellow,
    Baixo: colorGreen,
    "Muito Baixo": colorGreen,
    Mínimo: colorGreen,
    "imminent_risk": colorRed,
    "relevant_risk": colorRed,
    "considerable_risk": colorRed,
    "medium_risk": colorYellow,
    "low_risk": colorGreen,
    "very_low_risk": colorGreen,
    "minimal_risk": colorGreen,
  },
  positive: {
    color: colorGreen,
    siglum: <CheckIcon style={{ color: colorGreen }} />,
  },
  negative: {
    siglum: <ErrorOutlineIcon style={{ color: colorRed }} />,
  },
  neutral: {
    siglum: <ErrorOutlineIcon style={{ color: colorYellow }} />,
  },
  default: {
    siglum: <ErrorOutlineIcon style={{ color: colorGrey }} />,
  },
}

export const getRiskLevel = (status) => {
  switch (status) {
    case "low":
      return (<span style={{ color: '#73C580' }}>Baixo</span>)
    case "high":
      return (<span style={{ color: '#D91A1A' }}>Alto</span>)
    case "critical":
      return (<span style={{ color: '#D91A1A' }}>Crítico</span>)
    case "medium":
      return (<span style={{ color: '#DDBB08' }}>Médio</span>)
    case undefined:
      return (<span style={{ color: "rgb(170, 167, 153)" }}>Não definido</span>)
    default:
      return (<span style={{ color: "rgb(170, 167, 153)" }}>{status}</span>)
  }
}

export const formatAsMoneyMinimum2Digits = (value, nullCase = null) => {
  if (value == null) return nullCase
  return (
    "R$ " + (value / 100).toLocaleString("pt-BR", { minimumFractionDigits: 2 })
  )
}

export const getConfirmationIconDialog = (status) => {
  if (status) {
    return (<CheckIcon className="fraudStatusIcon" style={{ color: colorGreen, width: "40px", height: "40px" }} />)
  }
  else {
    return (<ClearIcon className="fraudStatusIcon" style={{ color: colorRed, width: "40px", height: "40px" }} />)
  }
}

//Translate error messages
export const translateErrorMessage = (errorMessage) => {
  let translatedErrorMessage = ""
  if (errorMessage === "This CSV file have blank lines. Please remove them and try again") {
    translatedErrorMessage = <p>Este arquivo CSV possui linhas em branco. Por favor, remova-os e tente novamente.</p>
  }
  else if (errorMessage === "This CSV file have more than one column with data. The document numbers must be at the first column") {
    translatedErrorMessage = <p>Este arquivo CSV possui mais de uma coluna com dados. Os números dos documentos devem estar na primeira coluna.</p>
  }
  else if (errorMessage === "The supplied file is not a valid csv.") {
    translatedErrorMessage = <p>O arquivo fornecido não é um csv válido.</p>
  }
  else if (errorMessage === "This CSV file have unformatted or incorrect natural_person document numbers") {
    translatedErrorMessage = <p>Este arquivo CSV contém números de CPF não formatados ou incorretos.</p>
  }
  else if (errorMessage === "This CSV file have unformatted or incorrect legal_person document numbers") {
    translatedErrorMessage = <p>Este arquivo CSV contém números de CNPJ não formatados ou incorretos.</p>
  }
  else {
    translatedErrorMessage = <p>Ocorreu um problema com o processamento da sua solicitação. Tente novamente, caso o problema persista entre em contato através do email <b>suporte.caas@qitech.com.br</b>, informando esta mensagem e a página que ocorreu o problema.</p>
  }
  return translatedErrorMessage
}

export const retrieveReceitaFederalStatus = (description) => {
  if (description.includes('CNPJ não encontrado na Receita Federal')) {
    return {
      enum: 'Não Encontrado',
      tooltip: 'CNPJ não encontrado na Receita Federal'
    }
  }
  if (description.includes('CNPJ em situação regular com a Receita Federal')) {
    return {
      enum: 'Regular',
      tooltip: 'CNPJ em situação regular com a Receita Federal'
    }
  }
  if (description.includes('CNPJ inapto perante a Receita Federal')) {
    return {
      enum: 'Inapto',
      tooltip: 'CNPJ inapto perante a Receita Federal'
    }
  }
  if (description.includes('CNPJ baixado perante a Receita Federal')) {
    return {
      enum: 'Baixado',
      tooltip: 'CNPJ baixado perante a Receita Federal'
    }
  }
  if (description.includes('CNPJ nulo perante a Receita Federal')) {
    return {
      enum: 'Nulo',
      tooltip: 'CNPJ nulo perante a Receita Federal'
    }
  }
  if (description.includes('CNPJ supenso perante a Receita Federal')) {
    return {
      enum: 'Suspenso',
      tooltip: 'CNPJ supenso perante a Receita Federal'
    }
  }
  if (description.includes('CNPJ em liquidação perante a Receita Federal')) {
    return {
      enum: 'Em Liquidação',
      tooltip: 'CNPJ em liquidação perante a Receita Federal'
    }
  }
  if (description.includes('Documento em situação irregular com a Receita Federal')) {
    return {
      enum: 'Irregular',
      tooltip: 'Documento em situação irregular com a Receita Federal'
    }
  }
  if (description.includes('Documento pendente de regularização na Receita Federal')) {
    return {
      enum: 'Regularização Pendente',
      tooltip: 'Documento pendente de regularização na Receita Federal'
    }
  }
  if (description.includes('CPF não encontrado na Receita Federal')) {
    return {
      enum: 'Não Encontrado',
      tooltip: 'CPF não encontrado na Receita Federal'
    }
  }
  if (description.includes('Documento em situação regular com a Receita Federal')) {
    return {
      enum: 'Regular',
      tooltip: 'Documento em situação regular com a Receita Federal'
    }
  }
  if (description.includes('Óbito registrado na Receita Federal')) {
    return {
      enum: 'Óbito',
      tooltip: 'Óbito registrado na Receita Federal'
    }
  }
  if (description.includes('CPF pendente de regularização na Receita Federal')) {
    return {
      enum: 'Regularização Pendente',
      tooltip: 'CPF pendente de regularização na Receita Federal'
    }
  }
  if (description.includes('CPF suspenso na Receita Federal')) {
    return {
      enum: 'Suspenso',
      tooltip: 'CPF suspenso na Receita Federal'
    }
  }
  if (description.includes('CPF cancelado na Receita Federal')) {
    return {
      enum: 'Cancelado',
      tooltip: 'CPF cancelado na Receita Federal'
    }
  }
  if (description.includes('CPF nulo na Receita Federal')) {
    return {
      enum: 'Cancelado',
      tooltip: 'CPF nulo na Receita Federal'
    }
  }
  if (description.includes('CPF de um menor de idade')) {
    return {
      enum: 'Menor de Idade',
      tooltip: 'CPF de um menor de idade'
    }
  }
  if (description.includes('Documento em situação irregular com a Receita Federal')) {
    return {
      enum: 'Irregular',
      tooltip: 'Documento em situação irregular com a Receita Federal'
    }
  }
  if (description.includes('Data de Nascimento incorreta')) {
    return {
      enum: 'Data de Nascimento Incorreta',
      tooltip: 'Data de Nascimento incorreta'
    }
  }
  return {
    enum: description,
    tooltip: description
  };
}