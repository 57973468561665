export const default_roles_groups = [
  {
    "name": "Análise de Crédito de Pessoa Física",
    "manager_role": "manage_credit_analysis_natural_persons_permissions",
    "description": "Gerenciar acesso de usuários em Análise de Crédito de Pessoa Física",
    "roles": [
      {
        "name": "read_credit_analysis_natural_persons",
        "description": "Recuperar lista de análises de crédito de pessoas físicas e detalhes de cada análise.",
        "message": "Usuário pode visualizar (somente leitura) a lista dde análises de Crédito de Pessoas Físicas, detalhes de cada análise. Usuário não tem acesso para editar as análises."
      },
      {
        "name": "update_credit_analysis_natural_persons",
        "description": "Resolver as análises de crédito de pessoas físicas.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as análises derivadas para análise manual. Usuário não tem permissão para incluir nova análise de Crédito de Pessoa Física."
      },
      {
        "name": "create_credit_analysis_natural_persons",
        "description": "Incluir novas análises de crédito de pessoas físicas.",
      },
      // {
      //   "name": "read_pdf_credit_analysis",
      //   "description": "Exportar dados da análise em formato PDF.",
      // },
      // {
      //   "name": "read_credit_analysis_dashboard",
      //   "description": "Visualizar relatório das análises de Crédito de Pessoas Físicas.",
      // },
      {
        "name": "manage_credit_analysis_natural_persons_permissions",
        "description": "Gerenciar acesso de usuários em Análise de Crédito de Pessoa Física",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em ANÁLISE DE CRÉDITO DE PESSOA FÍSICA, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Análise de Crédito de Pessoa Jurídica",
    "manager_role": "manage_credit_analysis_legal_persons_permissions",
    "description": "Gerenciar acesso de usuários em Análise de Crédito de Pessoa Jurídica",
    "roles": [
      {
        "name": "read_credit_analysis_legal_persons",
        "description": "Recuperar lista de análises de crédito de pessoas jurídicas e detalhes de cada análise.",
        "message": "Usuário pode visualizar (somente leitura) a lista dde análises de crédito de pessoas jurídicas, detalhes de cada análise. Usuário não tem acesso para editar as análises."
      },
      {
        "name": "update_credit_analysis_legal_persons",
        "description": "Resolver as análises de crédito de pessoas jurídicas.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as análises derivadas para análise manual. Usuário não tem permissão para incluir nova análise de Crédito de Pessoa Física."
      },
      {
        "name": "create_credit_analysis_legal_persons",
        "description": "Incluir novas análises de crédito de pessoas jurídicas.",
      },
      // {
      //   "name": "read_pdf_credit_analysis",
      //   "description": "Exportar dados da análise em formato pdf.",
      // },
      // {
      //   "name": "read_credit_analysis_dashboard",
      //   "description": "Visualizar relatório das análises de crédito de pessoas jurídicas.",
      // },
      {
        "name": "manage_credit_analysis_legal_persons_permissions",
        "description": "Gerenciar acesso de usuários em análises de crédito de pessoas jurídicas",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Análise de Crédito de Pessoa Jurídica, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Pedidos",
    "manager_role": "manage_card_order_orders_permissions",
    "description": "Gerenciar acesso de usuários em pedidos",
    "roles": [
      {
        "name": "read_card_order_orders",
        "description": "Recuperar lista de transações de pedidos e detalhes de cada transação.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as transações e detalhes de cada transação, assim como filtrar as transações exibidas. Usuário não tem acesso para editar as transações ou exportar os dados."
      },
      {
        "name": "update_card_order_orders",
        "description": "Realizar análise das transações de pedidos.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as transações derivadas para análise manual."
      },
      {
        "name": "manage_card_order_orders_permissions",
        "description": "Gerenciar acesso de usuários em Pedidos.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Pedidos, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Pedidos - Alertas",
    "manager_role": "manage_card_order_alerts_permissions",
    "description": "Gerenciar acesso de usuários em alertas de pedidos",
    "roles": [
      {
        "name": "read_card_order_alerts",
        "description": "Recuperar lista de alertas das transações de pedidos apontadas como potencial fraude e detalhes de cada alerta.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos os alertas de fraude (pendentes ou tratados) e detalhes de cada alerta, assim como filtrar as transações exibidas. Usuário não tem acesso para editar os alertas ou exportar os dados."
      },
      {
        "name": "update_card_order_alerts",
        "description": "Realizar análise dos alertas das transações de pedidos apontadas como potencial fraude.",
        "message": "Usuário tem permissão para finalizar os alertas (sinalizar como válido ou inválido) das transações pendentes apontadas como potencial fraude. Usuário não tem permissão para bloquear a entidade de cartão." //VALIDAR EMÍLIO SOBRE BLOQUEIO NESSA PÁGINA DE ALERTAS => role separada?
      },
      {
        "name": "manage_card_order_alerts_permissions",
        "description": "Gerenciar acesso de usuários em Alertas de pedidos",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Alertas de pedidos, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Pedidos - Bloqueios",
    "manager_role": "manage_card_order_locks_permissions",
    "description": "Gerenciar acesso de usuários em bloqueios de entidades de cartão",
    "roles": [
      {
        "name": "read_card_order_locks",
        "description": "Recuperar lista de bloqueios das entidades de cartão.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todas as entidades de cartão bloqueadas/ desbloqueadas e histórico de cada bloqueio. Usuário não tem acesso para editar os bloqueios ou exportar os dados."
      },
      {
        "name": "update_card_order_locks",
        "description": "Editar bloqueios das entidades de cartão.",
        "message": "Usuário tem permissão para revisar (bloquear ou desbloquear) as entidades de cartão na página de Bloqueios. Usuário não tem permissão para criar bloqueio para uma nova entidade de cartão."
      },
      {
        "name": "create_card_order_locks",
        "description": "Criar bloqueio para uma nova entidade de cartão.",
        "message": "Usuário tem permissão para bloquear novas entidades de cartão."
      },
      {
        "name": "manage_card_order_locks_permissions",
        "description": "Gerenciar acesso de usuários em Bloqueios de entidades de cartão.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Bloqueios de entidades de cartão, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Cadastros de Pessoa Fisica",
    "manager_role": "manage_onboarding_natural_persons_permissions",
    "description": "Gerenciar acesso de usuários em pessoas físicas",
    "roles": [
      {
        "name": "read_onboarding_natural_persons",
        "description": "Recuperar lista de análises de pessoas físicas e detalhes de cada análise.",
        "message": "Usuário pode visualizar (somente leitura) a lista de análises de pessoas físicas, detalhes de cada análise, assim como filtrar a lista exibida. Usuário não tem acesso para editar as análises ou exportar dados em formato pdf ou CSV."
      },
      // {
      //   "name": "read_onboarding_filtered_pending_status_natural_persons",
      //   "description": "Recuperar lista de análises de pessoas físicas apenas com status pendente e detalhes de cada análise.",
      //   "message": "Usuário pode visualizar (somente leitura) a lista de análises de pessoas físicas apenas com status pendente. Usuário não tem acesso às análises com análise concluída. Usuário não tem acesso para editar as análises ou exportar dados em formato pdf ou CSV."
      // },
      // {
      //   "name": "read_onboarding_history_natural_person",
      //   "description": "Recuperar histórico de análises da pessoa física em questão.",
      // },
      {
        "name": "update_onboarding_natural_persons",
        "description": "Resolver as análises de pessoas físicas.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as análises derivadas para análise manual. Usuário não tem permissão para incluir nova análise de pessoa física."
      },
      {
        "name": "read_pdf_onboarding",
        "description": "Exportar dados da análise em formato pdf.",
      },
      {
        "name": "create_onboarding_natural_persons",
        "description": "Incluir novas análises de pessoas físicas.",
      },
      // {
      //   "name": "read_onboarding_dashboard",
      //   "description": "Visualizar relatório das análises de pessoas físicas.",
      // },
      // {
      //   "name": "read_onboarding_filtered_company_dashboard",
      //   "description": "Visualizar relatório das análises de pessoas físicas por Cliente.",
      // },
      // {
      //   "name": "read_onboarding_natural_persons_reports",
      //   "description": "Exportar relatório das análises de pessoas físicas.",
      //   "message": "Usuário tem permissão para solicitar novos relatório das análises de pessoas físicas, conforme filtros disponíveis, assim como, acessar relatórios prontos para exportar."
      // },
      {
        "name": "manage_onboarding_natural_persons_permissions",
        "description": "Gerenciar acesso de usuários em pessoas físicas.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em pessoas físicas, assim como, cadastrar novos usuários."
      },
      {
        "name": "read_onboarding_filtered_pending_status_natural_persons",
        "description": "Recuperar somente a lista de análises de pessoas físicas com status pendente e detalhes de cada análise.",
        "message": "Usuário pode visualizar (somente leitura) somente a lista de análises de pessoas físicas com status pendente. Usuário não tem acesso às análises com análise concluída. Usuário não tem acesso para editar as análises ou exportar dados em formato pdf ou CSV."
      }
    ]
  },
  {
    "name": "Cadastros de Pessoa Jurídica",
    "manager_role": "manage_onboarding_legal_persons_permissions",
    "description": "Gerenciar acesso de usuários em pessoas jurídicas",
    "roles": [
      {
        "name": "read_onboarding_legal_persons",
        "description": "Recuperar lista de análises de pessoas jurídicas e detalhes de cada análise.",
        "message": "Usuário pode visualizar (somente leitura) a lista de análises de pessoas jurídicas, detalhes de cada análise, assim como filtrar a lista exibida. Usuário não tem acesso para editar as análises ou exportar dados em formato pdf ou CSV."
      },
      { 
        "name": "update_onboarding_legal_persons",
        "description": "Resolver as análises de pessoas jurídicas.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as análises derivadas para análise manual."
      },
      {
        "name": "read_pdf_onboarding",
        "description": "Exportar dados da análise em formato pdf.",
      },
      {
        "name": "create_onboarding_legal_persons",
        "description": "Incluir novas análises de pessoas jurídicas.",
      },
      // {
      //   "name": "read_onboarding_dashboard",
      //   "description": "Visualizar relatório das análises de pessoas jurídicas.",
      // },
      // {
      //   "name": "read_onboarding_filtered_company_dashboard",
      //   "description": "Visualizar relatório das análises de pessoas jurídicas por Cliente.",
      // },
      // {
      //   "name": "read_onboarding_legal_persons_reports",
      //   "description": "Exportar relatório das análises de pessoas jurídicas.",
      //   "message": "Usuário tem permissão para solicitar novos relatório das análises de pessoas jurídicas, conforme filtros disponíveis, assim como, acessar relatórios prontos para exportar."
      // },
      {
        "name": "manage_onboarding_legal_persons_permissions",
        "description": "Gerenciar acesso de usuários em Pessoas Jurídicas.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Pessoas Jurídicas, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Alertas - Transações Bancárias",
    "manager_role": "manage_banking_alerts_permissions",
    "description": "Gerenciar acesso de usuários em alertas de transações bancárias",
    "roles": [
      {
        "name": "read_banking_alerts", 
        "description": "Recuperar lista de alertas das transações bancárias apontadas como potencial fraude e detalhes de cada alerta.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos os alertas de fraude (pendentes ou tratados), assim como filtrar as transações exibidas e detalhes de cada alerta."
      },
      {
        "name": "update_banking_alerts", 
        "description": "Realizar análise dos alertas das transações bancárias apontadas como potencial fraude.",
        "message": "Usuário tem permissão para finalizar os alertas (sinalizar como válido ou inválido) das transações pendentes apontadas como potencial fraude. Usuário não tem permissão para bloquear o usuário."
      },
      // {
      //   "name": "read_alerts_dashboard",
      //   "description": "Visualizar relatório de análise dos alertas das transações bancárias.",
      // },
      // {
      //   "name": "read_banking_alerts_reports",
      //   "description": "Exportar relatório dos alertas de transações bancárias.",
      //   "message": "Usuário tem permissão para solicitar novos relatório dos alertas de transações bancárias, conforme filtros disponíveis, assim como, acessar relatórios prontos para exportar."
      // },
      {
        "name": "manage_banking_alerts_permissions",
        "description": "Gerenciar acesso de usuários em Alertas de Transações Bancárias.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Alertas de banking, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Boletos",
    "manager_role": "manage_bankslips_permissions",
    "description": "Gerenciar acesso de usuários em boletos",
    "roles": [
      {
        "name": "read_bankslips",
        "description": "Recuperar lista de transações de boletos e detalhes de cada transação.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as transações de boletos e detalhes de cada transação, incluindo histórico de fraude, assim como filtrar as transações de boletos exibidas. Usuário não tem acesso para editar as transações ou exportar os dados."
      },
      {
        "name": "update_bankslips",
        "description": "Realizar análise das transações de boletos, reportar as transações de fraude, atualizar histórico de fraude e exportar relatório de fraude.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as transações de boletos derivadas para análise manual, sinalizar as transações de boleto apontadas como fraude, atualizar histórico de fraude, incluindo upload e download de arquivos, e exportar relatório de fraude em formato CSV."
      },
      // {
      //   "name": "read_bankslip_dashboard",
      //   "description": "Visualizar relatório de análise das transações de boletos.",
      // },
      {
        "name": "manage_bankslips_permissions",
        "description": "Gerenciar acesso de usuários em Boletos.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Boletos, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Boletos - Bloqueios",
    "manager_role": "manage_bankslip_locks_permissions",
    "description": "Gerenciar acesso de usuários em bloqueios de boletos",
    "roles": [
      {
        "name": "read_bankslip_locks",
        "description": "Recuperar lista de usuários bloqueados.", 
        "message": "Usuário pode visualizar a lista de todos os usuários bloqueados/ desbloqueados e histórico de cada bloqueio. Usuário não tem permissão para revisar ou incluir novos bloqueios."
      },
      {
        "name": "update_banking_locks",
        "description": "Revisar usuários bloqueados.",
        "message": "Usuário tem permissão para revisar (desbloquear) os usuários bloqueados para as transações bancárias."
      },
      {
        "name": "create_bankslip_locks",
        "description": "Criar novos bloqueios de Boletos.",
        "message": "Usuário tem permissão para bloquear novos usuários para as transações de boleto."
      },
      {
        "name": "manage_bankslip_locks_permissions",
        "description": "Gerenciar acesso de usuários em Bloqueios de Boletos.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Bloqueios de Boletos, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Pagamento de Contas",
    "manager_role": "manage_bill_payments_permissions",
    "description": "Gerenciar acesso de usuários em pagamento de contas",
    "roles": [
      {
        "name": "read_bill_payments",
        "description": "Recuperar lista de transações de pagamento de contas e detalhes de cada transação.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as transações de pagamento de contas e detalhes de cada transação, incluindo histórico de fraude e filtrar as transações de pagamento de contas exibidas. Usuário não tem acesso para editar as transações ou exportar os dados."
      },
      {
        "name": "update_bill_payments",
        "description": "Realizar análise das transações de pagamento de contas, reportar as transações de fraude, atualizar histórico de fraude e exportar relatório de fraude.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as transações de pagamento de contas derivadas para análise manual, sinalizar as transações de pagamento de contas apontadas como fraude, atualizar histórico de fraude, incluindo upload e download de arquivos, e exportar relatório de fraude em formato CSV."
      },
      // {
      //   "name": "read_bill_payment_dashboard",
      //   "description": "Visualizar relatório de análise das transações de pagamento de contas.",
      // },
      {
        "name": "manage_bill_payments_permissions",
        "description": "Gerenciar acesso de usuários em Pagamento de Contas.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Pagamento de Contas, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Pagamento de Contas - Bloqueios",
    "manager_role": "manage_bill_payment_locks_permissions",
    "description": "Gerenciar acesso de usuários em bloqueios de pagamento de contas",
    "roles": [
      {
        "name": "read_bill_payment_locks",
        "description": "Recuperar lista de usuários bloqueados.", 
        "message": "Usuário pode visualizar a lista de todos os usuários bloqueados/ desbloqueados e histórico de cada bloqueio. Usuário não tem permissão para revisar ou incluir novos bloqueios."
      },
      {
        "name": "update_banking_locks",
        "description": "Revisar usuários bloqueados.",
        "message": "Usuário tem permissão para revisar (desbloquear) os usuários bloqueados para as transações bancárias."
      },
      {
        "name": "create_bill_payment_locks",
        "description": "Criar novos bloqueios de Pagamento de Contas.",
        "message": "Usuário tem permissão para bloquear novos usuários para pagamento de contas."
      },
      {
        "name": "manage_bill_payment_locks_permissions",
        "description": "Gerenciar acesso de usuários em Bloqueios de Pagamento de Contas.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Bloqueios de Pagamento de Contas, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Transferências",
    "manager_role": "manage_wire_transfers_permissions",
    "description": "Gerenciar acesso de usuários em transferências",
    "roles": [
      {
        "name": "read_wire_transfers",
        "description": "Recuperar lista de transações de transferências, detalhes de cada transação.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as transações de transferências e detalhes de cada transação, incluindo histórico de fraude e filtrar as transações de transferências exibidas. Usuário não tem acesso para editar as transações."
      },
      {
        "name": "update_wire_transfers",
        "description": "Realizar análise das transações de transferências, reportar as transações de fraude, atualizar histórico de fraude e exportar relatório de fraude.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as transações de transferências derivadas para análise manual, sinalizar as transações de transferências apontadas como fraude, atualizar histórico de fraude, incluindo upload e download de arquivos, e exportar relatório de fraude em formato CSV."
      },
      // {
      //   "name": "read_wire_transfer_dashboard",
      //   "description": "Visualizar relatório de análise das transações de transferências.",
      // },
      {
        "name": "manage_wire_transfers_permissions",
        "description": "Gerenciar acesso de usuários em Transferências.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Transferências, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Transferências - Bloqueios",
    "manager_role": "manage_wire_transfer_locks_permissions",
    "description": "Gerenciar acesso de usuários em bloqueios de tranferências",
    "roles": [
      {
        "name": "read_wire_transfer_locks",
        "description": "Recuperar lista de usuários bloqueados.", 
        "message": "Usuário pode visualizar a lista de todos os usuários bloqueados/ desbloqueados e histórico de cada bloqueio. Usuário não tem permissão para revisar ou incluir novos bloqueios."
      },
      {
        "name": "update_banking_locks",
        "description": "Revisar usuários bloqueados.",
        "message": "Usuário tem permissão para revisar (desbloquear) os usuários bloqueados para as transações bancárias."
      },
      {
        "name": "create_wire_transfer_locks",
        "description": "Criar novos bloqueios de Tranferências.",
        "message": "Usuário tem permissão para bloquear novos usuários para Tranferências."
      },
      {
        "name": "manage_wire_transfer_locks_permissions",
        "description": "Gerenciar acesso de usuários em Bloqueios de Tranferências.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Bloqueios de Tranferências, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "PIX - Transações & PIX - Relatos",
    "manager_role": "manage_pix_transactions_permissions",
    "description": "Gerenciar acesso de usuários em PIX - Transações e PIX - Relatos",
    "roles": [
      {
        "name": "read_pix_transactions",
        "description": "Recuperar lista de todas as transações de PIX (PIX - Transações) e todas as transações de PIX relatadas como fraude ou compliance (PIX - Relatos), detalhes de cada transação.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as transações de PIX e todas as transações de PIX relatadas, assim como detalhes de cada transação. Usuário não tem acesso para editar as transações."
      },
      {
        "name": "update_pix_transactions",
        "description": "Realizar análise das transações de PIX, reportar as transações de fraude e exportar relatório de fraude.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as transações de PIX derivadas para análise manual, sinalizar as transações de PIX apontadas como fraude, atualizar histórico de fraude, incluindo upload e download de arquivos, e exportar relatório de fraude em formato CSV."
      },
      // {
      //   "name": "create_infraction_report_pix_transactions", // NÃO IMPLEMENTADO NO CÓDIGO DASHBOARD, NEM DASH_API, NEM KEYCLOACK
      //   "description": "Relatar uma transação de PIX.",
      //   "message": "Usuário tem permissão para criar novos relatos de fraude ou compliance para as transações de PIX."
      // },
      // {
      //   "name": "read_pix_transaction_dashboard",
      //   "description": "Visualizar relatório de análise das transações de PIX.",
      // },
      {
        "name": "manage_pix_transactions_permissions",
        "description": "Gerenciar acesso de usuários em PIX - Transações e PIX - Relatos.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em PIX - Transações e e PIX - Relatos, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "PIX - Pré Transações",
    "manager_role": "manage_pre_pix_transactions_permissions",
    "description": "Gerenciar acesso de usuários para pré transações de PIX",
    "roles": [
      {
        "name": "read_pre_pix_transactions",
        "description": "Visualizar Pré Transações PIX",
        "message": "Usuário pode visualizar a lista de pré transações de PIX"
      },
      {
        "name": "manage_pre_pix_transactions_permissions",
        "description": "Gerenciar acesso de usuário para telas de Pré Transações PIX",
        "message": "Usuário pode gerenciar e persnonalizar as permissões de acesso dos usuário em Pré Transações PIX"
      }
    ]
  },
  {
    "name": "PIX - Transações - Bloqueios",
    "manager_role": "manage_pix_transaction_locks_permissions",
    "description": "Gerenciar acesso de usuários em bloqueios de PIX - Transações",
    "roles": [
      {
        "name": "read_pix_transaction_locks",
        "description": "Recuperar lista de usuários bloqueados.",
        "message": "Usuário pode visualizar a lista de todos os usuários bloqueados/ desbloqueados e histórico de cada bloqueio. Usuário não tem acesso para criar novos bloqueios."
      },
      {
        "name": "update_banking_locks", 
        "description": "Revisar usuários bloqueados.",
        "message": "Usuário tem permissão para revisar (desbloquear) os usuários bloqueados para as transações bancárias."
      },
      {
        "name": "create_pix_transaction_locks",
        "description": "Criar novos bloqueios para as transações de pix.",
        "message": "Usuário tem permissão para bloquear novos usuários para as transações de pix."
      },
      {
        "name": "manage_pix_transaction_locks_permissions",
        "description": "Gerenciar acesso de usuários em Bloqueios de PIX - Transações.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Bloqueios, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "PIX - DICT",
    "manager_role": "manage_pix_dict_operations_permissions",
    "description": "Gerenciar acesso de usuários em PIX - DICT",
    "roles": [
      {
        "name": "read_pix_dict_operations",
        "description": "Recuperar lista de operações de PIX - DICT, detalhes de cada operação.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as operações de PIX - DICT, detalhes de cada operação e filtrar as operações exibidas. Usuário não tem acesso para editar as análises das operações."
      },
      {
        "name": "update_pix_dict_operations",
        "description": "Realizar análise das operações de PIX - DICT.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as operações de PIX - DICT derivadas para análise manual."
      },
      // {
      //   "name": "read_pix_dict_operation_dashboard",
      //   "description": "Visualizar relatório de análise das operações de PIX - DICT.",
      // },
      {
        "name": "manage_pix_dict_operations_permissions",
        "description": "Gerenciar acesso de usuários em PIX - DICT.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em PIX - DICT, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "PIX - DICT - Bloqueios",
    "manager_role": "manage_pix_dict_operation_locks_permissions",
    "description": "Gerenciar acesso de usuários em bloqueios de PIX - DICT",
    "roles": [
      {
        "name": "read_pix_dict_operation_locks",
        "description": "Recuperar lista de usuários bloqueados.",
        "message": "Usuário pode visualizar a lista de todos os usuários bloqueados/ desbloqueados e histórico de cada bloqueio. Usuário não tem acesso para criar novos bloqueios."
      },
      {
        "name": "update_banking_locks", 
        "description": "Revisar usuários bloqueados.",
        "message": "Usuário tem permissão para revisar (desbloquear) os usuários bloqueados para as operações de PIX - DICT."
      },
      {
        "name": "create_pix_dict_operation_locks", 
        "description": "Criar novos bloqueios para as operações de PIX - DICT.",
        "message": "Usuário tem permissão para bloquear novos usuários para as operações de pix dict."
      },
      {
        "name": "manage_pix_dict_operation_locks_permissions",
        "description": "Gerenciar acesso de usuários em Bloqueios de PIX - DICT.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Bloqueios, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Saques",
    "manager_role": "manage_withdrawals_permissions",
    "description": "Gerenciar acesso de usuários em saques",
    "roles": [
      {
        "name": "read_withdrawals",
        "description": "Recuperar lista de transações de saque e detalhes de cada transação.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as transações de saque e detalhes de cada transação, incluindo histórico de fraude e filtrar as transações de saque exibidas. Usuário não tem acesso para editar as transações."
      },
      {
        "name": "manage_withdrawals_permissions",
        "description": "Gerenciar acesso de usuários em Saques.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Saques, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Depósitos",
    "manager_role": "manage_deposits_permissions",
    "description": "Gerenciar acesso de usuários em depósitos",
    "roles": [
      {
        "name": "read_deposits",
        "description": "Recuperar lista de transações de depósito e detalhes de cada transação.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as transações de depósito e detalhes de cada transação, incluindo histórico de fraude e filtrar as transações de depósito exibidas. Usuário não tem acesso para editar as transações."
      },
      {
        "name": "manage_deposits_permissions",
        "description": "Gerenciar acesso de usuários em Depósitos.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Depósitos, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Saques - Bloqueios",
    "manager_role": "manage_withdrawal_locks_permissions",
    "description": "Gerenciar acesso de usuários em bloqueios de saques",
    "roles": [
      {
        "name": "read_withdrawal_locks",
        "description": "Recuperar lista de usuários bloqueados.",
        "message": "Usuário pode visualizar a lista de todos os usuários bloqueados/ desbloqueados e histórico de cada bloqueio. Usuário não tem acesso para criar novos bloqueios."
      },
      {
        "name": "update_banking_locks", 
        "description": "Revisar usuários bloqueados.",
        "message": "Usuário tem permissão para revisar (desbloquear) os usuários bloqueados para as operações de Saque."
      },
      {
        "name": "create_withdrawal_locks",
        "description": "Criar novos bloqueios para as operações de Saque",
        "message": "Usuário tem permissão para bloquear novos usuários para as operações de Saque"
      },
      {
        "name": "manage_withdrawal_locks_permissions",
        "description": "Gerenciar acesso de usuários em Bloqueios de Saques.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Bloqueios, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Relatórios",
    "manager_role": "manage_reports",
    "description": "Gerenciar acesso de usuários em relatórios",
    "roles": [
      {
        "name": "read_reports",
        "description": "Recuperar lista de relatórios emitidos.",
        "message": "Usuário pode visualizar a lista de todos os relatórios já emitidos."
      },
      {
        "name": "create_reports", 
        "description": "Emitir novos relatórios",
        "message": "Usuário tem permissão para emitir novos relatórios."
      },
      {
        "name": "manage_reports",
        "description": "Gerenciar acesso de usuários em Relatórios",
        "message": "Usuário tem permissão para gerenciar permissões em Relatórios."
      }
    ]
  },
  {
    "name": "Transações",
    "manager_role": "card_issuance_transaction_manager",
    "description": "Gerenciar acesso de usuários em transações",
    "roles": [
      {
        "name": "card_issuance_transaction_viewer",
        "description": "Recuperar lista de transações, detalhes de cada transação e exportar dados em formato CSV.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as transações e detalhes de cada transação."
      },
      {
        "name": "card_issuance_transaction_read_onboarding_natural_person_by_key",
        "description": "Recuperar os detalhes da análise de Onboarding da pessoa física vinculada à transação em questão."
      },
      {
        "name": "card_issuance_transaction_read_onboarding_legal_person_by_key",
        "description": "Recuperar os detalhes da análise de Onboarding da pessoa jurídica vinculada à transação em questão."
      },
      {
        "name": "card_issuance_transaction_manager",
        "description": "Gerenciar acesso de usuários em transações.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Transações, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Transações - Alertas",
    "manager_role": "card_issuance_alert_manager",
    "description": "Gerenciar acesso de usuários em alertas de transações de emissão de cartão",
    "roles": [
      {
        "name": "card_issuance_alert_viewer",
        "description": "Recuperar lista de alertas das transações de emissão de cartão apontadas como potencial fraude e detalhes de cada alerta.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos os alertas de fraude (pendentes ou tratados) e detalhes de cada alerta, assim como filtrar as transações exibidas. Usuário não tem acesso para editar os alertas."
      },
      {
        "name": "card_issuance_alert_analyzer",
        "description": "Realizar análise dos alertas das transações de emissão de cartão apontadas como potencial fraude, assim como gerenciar bloqueio para a entidade em questão.",
        "message": "Usuário tem permissão para finalizar os alertas das transações pendentes, apontadas como potencial fraude, assim como bloquear/desbloquear a entidade em questão."
      },
      {
        "name": "card_issuance_alert_read_onboarding_natural_person_by_key",
        "description": "Recuperar os detalhes da análise de Onboarding da pessoa física vinculada ao alerta em questão."
      },
      {
        "name": "card_issuance_alert_read_onboarding_legal_person_by_key",
        "description": "Recuperar os detalhes da análise de Onboarding da pessoa jurídica vinculada ao alerta em questão."
      },
      {
        "name": "card_issuance_alert_manager",
        "description": "Gerenciar acesso de usuários em alertas de transações de emissão de cartão.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Alertas de transações de emissão de cartão, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Transações - Bloqueios",
    "manager_role": "card_issuance_lock_manager",
    "description": "Gerenciar acesso de usuários em bloqueios das entidades de emissão de cartão.",
    "roles": [
      {
        "name": "card_issuance_lock_viewer",
        "description": "Recuperar lista de bloqueios das entidades de emissão de cartão, assim como desbloquear as mesmas.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todas as entidades de emissão de cartão bloqueadas/ desbloqueadas e histórico de cada bloqueio, assim como desbloquear as entidades. Usuário não tem acesso para criar novos bloqueios."
      },
      {
        "name": "card_issuance_lock_analyzer",
        "description": "Criar novos bloqueios das entidades de cartão.",
        "message": "Usuário tem permissão para criar novos bloqueios de entidades de emissão de cartão.",
      },
      {
        "name": "card_issuance_lock_manager",
        "description": "Gerenciar acesso de usuários em Bloqueios das entidades de emissão de cartão.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Bloqueios de entidades de emissão de cartão, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Aluguéis",
    "manager_role": "rental_agreements_manager",
    "description": "Gerenciar acesso de usuários em aluguéis",
    "roles": [
      {
        "name": "rental_agreements_viewer",
        "description": "Recuperar lista de aluguéis, detalhes de cada aluguel e exportar dados em formato CSV.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as transações de aluguel e detalhes de cada aluguel."
      },
      {
        "name": "rental_agreements_analyzer", 
        "description": "Realizar análise das transações de aluguel e enviar e receber mensagens.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as transações de aluguel derivadas para análise manual."
      },
      // {
      //   "name": "rental_agreements_dashboard_viewer",
      //   "description": "Visualizar relatório de análise das transações de aluguel.",
      // },
      {
        "name": "read_car_rental_agreements_filtered_sac",
        "description": "Recuperar lista de aluguéis e detalhes de cada aluguel, para um determinado CPF pesquisado, exibindo apenas as informações permitidas para o SAC.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as transações de aluguel e detalhes de cada aluguel, para um determinado CPF pesquisado. As informações exibidas são filtradas para o usuário tipo SAC."
      },
      {
        "name": "rental_agreements_manager",
        "description": "Gerenciar acesso de usuários em aluguéis. Transitar entre as telas de aluguéis e aluguéis - SAC, desde que possua as respectivas permissões.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em aluguéis, assim como, cadastrar novos usuários. Usuário consegue verificar tanto a listagem de aluguéis, quanto a listagem de aluguéis - SAC, desde que possua as respectivas permissões de leitura. "
      }
    ]
  },
  {
    "name": "Reservas",
    "manager_role": "manage_car_rental_reservations_permissions",
    "description": "Gerenciar acesso de usuários em reservas",
    "roles": [
      {
        "name": "read_car_rental_reservations",
        "description": "Recuperar lista de reservas e detalhes de cada reserva.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as transações de reserva e detalhes de cada reserva."
      },
      {
        "name": "rental_agreements_viewer",
        "description": "Recuperar histórico de aluguéis.",
        "message": "Usuário pode visualizar (somente leitura) o histórico de todos os aluguéis realizados para o cliente da reserva em questão." 
      },
      {
        "name": "update_car_rental_reservations", 
        "description": "Realizar análise das reservas.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as reservadas derivadas para análise manual."
      },
      {
        "name": "manage_car_rental_reservations_permissions",
        "description": "Gerenciar acesso de usuários em Reservas.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Reservas, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Listas",
    "manager_role": "lists_manager",
    "description": "Gerenciar acesso de usuários em Listas",
    "roles": [
      // {
      //   "name": "create_lists",
      //   "description": "Criar novas listas."
      // },
      // {
      //   "name": "delete_lists",
      //   "description": "Deletar listas existentes."
      // },
      {
        "name": "lists_manager",
        "description": "Gerenciar acesso de usuários em listas vigentes.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: visualização/ criação) dos usuários em Listas, assim como, cadastrar novos usuários."
      },
      {
        "name": "lists_analyzer",
        "description": "Criar, excluir e compartilhar listas.",
        "message": "Usuário pode criar novas listas ou excluir listas existentes."
      },
      {
        "name": "lists_viewer",
        "description": "Acessar produto de listas com as configurações de permissões setadas.",
        "message": "Usuário pode acessar o produto de listas. O usuário só poderá acessar e modificar as listas conforme as permissões selecionadas."
      }
    ]
  },
  {
    "name": "Regras",
    "manager_role": "rule_manager",
    "description": "Gerenciar acesso de usuários em regras",
    "roles": [
      {
        "name": "rule_viewer", 
        "description": "Recuperar lista de todas as regras, detalhes de cada regra, e respectivas versões. Usuário também tem permissão para exportar dados em formato CSV. Obs: Os usuários também terão acesso de leitura à todas as listas.",
      },
      {
        "name": "rule_editor", // NO CÓDIGO NÃO EXISTE SEPARAÇÃO DE RULE_VIEWER E RULE_EDITOR, MAS DASH_API NÃO PERMITE SALVAR SE NÃO TIVER EDITOR
        "description": "Criar novas regras, assim como ativar, editar, renomear, duplicar ou excluir regras existentes. Além disso, pode aprovar ou reprovar rascunhos de regras existentes.",
      },
      {
        "name": "rule_manager",
        "description": "Gerenciar acesso de usuários em regras.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: visualização/ criação) dos usuários em regras, assim como, cadastrar novos usuários."
      },
      {
        "name": "rule_draft_editor",
        "description": "Criar novos rascunhos de regra, editar rascunhos de regra e descartar rascunhos de regra. Obs: As regras rascunho só serão ativadas quando aprovadas.",
      }
    ]
  },
  {
    "name": "Operações",
    "manager_role": "operations_manager",
    "description": "Gerenciar acesso de usuários em operações de câmbio",
    "roles": [
      {
        "name": "operations_viewer",
        "description": "Recuperar lista de operações de câmbio e detalhes de cada operação.",
        "message": "Usuário pode visualizar (somente leitura) a lista de operações de câmbio e detalhes de cada operação, assim como filtrar a lista exibida. Usuário não tem acesso para editar as análises ou exportar os dados."
      },
      {
        "name": "operations_analyzer",
        "description": "Realizar análise das operações de câmbio e exportar dados em formato CSV.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as operações de câmbio derivadas para análise manual."
      },
      {
        "name": "operations_manager",
        "description": "Gerenciar acesso de usuários em operações de câmbio.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em operações de câmbio, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Integração",
    "manager_role": "manage_integration",
    "description": "Gerenciar Integração de api para todos os produtos",
    "roles": [
      {
        "name": "manage_integration",
        "description": "Gerenciar Integração de api para todos os produtos.",
      }
    ]
  },
  {
    "name": "Depósitos",
    "manager_role": "manage_deposits_permissions",
    "description": "Gerenciar acesso de usuários em operações de depósito",
    "roles": [
      {
        "name": "read_deposits",
        "description": "Recuperar lista de operações de depósitos e detalhes de cada operação.",
        "message": "Usuário pode visualizar a lista de operações de depósitos e detalhes de cada operação, assim como filtrar a lista exibida. Usuário não tem acesso para editar as análises ou exportar os dados."
      }
    ]
  }
]

export const default_lists_permissions = [
  {
    "name": "read_list",
    "message": "Ler listas.",
    "description":"Usuário pode visualizar a tela de listagem com as listas escolhidas."
  },
  {
    "name": "read_item",
    "message": "Ler itens nas listas.",
    "description":"Usuário pode vizualizar os itens das listas escolhidas."
  },
  {
    "name": "create_item",
    "message": "Criar itens nas listas.",
    "description":"Usuário pode criar itens nas listas escolhidas."
  },
  {
    "name": "delete_item",
    "message": "Excluir itens nas listas.",
    "description":"Usuário pode excluir itens nas listas escolhidas."
  },
  {
    "name": "update_item",
    "message": "Atualizar itens nas listas.",
    "description":"Usuário pode atualizar itens nas listas escolhidas."
  }
]

export const formatGroupName = (input) => {
  return input.toLowerCase().replace(/\s+/g, ' ').trim().replaceAll(' ','_').normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

export var default_roles_groups_manager = [
  {
    "name": "Análise de Crédito de Pessoa Física",
    "manager_role": "manage_credit_analysis_natural_persons_permissions",
    "description": "Gerenciar acesso de usuários em Análise de Crédito de Pessoa Física",
    "roles": [
      {
        "name": "read_credit_analysis_natural_persons",
        "description": "Recuperar lista de análises de crédito de pessoas físicas e detalhes de cada análise.",
        "message": "Usuário pode visualizar (somente leitura) a lista dde análises de Crédito de Pessoas Físicas, detalhes de cada análise. Usuário não tem acesso para editar as análises."
      },
      {
        "name": "update_credit_analysis_natural_persons",
        "description": "Resolver as análises de crédito de pessoas físicas.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as análises derivadas para análise manual. Usuário não tem permissão para incluir nova análise de Crédito de Pessoa Física."
      },
      {
        "name": "create_credit_analysis_natural_persons",
        "description": "Incluir novas análises de crédito de pessoas físicas.",
      },
      // {
      //   "name": "read_pdf_credit_analysis",
      //   "description": "Exportar dados da análise em formato PDF.",
      // },
      // {
      //   "name": "read_credit_analysis_dashboard",
      //   "description": "Visualizar relatório das análises de Crédito de Pessoas Físicas.",
      // },
      {
        "name": "manage_credit_analysis_natural_persons_permissions",
        "description": "Gerenciar acesso de usuários em Análise de Crédito de Pessoa Física",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em ANÁLISE DE CRÉDITO DE PESSOA FÍSICA, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Análise de Crédito de Pessoa Jurídica",
    "manager_role": "manage_credit_analysis_legal_persons_permissions",
    "description": "Gerenciar acesso de usuários em Análise de Crédito de Pessoa Jurídica",
    "roles": [
      {
        "name": "read_credit_analysis_legal_persons",
        "description": "Recuperar lista de análises de crédito de pessoas jurídicas e detalhes de cada análise.",
        "message": "Usuário pode visualizar (somente leitura) a lista dde análises de crédito de pessoas jurídicas, detalhes de cada análise. Usuário não tem acesso para editar as análises."
      },
      {
        "name": "update_credit_analysis_legal_persons",
        "description": "Resolver as análises de crédito de pessoas jurídicas.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as análises derivadas para análise manual. Usuário não tem permissão para incluir nova análise de Crédito de Pessoa Física."
      },
      {
        "name": "create_credit_analysis_legal_persons",
        "description": "Incluir novas análises de crédito de pessoas jurídicas.",
      },
      // {
      //   "name": "read_pdf_credit_analysis",
      //   "description": "Exportar dados da análise em formato pdf.",
      // },
      // {
      //   "name": "read_credit_analysis_dashboard",
      //   "description": "Visualizar relatório das análises de crédito de pessoas jurídicas.",
      // },
      {
        "name": "manage_credit_analysis_legal_persons_permissions",
        "description": "Gerenciar acesso de usuários em análises de crédito de pessoas jurídicas",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Análise de Crédito de Pessoa Jurídica, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Pedidos",
    "manager_role": "manage_card_order_orders_permissions",
    "description": "Gerenciar acesso de usuários em pedidos",
    "roles": [
      {
        "name": "read_card_order_orders",
        "description": "Recuperar lista de transações de pedidos e detalhes de cada transação.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as transações e detalhes de cada transação, assim como filtrar as transações exibidas. Usuário não tem acesso para editar as transações ou exportar os dados."
      },
      {
        "name": "update_card_order_orders",
        "description": "Realizar análise das transações de pedidos.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as transações derivadas para análise manual."
      },
      {
        "name": "manage_card_order_orders_permissions",
        "description": "Gerenciar acesso de usuários em Pedidos.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Pedidos, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Pedidos - Alertas",
    "manager_role": "manage_card_order_alerts_permissions",
    "description": "Gerenciar acesso de usuários em alertas de pedidos",
    "roles": [
      {
        "name": "read_card_order_alerts",
        "description": "Recuperar lista de alertas das transações de pedidos apontadas como potencial fraude e detalhes de cada alerta.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos os alertas de fraude (pendentes ou tratados) e detalhes de cada alerta, assim como filtrar as transações exibidas. Usuário não tem acesso para editar os alertas ou exportar os dados."
      },
      {
        "name": "update_card_order_alerts",
        "description": "Realizar análise dos alertas das transações de pedidos apontadas como potencial fraude.",
        "message": "Usuário tem permissão para finalizar os alertas (sinalizar como válido ou inválido) das transações pendentes apontadas como potencial fraude. Usuário não tem permissão para bloquear a entidade de cartão." //VALIDAR EMÍLIO SOBRE BLOQUEIO NESSA PÁGINA DE ALERTAS => role separada?
      },
      {
        "name": "manage_card_order_alerts_permissions",
        "description": "Gerenciar acesso de usuários em Alertas de pedidos",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Alertas de pedidos, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Pedidos - Bloqueios",
    "manager_role": "manage_card_order_locks_permissions",
    "description": "Gerenciar acesso de usuários em bloqueios de entidades de cartão",
    "roles": [
      {
        "name": "read_card_order_locks",
        "description": "Recuperar lista de bloqueios das entidades de cartão.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todas as entidades de cartão bloqueadas/ desbloqueadas e histórico de cada bloqueio. Usuário não tem acesso para editar os bloqueios ou exportar os dados."
      },
      {
        "name": "update_card_order_locks",
        "description": "Editar bloqueios das entidades de cartão.",
        "message": "Usuário tem permissão para revisar (bloquear ou desbloquear) as entidades de cartão na página de Bloqueios. Usuário não tem permissão para criar bloqueio para uma nova entidade de cartão."
      },
      {
        "name": "create_card_order_locks",
        "description": "Criar bloqueio para uma nova entidade de cartão.",
        "message": "Usuário tem permissão para bloquear novas entidades de cartão."
      },
      {
        "name": "manage_card_order_locks_permissions",
        "description": "Gerenciar acesso de usuários em Bloqueios de entidades de cartão.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Bloqueios de entidades de cartão, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Cadastros de Pessoa Fisica",
    "manager_role": "manage_onboarding_natural_persons_permissions",
    "description": "Gerenciar acesso de usuários em pessoas físicas",
    "roles": [
      {
        "name": "read_onboarding_natural_persons",
        "description": "Recuperar lista de análises de pessoas físicas e detalhes de cada análise.",
        "message": "Usuário pode visualizar (somente leitura) a lista de análises de pessoas físicas, detalhes de cada análise, assim como filtrar a lista exibida. Usuário não tem acesso para editar as análises ou exportar dados em formato pdf ou CSV."
      },
      // {
      //   "name": "read_onboarding_filtered_pending_status_natural_persons",
      //   "description": "Recuperar lista de análises de pessoas físicas apenas com status pendente e detalhes de cada análise.",
      //   "message": "Usuário pode visualizar (somente leitura) a lista de análises de pessoas físicas apenas com status pendente. Usuário não tem acesso às análises com análise concluída. Usuário não tem acesso para editar as análises ou exportar dados em formato pdf ou CSV."
      // },
      // {
      //   "name": "read_onboarding_history_natural_person",
      //   "description": "Recuperar histórico de análises da pessoa física em questão.",
      // },
      {
        "name": "update_onboarding_natural_persons",
        "description": "Resolver as análises de pessoas físicas.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as análises derivadas para análise manual. Usuário não tem permissão para incluir nova análise de pessoa física."
      },
      {
        "name": "read_pdf_onboarding",
        "description": "Exportar dados da análise em formato pdf.",
      },
      {
        "name": "create_onboarding_natural_persons",
        "description": "Incluir novas análises de pessoas físicas.",
      },
      // {
      //   "name": "read_onboarding_dashboard",
      //   "description": "Visualizar relatório das análises de pessoas físicas.",
      // },
      // {
      //   "name": "read_onboarding_filtered_company_dashboard",
      //   "description": "Visualizar relatório das análises de pessoas físicas por Cliente.",
      // },
      // {
      //   "name": "read_onboarding_natural_persons_reports",
      //   "description": "Exportar relatório das análises de pessoas físicas.",
      //   "message": "Usuário tem permissão para solicitar novos relatório das análises de pessoas físicas, conforme filtros disponíveis, assim como, acessar relatórios prontos para exportar."
      // },
      {
        "name": "manage_onboarding_natural_persons_permissions",
        "description": "Gerenciar acesso de usuários em pessoas físicas.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em pessoas físicas, assim como, cadastrar novos usuários."
      },
      {
        "name": "read_onboarding_filtered_pending_status_natural_persons",
        "description": "Recuperar somente a lista de análises de pessoas físicas com status pendente e detalhes de cada análise.",
        "message": "Usuário pode visualizar (somente leitura) somente a lista de análises de pessoas físicas com status pendente. Usuário não tem acesso às análises com análise concluída. Usuário não tem acesso para editar as análises ou exportar dados em formato pdf ou CSV."
      }
    ]
  },
  {
    "name": "Cadastros de Pessoa Jurídica",
    "manager_role": "manage_onboarding_legal_persons_permissions",
    "description": "Gerenciar acesso de usuários em pessoas jurídicas",
    "roles": [
      {
        "name": "read_onboarding_legal_persons",
        "description": "Recuperar lista de análises de pessoas jurídicas e detalhes de cada análise.",
        "message": "Usuário pode visualizar (somente leitura) a lista de análises de pessoas jurídicas, detalhes de cada análise, assim como filtrar a lista exibida. Usuário não tem acesso para editar as análises ou exportar dados em formato pdf ou CSV."
      },
      { 
        "name": "update_onboarding_legal_persons",
        "description": "Resolver as análises de pessoas jurídicas.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as análises derivadas para análise manual."
      },
      {
        "name": "read_pdf_onboarding",
        "description": "Exportar dados da análise em formato pdf.",
      },
      {
        "name": "create_onboarding_legal_persons",
        "description": "Incluir novas análises de pessoas jurídicas.",
      },
      // {
      //   "name": "read_onboarding_dashboard",
      //   "description": "Visualizar relatório das análises de pessoas jurídicas.",
      // },
      // {
      //   "name": "read_onboarding_filtered_company_dashboard",
      //   "description": "Visualizar relatório das análises de pessoas jurídicas por Cliente.",
      // },
      // {
      //   "name": "read_onboarding_legal_persons_reports",
      //   "description": "Exportar relatório das análises de pessoas jurídicas.",
      //   "message": "Usuário tem permissão para solicitar novos relatório das análises de pessoas jurídicas, conforme filtros disponíveis, assim como, acessar relatórios prontos para exportar."
      // },
      {
        "name": "manage_onboarding_legal_persons_permissions",
        "description": "Gerenciar acesso de usuários em Pessoas Jurídicas.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Pessoas Jurídicas, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Alertas - Transações Bancárias",
    "manager_role": "manage_banking_alerts_permissions",
    "description": "Gerenciar acesso de usuários em alertas de transações bancárias",
    "roles": [
      {
        "name": "read_banking_alerts", 
        "description": "Recuperar lista de alertas das transações bancárias apontadas como potencial fraude e detalhes de cada alerta.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos os alertas de fraude (pendentes ou tratados), assim como filtrar as transações exibidas e detalhes de cada alerta."
      },
      {
        "name": "update_banking_alerts", 
        "description": "Realizar análise dos alertas das transações bancárias apontadas como potencial fraude.",
        "message": "Usuário tem permissão para finalizar os alertas (sinalizar como válido ou inválido) das transações pendentes apontadas como potencial fraude. Usuário não tem permissão para bloquear o usuário."
      },
      // {
      //   "name": "read_alerts_dashboard",
      //   "description": "Visualizar relatório de análise dos alertas das transações bancárias.",
      // },
      // {
      //   "name": "read_banking_alerts_reports",
      //   "description": "Exportar relatório dos alertas de transações bancárias.",
      //   "message": "Usuário tem permissão para solicitar novos relatório dos alertas de transações bancárias, conforme filtros disponíveis, assim como, acessar relatórios prontos para exportar."
      // },
      {
        "name": "manage_banking_alerts_permissions",
        "description": "Gerenciar acesso de usuários em Alertas de Transações Bancárias.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Alertas de banking, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Boletos",
    "manager_role": "manage_bankslips_permissions",
    "description": "Gerenciar acesso de usuários em boletos",
    "roles": [
      {
        "name": "read_bankslips",
        "description": "Recuperar lista de transações de boletos e detalhes de cada transação.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as transações de boletos e detalhes de cada transação, incluindo histórico de fraude, assim como filtrar as transações de boletos exibidas. Usuário não tem acesso para editar as transações ou exportar os dados."
      },
      {
        "name": "update_bankslips",
        "description": "Realizar análise das transações de boletos, reportar as transações de fraude, atualizar histórico de fraude e exportar relatório de fraude.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as transações de boletos derivadas para análise manual, sinalizar as transações de boleto apontadas como fraude, atualizar histórico de fraude, incluindo upload e download de arquivos, e exportar relatório de fraude em formato CSV."
      },
      // {
      //   "name": "read_bankslip_dashboard",
      //   "description": "Visualizar relatório de análise das transações de boletos.",
      // },
      {
        "name": "manage_bankslips_permissions",
        "description": "Gerenciar acesso de usuários em Boletos.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Boletos, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Boletos - Bloqueios",
    "manager_role": "manage_bankslip_locks_permissions",
    "description": "Gerenciar acesso de usuários em bloqueios de boletos",
    "roles": [
      {
        "name": "read_bankslip_locks",
        "description": "Recuperar lista de usuários bloqueados.", 
        "message": "Usuário pode visualizar a lista de todos os usuários bloqueados/ desbloqueados e histórico de cada bloqueio. Usuário não tem permissão para revisar ou incluir novos bloqueios."
      },
      {
        "name": "update_banking_locks",
        "description": "Revisar usuários bloqueados.",
        "message": "Usuário tem permissão para revisar (desbloquear) os usuários bloqueados para as transações bancárias."
      },
      {
        "name": "create_bankslip_locks",
        "description": "Criar novos bloqueios de Boletos.",
        "message": "Usuário tem permissão para bloquear novos usuários para as transações de boleto."
      },
      {
        "name": "manage_bankslip_locks_permissions",
        "description": "Gerenciar acesso de usuários em Bloqueios de Boletos.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Bloqueios de Boletos, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Pagamento de Contas",
    "manager_role": "manage_bill_payments_permissions",
    "description": "Gerenciar acesso de usuários em pagamento de contas",
    "roles": [
      {
        "name": "read_bill_payments",
        "description": "Recuperar lista de transações de pagamento de contas e detalhes de cada transação.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as transações de pagamento de contas e detalhes de cada transação, incluindo histórico de fraude e filtrar as transações de pagamento de contas exibidas. Usuário não tem acesso para editar as transações ou exportar os dados."
      },
      {
        "name": "update_bill_payments",
        "description": "Realizar análise das transações de pagamento de contas, reportar as transações de fraude, atualizar histórico de fraude e exportar relatório de fraude.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as transações de pagamento de contas derivadas para análise manual, sinalizar as transações de pagamento de contas apontadas como fraude, atualizar histórico de fraude, incluindo upload e download de arquivos, e exportar relatório de fraude em formato CSV."
      },
      // {
      //   "name": "read_bill_payment_dashboard",
      //   "description": "Visualizar relatório de análise das transações de pagamento de contas.",
      // },
      {
        "name": "manage_bill_payments_permissions",
        "description": "Gerenciar acesso de usuários em Pagamento de Contas.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Pagamento de Contas, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Pagamento de Contas - Bloqueios",
    "manager_role": "manage_bill_payment_locks_permissions",
    "description": "Gerenciar acesso de usuários em bloqueios de pagamento de contas",
    "roles": [
      {
        "name": "read_bill_payment_locks",
        "description": "Recuperar lista de usuários bloqueados.", 
        "message": "Usuário pode visualizar a lista de todos os usuários bloqueados/ desbloqueados e histórico de cada bloqueio. Usuário não tem permissão para revisar ou incluir novos bloqueios."
      },
      {
        "name": "update_banking_locks",
        "description": "Revisar usuários bloqueados.",
        "message": "Usuário tem permissão para revisar (desbloquear) os usuários bloqueados para as transações bancárias."
      },
      {
        "name": "create_bill_payment_locks",
        "description": "Criar novos bloqueios de Pagamento de Contas.",
        "message": "Usuário tem permissão para bloquear novos usuários para pagamento de contas."
      },
      {
        "name": "manage_bill_payment_locks_permissions",
        "description": "Gerenciar acesso de usuários em Bloqueios de Pagamento de Contas.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Bloqueios de Pagamento de Contas, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Transferências",
    "manager_role": "manage_wire_transfers_permissions",
    "description": "Gerenciar acesso de usuários em transferências",
    "roles": [
      {
        "name": "read_wire_transfers",
        "description": "Recuperar lista de transações de transferências, detalhes de cada transação.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as transações de transferências e detalhes de cada transação, incluindo histórico de fraude e filtrar as transações de transferências exibidas. Usuário não tem acesso para editar as transações."
      },
      {
        "name": "update_wire_transfers",
        "description": "Realizar análise das transações de transferências, reportar as transações de fraude, atualizar histórico de fraude e exportar relatório de fraude.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as transações de transferências derivadas para análise manual, sinalizar as transações de transferências apontadas como fraude, atualizar histórico de fraude, incluindo upload e download de arquivos, e exportar relatório de fraude em formato CSV."
      },
      // {
      //   "name": "read_wire_transfer_dashboard",
      //   "description": "Visualizar relatório de análise das transações de transferências.",
      // },
      {
        "name": "manage_wire_transfers_permissions",
        "description": "Gerenciar acesso de usuários em Transferências.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Transferências, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Transferências - Bloqueios",
    "manager_role": "manage_wire_transfer_locks_permissions",
    "description": "Gerenciar acesso de usuários em bloqueios de tranferências",
    "roles": [
      {
        "name": "read_wire_transfer_locks",
        "description": "Recuperar lista de usuários bloqueados.", 
        "message": "Usuário pode visualizar a lista de todos os usuários bloqueados/ desbloqueados e histórico de cada bloqueio. Usuário não tem permissão para revisar ou incluir novos bloqueios."
      },
      {
        "name": "update_banking_locks",
        "description": "Revisar usuários bloqueados.",
        "message": "Usuário tem permissão para revisar (desbloquear) os usuários bloqueados para as transações bancárias."
      },
      {
        "name": "create_wire_transfer_locks",
        "description": "Criar novos bloqueios de Tranferências.",
        "message": "Usuário tem permissão para bloquear novos usuários para Tranferências."
      },
      {
        "name": "manage_wire_transfer_locks_permissions",
        "description": "Gerenciar acesso de usuários em Bloqueios de Tranferências.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Bloqueios de Tranferências, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "PIX - Transações & PIX - Relatos",
    "manager_role": "manage_pix_transactions_permissions",
    "description": "Gerenciar acesso de usuários em PIX - Transações e PIX - Relatos",
    "roles": [
      {
        "name": "read_pix_transactions",
        "description": "Recuperar lista de todas as transações de PIX (PIX - Transações) e todas as transações de PIX relatadas como fraude ou compliance (PIX - Relatos), detalhes de cada transação.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as transações de PIX e todas as transações de PIX relatadas, assim como detalhes de cada transação. Usuário não tem acesso para editar as transações."
      },
      {
        "name": "update_pix_transactions",
        "description": "Realizar análise das transações de PIX, reportar as transações de fraude e exportar relatório de fraude.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as transações de PIX derivadas para análise manual, sinalizar as transações de PIX apontadas como fraude, atualizar histórico de fraude, incluindo upload e download de arquivos, e exportar relatório de fraude em formato CSV."
      },
      // {
      //   "name": "create_infraction_report_pix_transactions", // NÃO IMPLEMENTADO NO CÓDIGO DASHBOARD, NEM DASH_API, NEM KEYCLOACK
      //   "description": "Relatar uma transação de PIX.",
      //   "message": "Usuário tem permissão para criar novos relatos de fraude ou compliance para as transações de PIX."
      // },
      // {
      //   "name": "read_pix_transaction_dashboard",
      //   "description": "Visualizar relatório de análise das transações de PIX.",
      // },
      {
        "name": "manage_pix_transactions_permissions",
        "description": "Gerenciar acesso de usuários em PIX - Transações e PIX - Relatos.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em PIX - Transações e e PIX - Relatos, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "PIX - Pré Transações",
    "manager_role": "manage_pre_pix_transactions_permissions",
    "description": "Gerenciar acesso de usuários para pré transações de PIX",
    "roles": [
      {
        "name": "read_pre_pix_transactions",
        "description": "Visualizar Pré Transações PIX",
        "message": "Usuário pode visualizar a lista de pré transações de PIX"
      },
      {
        "name": "manage_pre_pix_transactions_permissions",
        "description": "Gerenciar acesso de usuário para telas de Pré Transações PIX",
        "message": "Usuário pode gerenciar e persnonalizar as permissões de acesso dos usuário em Pré Transações PIX"
      }
    ]
  },
  {
    "name": "PIX - Transações - Bloqueios",
    "manager_role": "manage_pix_transaction_locks_permissions",
    "description": "Gerenciar acesso de usuários em bloqueios de PIX - Transações",
    "roles": [
      {
        "name": "read_pix_transaction_locks",
        "description": "Recuperar lista de usuários bloqueados.",
        "message": "Usuário pode visualizar a lista de todos os usuários bloqueados/ desbloqueados e histórico de cada bloqueio. Usuário não tem acesso para criar novos bloqueios."
      },
      {
        "name": "update_banking_locks", 
        "description": "Revisar usuários bloqueados.",
        "message": "Usuário tem permissão para revisar (desbloquear) os usuários bloqueados para as transações bancárias."
      },
      {
        "name": "create_pix_transaction_locks",
        "description": "Criar novos bloqueios para as transações de pix.",
        "message": "Usuário tem permissão para bloquear novos usuários para as transações de pix."
      },
      {
        "name": "manage_pix_transaction_locks_permissions",
        "description": "Gerenciar acesso de usuários em Bloqueios de PIX - Transações.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Bloqueios, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "PIX - DICT",
    "manager_role": "manage_pix_dict_operations_permissions",
    "description": "Gerenciar acesso de usuários em PIX - DICT",
    "roles": [
      {
        "name": "read_pix_dict_operations",
        "description": "Recuperar lista de operações de PIX - DICT, detalhes de cada operação.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as operações de PIX - DICT, detalhes de cada operação e filtrar as operações exibidas. Usuário não tem acesso para editar as análises das operações."
      },
      {
        "name": "update_pix_dict_operations",
        "description": "Realizar análise das operações de PIX - DICT.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as operações de PIX - DICT derivadas para análise manual."
      },
      // {
      //   "name": "read_pix_dict_operation_dashboard",
      //   "description": "Visualizar relatório de análise das operações de PIX - DICT.",
      // },
      {
        "name": "manage_pix_dict_operations_permissions",
        "description": "Gerenciar acesso de usuários em PIX - DICT.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em PIX - DICT, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "PIX - DICT - Bloqueios",
    "manager_role": "manage_pix_dict_operation_locks_permissions",
    "description": "Gerenciar acesso de usuários em bloqueios de PIX - DICT",
    "roles": [
      {
        "name": "read_pix_dict_operation_locks",
        "description": "Recuperar lista de usuários bloqueados.",
        "message": "Usuário pode visualizar a lista de todos os usuários bloqueados/ desbloqueados e histórico de cada bloqueio. Usuário não tem acesso para criar novos bloqueios."
      },
      {
        "name": "update_banking_locks", 
        "description": "Revisar usuários bloqueados.",
        "message": "Usuário tem permissão para revisar (desbloquear) os usuários bloqueados para as operações de PIX - DICT."
      },
      {
        "name": "create_pix_dict_operation_locks", 
        "description": "Criar novos bloqueios para as operações de PIX - DICT.",
        "message": "Usuário tem permissão para bloquear novos usuários para as operações de pix dict."
      },
      {
        "name": "manage_pix_dict_operation_locks_permissions",
        "description": "Gerenciar acesso de usuários em Bloqueios de PIX - DICT.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Bloqueios, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Saques",
    "manager_role": "manage_withdrawals_permissions",
    "description": "Gerenciar acesso de usuários em saques",
    "roles": [
      {
        "name": "read_withdrawals",
        "description": "Recuperar lista de transações de saque e detalhes de cada transação.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as transações de saque e detalhes de cada transação, incluindo histórico de fraude e filtrar as transações de saque exibidas. Usuário não tem acesso para editar as transações."
      },
      {
        "name": "manage_withdrawals_permissions",
        "description": "Gerenciar acesso de usuários em Saques.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Saques, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Saques - Bloqueios",
    "manager_role": "manage_withdrawal_locks_permissions",
    "description": "Gerenciar acesso de usuários em bloqueios de saques",
    "roles": [
      {
        "name": "read_withdrawal_locks",
        "description": "Recuperar lista de usuários bloqueados.",
        "message": "Usuário pode visualizar a lista de todos os usuários bloqueados/ desbloqueados e histórico de cada bloqueio. Usuário não tem acesso para criar novos bloqueios."
      },
      {
        "name": "update_banking_locks", 
        "description": "Revisar usuários bloqueados.",
        "message": "Usuário tem permissão para revisar (desbloquear) os usuários bloqueados para as operações de Saque."
      },
      {
        "name": "create_withdrawal_locks",
        "description": "Criar novos bloqueios para as operações de Saque",
        "message": "Usuário tem permissão para bloquear novos usuários para as operações de Saque"
      },
      {
        "name": "manage_withdrawal_locks_permissions",
        "description": "Gerenciar acesso de usuários em Bloqueios de Saques.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Bloqueios, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Relatórios",
    "manager_role": "manage_reports",
    "description": "Gerenciar acesso de usuários em relatórios",
    "roles": [
      {
        "name": "read_reports",
        "description": "Recuperar lista de relatórios emitidos.",
        "message": "Usuário pode visualizar a lista de todos os relatórios já emitidos."
      },
      {
        "name": "create_reports", 
        "description": "Emitir novos relatórios",
        "message": "Usuário tem permissão para emitir novos relatórios."
      },
      {
        "name": "manage_reports",
        "description": "Gerenciar acesso de usuários em Relatórios",
        "message": "Usuário tem permissão para gerenciar permissões em Relatórios."
      }
    ]
  },
  {
    "name": "Transações",
    "manager_role": "card_issuance_transaction_manager",
    "description": "Gerenciar acesso de usuários em transações",
    "roles": [
      {
        "name": "card_issuance_transaction_viewer",
        "description": "Recuperar lista de transações, detalhes de cada transação e exportar dados em formato CSV.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as transações e detalhes de cada transação."
      },
      {
        "name": "card_issuance_transaction_read_onboarding_natural_person_by_key",
        "description": "Recuperar os detalhes da análise de Onboarding da pessoa física vinculada à transação em questão."
      },
      {
        "name": "card_issuance_transaction_read_onboarding_legal_person_by_key",
        "description": "Recuperar os detalhes da análise de Onboarding da pessoa jurídica vinculada à transação em questão."
      },
      {
        "name": "card_issuance_transaction_manager",
        "description": "Gerenciar acesso de usuários em transações.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Transações, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Transações - Alertas",
    "manager_role": "card_issuance_alert_manager",
    "description": "Gerenciar acesso de usuários em alertas de transações de emissão de cartão",
    "roles": [
      {
        "name": "card_issuance_alert_viewer",
        "description": "Recuperar lista de alertas das transações de emissão de cartão apontadas como potencial fraude e detalhes de cada alerta.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos os alertas de fraude (pendentes ou tratados) e detalhes de cada alerta, assim como filtrar as transações exibidas. Usuário não tem acesso para editar os alertas."
      },
      {
        "name": "card_issuance_alert_analyzer",
        "description": "Realizar análise dos alertas das transações de emissão de cartão apontadas como potencial fraude, assim como gerenciar bloqueio para a entidade em questão.",
        "message": "Usuário tem permissão para finalizar os alertas das transações pendentes, apontadas como potencial fraude, assim como bloquear/desbloquear a entidade em questão."
      },
      {
        "name": "card_issuance_alert_read_onboarding_natural_person_by_key",
        "description": "Recuperar os detalhes da análise de Onboarding da pessoa física vinculada ao alerta em questão."
      },
      {
        "name": "card_issuance_alert_read_onboarding_legal_person_by_key",
        "description": "Recuperar os detalhes da análise de Onboarding da pessoa jurídica vinculada ao alerta em questão."
      },
      {
        "name": "card_issuance_alert_manager",
        "description": "Gerenciar acesso de usuários em alertas de transações de emissão de cartão.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Alertas de transações de emissão de cartão, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Transações - Bloqueios",
    "manager_role": "card_issuance_lock_manager",
    "description": "Gerenciar acesso de usuários em bloqueios das entidades de emissão de cartão.",
    "roles": [
      {
        "name": "card_issuance_lock_viewer",
        "description": "Recuperar lista de bloqueios das entidades de emissão de cartão, assim como desbloquear as mesmas.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todas as entidades de emissão de cartão bloqueadas/ desbloqueadas e histórico de cada bloqueio, assim como desbloquear as entidades. Usuário não tem acesso para criar novos bloqueios."
      },
      {
        "name": "card_issuance_lock_analyzer",
        "description": "Criar novos bloqueios das entidades de cartão.",
        "message": "Usuário tem permissão para criar novos bloqueios de entidades de emissão de cartão.",
      },
      {
        "name": "card_issuance_lock_manager",
        "description": "Gerenciar acesso de usuários em Bloqueios das entidades de emissão de cartão.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Bloqueios de entidades de emissão de cartão, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Aluguéis",
    "manager_role": "rental_agreements_manager",
    "description": "Gerenciar acesso de usuários em aluguéis",
    "roles": [
      {
        "name": "rental_agreements_viewer",
        "description": "Recuperar lista de aluguéis, detalhes de cada aluguel e exportar dados em formato CSV.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as transações de aluguel e detalhes de cada aluguel."
      },
      {
        "name": "rental_agreements_analyzer", 
        "description": "Realizar análise das transações de aluguel e enviar e receber mensagens.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as transações de aluguel derivadas para análise manual."
      },
      // {
      //   "name": "rental_agreements_dashboard_viewer",
      //   "description": "Visualizar relatório de análise das transações de aluguel.",
      // },
      {
        "name": "read_car_rental_agreements_filtered_sac",
        "description": "Recuperar lista de aluguéis e detalhes de cada aluguel, para um determinado CPF pesquisado, exibindo apenas as informações permitidas para o SAC.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as transações de aluguel e detalhes de cada aluguel, para um determinado CPF pesquisado. As informações exibidas são filtradas para o usuário tipo SAC."
      },
      {
        "name": "rental_agreements_manager",
        "description": "Gerenciar acesso de usuários em aluguéis. Transitar entre as telas de aluguéis e aluguéis - SAC, desde que possua as respectivas permissões.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em aluguéis, assim como, cadastrar novos usuários. Usuário consegue verificar tanto a listagem de aluguéis, quanto a listagem de aluguéis - SAC, desde que possua as respectivas permissões de leitura. "
      }
    ]
  },
  {
    "name": "Reservas",
    "manager_role": "manage_car_rental_reservations_permissions",
    "description": "Gerenciar acesso de usuários em reservas",
    "roles": [
      {
        "name": "read_car_rental_reservations",
        "description": "Recuperar lista de reservas e detalhes de cada reserva.",
        "message": "Usuário pode visualizar (somente leitura) a lista de todos as transações de reserva e detalhes de cada reserva."
      },
      {
        "name": "rental_agreements_viewer",
        "description": "Recuperar histórico de aluguéis.",
        "message": "Usuário pode visualizar (somente leitura) o histórico de todos os aluguéis realizados para o cliente da reserva em questão." 
      },
      {
        "name": "update_car_rental_reservations", 
        "description": "Realizar análise das reservas.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as reservadas derivadas para análise manual."
      },
      {
        "name": "manage_car_rental_reservations_permissions",
        "description": "Gerenciar acesso de usuários em Reservas.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em Reservas, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Listas",
    "manager_role": "lists_manager",
    "description": "Gerenciar acesso de usuários em Listas",
    "roles": [
      // {
      //   "name": "create_lists",
      //   "description": "Criar novas listas."
      // },
      // {
      //   "name": "delete_lists",
      //   "description": "Deletar listas existentes."
      // },
      {
        "name": "lists_manager",
        "description": "Gerenciar acesso de usuários em listas vigentes.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: visualização/ criação) dos usuários em Listas, assim como, cadastrar novos usuários."
      },
      {
        "name": "lists_analyzer",
        "description": "Criar, excluir e compartilhar listas.",
        "message": "Usuário pode criar novas listas ou excluir listas existentes."
      },
      {
        "name": "lists_viewer",
        "description": "Acessar produto de listas com as configurações de permissões setadas.",
        "message": "Usuário pode acessar o produto de listas. O usuário só poderá acessar e modificar as listas conforme as permissões selecionadas."
      }
    ]
  },
  {
    "name": "Regras",
    "manager_role": "rule_manager",
    "description": "Gerenciar acesso de usuários em regras",
    "roles": [
      {
        "name": "rule_viewer", 
        "description": "Recuperar lista de todas as regras, detalhes de cada regra, e respectivas versões. Usuário também tem permissão para exportar dados em formato CSV. Obs: Os usuários também terão acesso de leitura à todas as listas.",
      },
      {
        "name": "rule_editor", // NO CÓDIGO NÃO EXISTE SEPARAÇÃO DE RULE_VIEWER E RULE_EDITOR, MAS DASH_API NÃO PERMITE SALVAR SE NÃO TIVER EDITOR
        "description": "Criar novas regras, assim como ativar, editar, renomear, duplicar ou excluir regras existentes. Além disso, pode aprovar ou reprovar rascunhos de regras existentes.",
      },
      {
        "name": "rule_manager",
        "description": "Gerenciar acesso de usuários em regras.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: visualização/ criação) dos usuários em regras, assim como, cadastrar novos usuários."
      },
      {
        "name": "rule_draft_editor",
        "description": "Criar novos rascunhos de regra, editar rascunhos de regra e descartar rascunhos de regra. Obs: As regras rascunho só serão ativadas quando aprovadas.",
      }
    ]
  },
  {
    "name": "Operações",
    "manager_role": "operations_manager",
    "description": "Gerenciar acesso de usuários em operações de câmbio",
    "roles": [
      {
        "name": "operations_viewer",
        "description": "Recuperar lista de operações de câmbio e detalhes de cada operação.",
        "message": "Usuário pode visualizar (somente leitura) a lista de operações de câmbio e detalhes de cada operação, assim como filtrar a lista exibida. Usuário não tem acesso para editar as análises ou exportar os dados."
      },
      {
        "name": "operations_analyzer",
        "description": "Realizar análise das operações de câmbio e exportar dados em formato CSV.",
        "message": "Usuário tem permissão para resolver (aprovar ou reprovar) as operações de câmbio derivadas para análise manual."
      },
      {
        "name": "operations_manager",
        "description": "Gerenciar acesso de usuários em operações de câmbio.",
        "message": "Usuário pode gerenciar e personalizar as permissões de acesso (ativo/ inativo) e o tipo de perfil (ex: edição/ visualização) dos usuários em operações de câmbio, assim como, cadastrar novos usuários."
      }
    ]
  },
  {
    "name": "Depósitos",
    "manager_role": "manage_deposits_permissions",
    "description": "Gerenciar acesso de usuários em operações de depósito",
    "roles": [
      {
        "name": "read_deposits",
        "description": "Recuperar lista de operações de depósitos e detalhes de cada operação.",
        "message": "Usuário pode visualizar a lista de operações de depósitos e detalhes de cada operação, assim como filtrar a lista exibida. Usuário não tem acesso para editar as análises ou exportar os dados."
      }
    ]
  }
]

