import React from 'react'
import Tooltip from "@material-ui/core/Tooltip";
import {colorGreen, colorRed, colorGrey, colorYellow, colorOrange, getAgencyInfo} from "./utils"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from '@material-ui/icons/Clear';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import complianceLogo from '../../../assets/cardEntryIcons/compliance.svg';
import fraudLogo from '../../../assets/cardEntryIcons/fraud.svg';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CachedIcon from '@material-ui/icons/Cached';

export const colorBlue = "#0b1f82"

export const getTransactionType = (status) => {
    switch(status){
        case "sent":
            return "Pagamento"
        case "received":
            return "Recebimento"
        default:
            return status
    }
}

export const getSalesChannel = (status) => {
    switch(status){
        case "inbound_sales":
            return "Vendas inbound"
        case "app":
            return "Aplicativo"
        case "website":
            return "Website"
        case "call_center":
            return "Call Center"
        default:
            return status
    }
}

export const getSourceChannel = (status) => {
    switch(status){
        case "app":
            return "Aplicativo"
        case "internet_banking":
            return "Internet Banking"
        default:
            return status
    }
}

export const getSourcePlatform = (status) => {
    switch(status){
        case "android":
            return "Android"
        case "web":
            return "Web"
        case "ios":
            return "IOS"
        default:
            return status
    }
}

export const getDevicePlatformName = (channel, platform) => {
    let translated_channel = getSourceChannel(channel)
    let translated_platform = getSourcePlatform(platform)
    if (channel && platform) return (translated_platform + " via " + translated_channel)
    else if(channel) return translated_channel
    else if(platform) return translated_platform
    else return null
}

export const getCaptureType = (status) => {
    switch(status){
        case "static_qr_code":
            return "QR Code Estático"
        case "dynamic_qr_code":
            return "QR Code Dinâmico"
        case "offline_qr_code":
            return "QR Code Offline"
        case "typed":
            return "Digitado"
        default:
            return status
    }
}

export const getTransactionStatusText = (status) => {
    switch(status){
        case "created":
            return "Transação Requisitada"
        case "received":
            return "Transação Recebida"
        case "sent":
            return "Enviada ao Banco Central"
        default:
            return status
    }
}

export const getTransactionDirectionText = (status) => {
    switch(status){
        case "received":
            return "Recebimento"
        case "sent":
            return "Pagamento"
        default:
            return status
    }
}

export const getAccountType = (status) => {
    switch(status){
        case "CACC":
            return "Conta Corrente"
        case "SVGS":
            return "Conta Poupança"
        case "SLRY":
            return "Conta Salário"
        default:
            return status
    }
}

export const getAnalysisStatus = (status) => {
    switch(status){
        case "pending":
        case "in_manual_analysis":
          	return "Pendente";
        case "manually_approved":
            return ("Aprovado Manualmente");
        case "automatically_approved":
            return ("Aprovado Automaticamente");
        case "manually_reproved":
            return ("Reprovado Manualmente");
        case "automatically_reproved":
            return ("Reprovado Automaticamente");
        case "approved_by_time":
            return "Aprovado por tempo";
        case "not_analised":
            return "Não analisado";
        case "manually_challenged":
            return "Contestado Manualmente"
        case "automatically_challenged":
            return "Contestado Automaticamente"
        default:
            return status;
    }
}
  
export const getAnalysisStatusSiglum = (status) => {
    switch(status){
        case "pending":
        case "in_manual_analysis":
          	return "P";
        case "manually_approved":
        case "manually_reproved":
        case "manually_challenged":
            return "M";
        case "automatically_approved":
        case "automatically_reproved":
        case "automatically_challenged":
            return "A";
        case "approved_by_time":
            return "T";
        case "not_analised":
            return "NA";
        default:
            return "?";
    }
}

export const setAllowChanges = (status, roles) => {
    if (!roles.includes("update_pix_transactions")){
        return false
    }
    switch(status){
        case "pending":
        case "in_manual_analysis":
            return true
        default:
            return false
    }
}

export const getAnalysisStatusColor = (status) => {
    switch(status){
        case "automatically_approved":
        case "manually_approved":
        case "approved_by_time":
            return colorGreen
        case "automatically_reproved":
        case "manually_reproved":
            return colorRed
        case "manually_challenged":
        case "automatically_challenged":
            return colorYellow
        default:
            return colorGrey
    }
}

export const getTransactionStatusColor = (status) => {
    switch(status){
        case "created":
            return colorYellow
        case "received":
            return colorOrange
        case "sent":
            return colorGreen
        default:
            return colorGrey
    }
}

export const getTransactionDirectionColor = (status) => {
    switch(status){
        case "received":
            return colorGreen
        case "sent":
            return colorRed
        default:
            return colorGrey
    }
}

export const formatDecision = (decision) => {
    let color
    let text
    let icon
    if (decision==="automatically_reproved" || decision==="manually_reproved") {
        color = colorRed
        text = "REPROVADO"
        icon = <ClearIcon style={{width:"50px", height:"50px", margin:"auto 0px"}}/>
    }
    else if (decision==="pending"){
        color = colorBlue
        text = "EM ANÁLISE"
        icon = <ErrorOutlineIcon style={{width:"50px", height:"50px", margin:"auto 0px"}}/>
    }
    else if (decision==="in_manual_analysis") {
        color = colorBlue
        text = "EM ANÁLISE MANUAL"
        icon = <ErrorOutlineIcon style={{width:"50px", height:"50px", margin:"auto 0px"}}/>
    } 
    else if (decision==="automatically_approved" || decision==="manually_approved" || decision==="approved_by_time"){
        color = colorGreen
        text = "APROVADO"
        icon = <CheckIcon style={{width:"50px", height:"50px", margin:"auto 0px"}}/>
    }
    else{
        color = colorGrey
        text = "INDISPONÍVEL"
        icon = <HelpOutlineIcon style={{width:"50px", height:"50px", margin:"auto 0px"}}/>
    }
    return ({color:color, text:text, icon:icon})
}

export const formatDecisionSmaller = (decision) => {
    let color
    let text
    let icon
    if (decision==="automatically_reproved" || decision==="manually_reproved") {
        color = colorRed
        text = "REPROVADO"
        icon = <ClearIcon style={{width:"30px", height:"30px", margin:"auto 0px"}}/>
    }
    else if (decision==="pending"){
        color = colorBlue
        text = "EM ANÁLISE"
        icon = <ErrorOutlineIcon style={{width:"30px", height:"30px", margin:"auto 0px"}}/>
    }
    else if (decision==="in_manual_analysis") {
        color = colorBlue
        text = "EM ANÁLISE MANUAL"
        icon = <ErrorOutlineIcon style={{width:"30px", height:"30px", margin:"auto 0px"}}/>
    } 
    else if (decision==="automatically_approved" || decision==="manually_approved" || decision==="approved_by_time"){
        color = colorGreen
        text = "APROVADO"
        icon = <CheckIcon style={{width:"30px", height:"30px", margin:"auto 0px"}}/>
    } else if (decision==="automatically_challenged") {
        color = colorYellow
        text = "CONTESTADO"
        icon = <CachedIcon style={{width:"30px", height:"30px", margin:"auto 0px"}}/>
    }
    else {
        color = colorGrey
        text = "INDISPONÍVEL"
        icon = <HelpOutlineIcon style={{width:"30px", height:"30px", margin:"auto 0px"}}/>
    }
    return ({color:color, text:text, icon:icon})
}

export const formatScore = (score) => {
    let color
    let text
    let icon
    if (score == null || score === undefined) {
        color = colorGrey
        text = "Score Indisponível"
        icon = null
    }
    else if (score > 800) {
        color = colorRed
        text = "ARRISCADO"
        icon = <ErrorOutlineIcon style={{width:"30px", height:"30px", color:color, margin:"auto 0px"}}/>
    } 
    else if (score > 500) {
        color = colorYellow
        text = "MODERADO"
        icon = <ErrorOutlineIcon style={{width:"30px", height:"30px", color:color, margin:"auto 0px"}}/>
    } 
    else {
        color = colorGreen
        text = "SEGURO"
        icon = <CheckIcon style={{width:"30px", height:"30px", color:color, margin:"auto 0px"}}/>
    }

    return ({color:color, text:text, icon:icon})
}

export const getTransactionStatusIcon = (status, icon_size_class, showTooltip=false) => {
    let circle_size
    let icon_size
    let icon_component
    let icon_backgroud_color = getTransactionStatusColor(status)
    if (icon_size_class === "big"){
        circle_size = ""
        icon_size = "45px"
    }
    else if (icon_size_class === "small"){
        circle_size = "small"
        icon_size = "18px"
    }
    else throw new Error("Please a new size is needed for setup")
    switch(status){
        case "received":
            icon_component = <GetAppIcon  style={{width:icon_size, height:icon_size}}/>
            break
        case "sent":
            icon_component = <AccountBalanceIcon  style={{width:icon_size, height:icon_size}}/>
            break       
        case "created":
            icon_component = <PublishIcon  style={{width:icon_size, height:icon_size}}/>
            break   
        default:
            icon_component = <HelpOutlineIcon  style={{width:icon_size, height:icon_size}}/>
            break   
    }
    if (showTooltip){
        return (
            <Tooltip title={<p className="tooltipText">{getTransactionStatusText(status)}</p>}>
                <div className={["circle", circle_size].join(" ")} style={{backgroundColor: icon_backgroud_color}}>
                    {icon_component}
                </div>
            </Tooltip>
        )
    }
    else{
        return (
            <div className={["circle", circle_size].join(" ")} style={{backgroundColor: icon_backgroud_color}}>
                {icon_component}
            </div>
        )
    }
}

export const getTransactionDirectionIcon = (status, icon_size_class, showTooltip=false) => {
    let circle_size
    let icon_size
    let icon_component
    let icon_backgroud_color = getTransactionDirectionColor(status)
    if (icon_size_class === "big"){
        circle_size = ""
        icon_size = "45px"
    }
    else if (icon_size_class === "small"){
        circle_size = "small"
        icon_size = "18px"
    }
    else throw new Error("Please a new size is needed for setup")
    switch(status){
        case "received":
            icon_component = <ArrowBackIcon style={{width:icon_size, height:icon_size}}/>
            break
        case "sent":
            icon_component = <ArrowForwardIcon style={{width:icon_size, height:icon_size}}/>
            break       
        default:
            icon_component = <HelpOutlineIcon  style={{width:icon_size, height:icon_size}}/>
            break   
    }
    if (showTooltip){
        return (
            <Tooltip title={<p className="tooltipText">{getTransactionDirectionText(status)}</p>}>
                <div className={["circle", circle_size].join(" ")} style={{backgroundColor: icon_backgroud_color}}>
                    {icon_component}
                </div>
            </Tooltip>
        )
    }
    else{
        return (
            <div className={["circle", circle_size].join(" ")} style={{backgroundColor: icon_backgroud_color}}>
                {icon_component}
            </div>
        )
    }
}

export const getReportIndicatorIcon = (infraction_reports) => {
    let text
    let icon  
    if ((infraction_reports || []).length === 0){
        return (
            <div style={{display:"flex", justifyContent:"center"}}>
                <Tooltip title={<p className="tooltipText">Nenhum Relato</p>}>
                    <p>-</p>
                </Tooltip>
            </div>
        )
    } 

    let fraud_reports = infraction_reports.filter(report => report.infraction_report_type === "fraud")
    if (fraud_reports.length > 0){
        text = "Há relatos de Fraude nessa transação"
        icon = fraudLogo
    }
    else{
        text = "Há relatos de Compliance nessa transação"
        icon = complianceLogo
    }
    return (
        <div style={{display:"flex", justifyContent:"center"}}>
            <Tooltip title={<p className="tooltipText">{text}</p>}>
                <img src={icon} alt="irregularity Logo" />
            </Tooltip>
        </div>
    )
}

export const getInstitutionNameByParticipant = (transaction) => {
    let transaction_direction_object

    if (transaction.transaction_direction === "received") {
        transaction_direction_object = transaction.source_account
    }
    else if (transaction.transaction_direction === "sent") {
        transaction_direction_object = transaction.destination_account
    }
    else throw new Error("Transation direction not defined")

    let [institution_name] = getAgencyInfo(transaction_direction_object)
    return institution_name
}

export const getInstitutionNameByParticipantWire = (transaction) => {
    let transaction_direction_object

    if (transaction.wire_transfer_direction === "received") {
        transaction_direction_object = transaction.source_account
    }
    else if (transaction.wire_transfer_direction === "sent") {
        transaction_direction_object = transaction.destination_account
    }
    else throw new Error("Transation direction not defined")

    let [institution_name] = getAgencyInfo(transaction_direction_object)
    return institution_name
}

export const getInstitutionNameByParticipantBankslip = (transaction) => {
    let transaction_direction_object

    if (transaction.bankslip_direction === "received") {
        transaction_direction_object = transaction.payer.account
    }
    else if (transaction.bankslip_direction === "payed") {
        transaction_direction_object = transaction.final_recipient.account
    }
    else throw new Error("Transation direction not defined")

    // return transaction_direction_object.name
    let [institution_name] = getAgencyInfo(transaction_direction_object)
    return institution_name
}

export const getReasonDescription = (transaction) => {
    let last_index = (transaction.analysis_events || []).length ? ((transaction.analysis_events || []).length - 1) : 0
    return  ((((transaction.analysis_events || [])[last_index] || {}).decision_metadata || {}).reason_description || "-")
}

export const setTransactionsInSessionStorage = (response) => {
    let transactionsArray = []
    response.map(transaction => transactionsArray.push(transaction.transaction_key))
    sessionStorage.setItem('transactionsArray', JSON.stringify(transactionsArray))
}

export const setGetTransactionsParamsInSessionStorage = (paramsObj, number_of_pages) => {
    sessionStorage.setItem('transactionsParams', JSON.stringify(paramsObj))
    sessionStorage.setItem('transactionsNumberOfPages', JSON.stringify(number_of_pages))
}

export const setPreTransactionsInSessionStorage = (response) => {
    let transactionsArray = []
    response.map(transaction => transactionsArray.push(transaction.id))
    sessionStorage.setItem('preTransactionsArray', JSON.stringify(transactionsArray))
}

export const setGetPreTransactionsParamsInSessionStorage = (paramsObj, number_of_pages) => {
    sessionStorage.setItem('preTransactionsParams', JSON.stringify(paramsObj))
    sessionStorage.setItem('preTransactionsNumberOfPages', JSON.stringify(number_of_pages))
}
