import React, { useContext, useState } from "react";
import AuthContext from "../../../../../context/auth-context";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DataField from "./DataField/DataField";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import {
  getTableRowClass,
  getGlobalAlertStatusEnum,
  getGlobalAlertFlagIcon,
  getMonitoringTopicName,
  getMonitoringTopicIcon,
  getTranslatedGlobalAlertStatus
} from "../../../utils/utils";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  blueText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#0b1f82",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontSize: "15px",
    lineHeight: "16px",
    textAlign: "center",
    margin: "10px",
    padding: "6px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: "#6F6F6F",
    fontSize: "16px",
    lineHeight: "19px",
    padding: "6px",
  },
  tableRow: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: "#6F6F6F",
    fontSize: "20px",
    lineHeight: "19px",
    padding: "6px",
    marginBottom: "20px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.37)",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#f0f0f0", 
      cursor: "pointer",}
  }
}));


function checkGlobalAlertStatus(monitoringData) {

  const isOnRestrictiveLists = Object.entries(monitoringData)
    .filter(([_, value]) => typeof value === "boolean")
    .some(([_, flag]) => flag === true);

  const checkReceitaFederalStatus = typeof monitoringData.receita_federal_status === "string" && monitoringData.receita_federal_status !== "regular";

  return isOnRestrictiveLists || checkReceitaFederalStatus;
}

function AccountPersonsTable(props) {
  const classes = useStyles();
  useTheme();

  const [expandedRows, setExpandedRows] = useState([]);

  const handleExpand = (event, index) => {
    event.stopPropagation();
    setExpandedRows((prevExpandedRows) => {
      if (prevExpandedRows.includes(index)) {
        return prevExpandedRows.filter((i) => i !== index);
      } else {
        return [...prevExpandedRows, index];
      }
    });
  };

  const { account_persons } = props

  let user_data = useContext(AuthContext).user_data

  if (account_persons.length === 0) {
    return (
      <div className="alignCenter">Conta não possui pessoas ativas sendo monitoradas</div>
    )
  } else {
    return (
      <Paper className={classes.tableStyle}>
        <Table style={{ padding: "8px" }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>ID</TableCell>
              {user_data.business_group_key &&
                <TableCell className={classes.tableHeader}>Companhia</TableCell>}
              <TableCell className={classes.tableHeader}>Nome</TableCell>
              <TableCell className={classes.tableHeader}>N° Documento</TableCell>
              <TableCell className={classes.tableHeader}>
                Status Global de Monitoramento
              </TableCell>
              <TableCell className={classes.tableHeader}>Data de Adição</TableCell>
              <TableCell className={classes.tableHeader}>Detalhamento</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {account_persons.map((person, index) => (
              <React.Fragment key={index}>
                <TableRow key={index} className={getTableRowClass(index)}>
                  <TableCell className={classes.tableText}>
                    {"Cliente #".concat(person.natural_person_id)}
                  </TableCell>
                  {user_data.business_group_key &&
                    <TableCell className={classes.tableText}>
                      {person.company_name}
                    </TableCell>}
                  <TableCell className={classes.tableText}>
                    {person.natural_person_data.name}
                  </TableCell>
                  <TableCell className={[classes.tableText, "breakWord"].join(" ")}>
                    {person.natural_person_data.document_number}
                  </TableCell>
                  <TableCell className={classes.tableText}>
                    <Tooltip
                      title={
                        <p className="tooltipText">
                          {getGlobalAlertStatusEnum(checkGlobalAlertStatus(person.monitoring_data))}
                        </p>
                      }
                    >
                      <div style={{ display: "flex", margin: "auto 0px" }}>
                        {getGlobalAlertFlagIcon(checkGlobalAlertStatus(person.monitoring_data))}
                      </div>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={classes.tableText}>
                    {moment(person.created_at).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                  </TableCell>
                  <TableCell className={classes.tableText}>
                    <IconButton onClick={(event) => handleExpand(event, index)}>
                      {expandedRows.includes(index) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </IconButton>
                  </TableCell>
                </TableRow>

                {
                  expandedRows.includes(index) && (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <div className="internalCollapse">
                          <div className="twoColumn">
                            {Object.entries(person.monitoring_data).map(([key, value], index) => {
                              if (key) {
                                const name = getMonitoringTopicName(key);
                                const data_icon = getMonitoringTopicIcon(key, value, person.natural_person_data.name);
                                return (
                                  <div className="column" key={index}>
                                    <DataField name={name} data={getTranslatedGlobalAlertStatus(value, data_icon)}/>
                                  </div>
                                );
                              }
                              return null;
                            })}
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                }
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default AccountPersonsTable;
