import React from "react";
import { useTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { getTableRowClass, formatAsMoney } from "../../utils/utils";
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    tableStyle: {
      border: "none",
      boxShadow: "none",
    },
    blueText: {
      fontFamily: "Open Sans",
      fontWeight: "600",
      color: "#0b1f82"
  },
  redText: {
      fontFamily: "Open Sans",
      fontWeight: "600",
      color: "#D91A1A"
  },
    tableText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      lineHeight: "16px",
      textAlign: "center",
      color: '#6F6F6F',
      margin: "10px",
      padding: "6px",
    },
    tableHeader: {
      textAlign: "center",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      color: '#6F6F6F',
      fontSize: "14px",
      lineHeight: "19px",
      padding: "6px",
    }
  }));

function DeloreanCard(props) {
    const classes = useStyles();
    useTheme();
    const { rufra_data } = props

    const { t } = useTranslation()

    const mapDecisionSuggestion = (rufra_data) => {
        if(rufra_data.flag ==='positive') {
            return 'pass'
        } else {
            if(rufra_data.flag === 'negative'){
                if(rufra_data.data?.total_occurrences > 0)
                    return 'reject'
                if(rufra_data.data?.total_occurrences === 0)
                    return 'neutral'
            }
        }
        return 'neutral'
    }

    const sortDates = (prev, next) => {
      const prevDate = new Date(prev.registration_date);
      const nextDate = new Date(next.registration_date);
      
      if (isNaN(prevDate)) return 1; 
      
      if (isNaN(nextDate)) return -1; 
    
      return nextDate - prevDate;
    };
    

    return (
        <div className="analysisCard" style={{height: "100%"}}>
            <div className={["blueText", "subtitleSize"].join(" ")}>{t("rufra_short_information")}</div>
            <div className="internalCardContainer" style={{display:"flex", flexGrow:"1", flexDirection: "column"}}>
                <div style={{display:"grid", width:"100%", height:"fit-content", gridTemplateColumns: "auto auto auto auto", padding: "12px"}}>
                    <div style={{display: "flex", flexDirection: "column", gap: "8px", width: "100%", alignItems: "center"}}>
                        <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>Score</div>
                        <div className={["normalText", "moneySize", "bold"].join(" ")}>{rufra_data.data.risk_score}</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", gap: "8px", width: "100%", alignItems: "center"}}>
                        <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>{t('risk_level')}</div>
                        <div className={["normalText", "moneySize", "bold"].join(" ")}>
                            {t(rufra_data.data.risk_classification  === "NONE" ? "neutral" : rufra_data.data.risk_classification.toLowerCase())}
                            </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", gap: "8px", width: "100%", alignItems: "center"}}>
                        <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>{t('decision_sugestion')}</div>
                        <div className={["normalText", "moneySize", "bold"].join(" ")} style={{color: mapDecisionSuggestion(rufra_data) === "reject" ? "#A00" : "#0AA"}}>{t(mapDecisionSuggestion(rufra_data))}</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", gap: "8px", width: "100%", alignItems: "center"}}>
                        <div className={["labelText", "normalMediumSize", "bold"].join(" ")}>{t('total_number_of_events')}</div>
                        <div className={["normalText", "moneySize", "bold"].join(" ")}>{(rufra_data.data.occurrences || []).length}</div>
                    </div>
                </div>

                {
                    (rufra_data.data.occurrences || []).length > 0 &&
                    <>
                        <div className={["blueText", "normalMediumSize", "bold"].join(" ")} style={{marginTop: 16, marginBottom: 16}}>{t('occurrences')}</div>
                        <Paper className={classes.tableStyle}>
                            <Table>
                                <TableHead className={getTableRowClass(0)}>
                                    <TableCell className={classes.tableHeader}>{t('financial_institution')}</TableCell>
                                    <TableCell className={classes.tableHeader}>{t('executor_name')}</TableCell>
                                    <TableCell className={classes.tableHeader}>{t('register_date')}</TableCell>
                                    <TableCell className={classes.tableHeader}>{t('fraud_value')}</TableCell>
                                </TableHead>
                                {rufra_data.data.occurrences
                                  .sort(sortDates)
                                  .map((occurrence, index) => (
                                    <TableRow className={getTableRowClass(1)} key={index}>
                                        <TableCell className={classes.tableText}>{occurrence.institution_name ?? "-"}</TableCell>
                                        <TableCell className={classes.tableText}>{(occurrence.executor_name || '').toUpperCase() ?? "-"}</TableCell>
                                        <TableCell className={classes.tableText}>
                                            {moment(occurrence.registration_date).format("DD/MM/YYYY") + " às " + moment(occurrence.registration_date).format("HH:mm") ?? "-"}
                                         </TableCell>
                                        <TableCell className={classes.tableText}>{ occurrence.fraud_value ? formatAsMoney(occurrence.fraud_value * 100) : "-"}</TableCell> 
                                    </TableRow>
                                ))}
                            </Table>
                        </Paper>
                    </>
                }
            </div>
        </div>
    );
}


export default DeloreanCard
