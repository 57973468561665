import React, {useContext} from "react";
import AuthContext from "../../../../context/auth-context";

function CardListHeader(props){

    let business_group_key = useContext(AuthContext).user_data.business_group_key 

    return (
        <div className="listCardHeader">
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"2 1 100px", textAlign:"center"}}
            >
                Chave do Alerta
            </div> 

            {business_group_key&&
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"2 1 180px", textAlign:"center"}}
            >
                Companhia
            </div>}
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 180px", textAlign:"center"}}
            >
                Tópico de Monitoramento
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 80px", textAlign:"center"}}
            >
                Gatilhos
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 45px", textAlign:"center"}}
            >
                Severidade
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 45px", textAlign:"center"}}
            >
                Status
            </div>
            <div 
                className={["listCardItem", "normalText", "normalSmallSize"].join(" ")}
                style={{flex:"1 1 110px", textAlign:"center"}}
            >
                Data do Alerta
            </div>
        </div>
    )
}

export default CardListHeader