import React, { useEffect, useCallback, useReducer, useContext, useState, useRef } from "react"
import { dataFetchReducer, useStateConnectedToCache } from "../../Utils/requestUtils"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import NaturalPersonAccountTable from "./NaturalPersonAccountTable/NaturalPersonAccountTable"
import Filter from "./Filter/Filter"
import OrderBy from "../utils/OrderBy"
import Pagination from "@material-ui/lab/Pagination"
import { CircularProgress } from '@material-ui/core'
import AuthContext from "../../../context/auth-context"
import axios from "axios"
import ErrorBoundary from '../../Utils/ErrorBoundary'
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import * as S from "./styles"


const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: "Open Sans",
        fontWeight: 600,
        fontSize: "22px",
        lineHeight: "27px",
        color: "#0b1f82",
        width: "auto"
    },
    error: {
        fontFamily: "Open Sans",
        fontWeight: 600,
        fontSize: "22px",
        lineHeight: "27px",
        color: "#0b1f82",
        marginUp: "300px"
    },
    subtitle: {
        fontFamily: "Open Sans",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "25px",
        color: "#0b1f82",
    },
    cardContainer: {
        width: "100%",
        display: "block",
    },
    pagination: {
        float: "right"
    },
    tableStyle: {
        border: "none",
        boxShadow: "none",
    },
    blueText: {
        fontFamily: "Open Sans",
        fontWeight: "600",
        color: "#0b1f82",
    },
    tableText: {
        fontFamily: "Open Sans",
        fontSize: "15px",
        lineHeight: "16px",
        textAlign: "center",
        margin: "10px",
        padding: "25px",
    },
    tableHeader: {
        textAlign: "center",
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        color: "#6F6F6F",
        fontSize: "16px",
        lineHeight: "19px",
        padding: "15px",
    },
    tableRow: {
        textAlign: "center",
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        color: "#6F6F6F",
        fontSize: "20px",
        lineHeight: "19px",
        padding: "6px",
        marginBottom: "20px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.37)",
        transition: "background-color 0.3s ease",
        "&:hover": {
            backgroundColor: "#f0f0f0",
        }
    }
}));

function NaturalPersonAccountsMonitoringInbox(props) {
    const history = useHistory()
    const user_data = useContext(AuthContext).user_data

    const [clicked, setClicked] = useState({
        "in_manual_analysis": false,
        "on_hold": false
    })

    const [orderBy, setOrderBy] = useState(null)

    const classes = useStyles()
    useTheme()

    const { t } = useTranslation()

    if (!user_data.roles.includes("read_account_monitoring")) {
        history.push("")
    }

    let local_storage_object_preffix = "account_monitoring"

    const [pageParams, setPageParams] = useStateConnectedToCache(local_storage_object_preffix)

    const [pageState, dispatchPageState] = useReducer(
        dataFetchReducer,
        { fetchedData: null, isLoading: true, isError: false }
    )

    const doRequest = useCallback(
        (payload) => {
            dispatchPageState({ type: "data_fetch_init" })
            axios.get("/dash/account_monitoring/natural_person_accounts", {
                params: payload
            }).then(response => {
                dispatchPageState({
                    type: "data_fetch_success",
                    payload: response.data
                })
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchPageState({ type: "data_fetch_failure_403" })
                else if ((error.response || {}).status === 404) dispatchPageState({ type: "data_fetch_failure_404" })
                else dispatchPageState({ type: "data_fetch_failure" })
            })
        }, []
    )

    const timerRef = useRef(null);
    useEffect(() => {

        const stopInterval = () => {
            clearInterval(timerRef.current)
        }

        let payload = {
            "page_number": pageParams.page - 1,
            "page_rows": 25
        }

        if (orderBy) {
            payload.order_by = orderBy
        }

        Object.keys(pageParams.filters).forEach((key, index) => {
            payload[key] = pageParams.filters[key]
        })

        const timer_ = setTimeout(() => {
            doRequest(payload)
        }, 300)

        return () => {
            clearTimeout(timer_)
            stopInterval();
        }
    }, [doRequest, user_data.roles, pageParams, orderBy])

    const handleChangePage = (event, page, filters) => {
        if (pageState.page === page) {
            return
        }
        setPageParams({ page: page, filters: filters })
    }
    const handleChangeOrderBy = (orderBy) => {
        setOrderBy(orderBy)
    }

    const contentContainer = (dataList) => {

        const nr_of_cards = dataList['data'].length

        if (nr_of_cards === 0) {
            return (
                <div className={classes.emptyContainer}>
                    <p className={classes.subtitle}>
                        {t("Nenhuma conta ativa para a companhia")}
                    </p>
                </div>
            )
        }

        else {
            return (
                < NaturalPersonAccountTable dataList={dataList['data']} />
            )
        }
    }

    if (pageState.fetchedData) {
        return (
            <S.topBar>
                <div style={{ display: "flex" }}>
                    <p className={["blueText", "titleSize"].join(" ")}>{t("MONITORAMENTO DE CONTAS DE PESSOA FÍSICA")}</p>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                        <ErrorBoundary doNothing>
                            <Filter
                                local_storage_object_preffix={local_storage_object_preffix}
                                setPageParams={setPageParams}
                                pageParams={pageParams}
                                clicked={clicked}
                                setClicked={setClicked}
                            />
                        </ErrorBoundary>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row-reverse", gap: "4px"}}>
                        <ErrorBoundary doNothing>
                            <OrderBy
                                handleChangeOrderBy={handleChangeOrderBy}
                                selectedValue={orderBy}
                            />
                        </ErrorBoundary>
                    </div>
                </div>
                <div>{contentContainer(pageState.fetchedData)}</div>
                <div>
                    <Pagination
                        className="pagination"
                        page={pageParams.page}
                        count={parseInt(pageState.fetchedData["number_of_pages"])}
                        onChange={(e, page) => handleChangePage(e, page, pageParams.filters)}
                    />
                </div>
            </S.topBar>
        )
    }
    else if (pageState.isError) {
        return (
            <div style={{ width: "auto" }}>
                <p className={["blueText", "titleSize"].join(" ")}>{t("MONITORAMENTO DE CONTAS DE PESSOA FÍSICA")}</p>
                <ErrorBoundary doNothing><Filter local_storage_object_preffix={local_storage_object_preffix} setPageParams={setPageParams} /></ErrorBoundary>
                <div style={{ height: "55vh", display: "flex" }}>
                    <p className={["blueText", "titleSize"].join(" ")} style={{ margin: "auto", textAlign: "center" }}>
                        {pageState.errorMessage}
                    </p>
                </div>
            </div>
        )
    }
    else if (pageState.isLoading) {
        return (
            <div style={{ width: "auto" }}>
                <p className={["blueText", "titleSize"].join(" ")}>{t("MONITORAMENTO DE CONTAS DE PESSOA FÍSICA")}</p>
                <div className="circularProgressCenter">
                    <CircularProgress />
                </div>
            </div>
        )
    }
    else return null

}

export default (NaturalPersonAccountsMonitoringInbox)
