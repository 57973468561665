import React from 'react'
import {getConfirmationIcon} from "../../../../utils/utils"

function ConfirmationContent (props) {
    const {alertKey_params, company_key, history} = props

    let whitelist_company_key_array = [
        "123-123-123", 
        "560f7015-0d23-454e-b254-1812f1e358b7", 
        "8d57f24b-2f1b-4acd-bede-0579e70ca33f", 
        "b81a015d-640e-4d11-8921-1ab46f168c8d", 
        "64520138-3e5b-4c2a-856a-33e1b199b0cb" 
    ]

    let alertsArray = JSON.parse(sessionStorage.getItem('alertsArray'))

    let index = alertsArray.findIndex(alert => alert === alertKey_params)
    let showNextAlertButton = index < alertsArray.length - 1

    return(
        <div style={{ width: "310px", height:"217px"}}>
            <div className="internalCardContainer" style={{display:"flex", height:"40%", justifyContent:"center"}}>
                {getConfirmationIcon(props.data.success)}
            </div>
            <div className="internalCardContainer" style={{display:"flex", textAlign:"center", justifyContent:"center"}}>
                {props.data.message}
            </div>
            <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                {whitelist_company_key_array.includes(company_key) ?
                    <div 
                        className={["button", "onlyboarder", "normalMediumSize"].join(" ")}
                        style={{width: "40%"}}
                        onClick={() => history.push("/account-monitoring-alerts-inbox")}
                    >
                        Voltar para <br/>Alertas
                    </div>
                    :
                    <div 
                        className={["button", "standard", "normalMediumSize"].join(" ")}
                        onClick={() => history.push("/account-monitoring-alerts-inbox")}
                    >
                        VOLTAR À TELA INICIAL 
                    </div>
                }
                {showNextAlertButton && whitelist_company_key_array.includes(company_key) &&
                <div 
                    className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                    onClick={props.onClose}
                    style={{width: "40%"}}
                >
                    Próximo <br/>Alerta
                </div>}
            </div>
        </div>
    )
}

export default ConfirmationContent