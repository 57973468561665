import React, { useContext } from "react";
import AuthContext from "../../../../context/auth-context";
import { Link } from "react-router-dom";
import * as S from "./styles"
import {
  translateAccountStatus,
  accountStatusSiglum,
  translateAlertAccountStatus,
  accountAlertStatusSiglum
} from "../../utils/utils";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { Tooltip } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles"



const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  blueText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#0b1f82",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontSize: "15px",
    lineHeight: "16px",
    textAlign: "center",
    margin: "10px",
    padding: "25px",
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: "#6F6F6F",
    fontSize: "16px",
    lineHeight: "19px",
    padding: "15px",
  },
  tableRow: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: "#6F6F6F",
    fontSize: "20px",
    lineHeight: "19px",
    padding: "6px",
    marginBottom: "20px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.37)",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    }
  }
}));

function NaturalPersonAccountTable(props) {
  let user_data = useContext(AuthContext).user_data;

  const classes = useStyles()
  useTheme()
  let { dataList } = props

  const AccountStatus = ({ status }) => {
    const { siglum, color } = accountStatusSiglum(status);
  
    return (
      <div style={{ 
        color, 
        fontWeight: "bold", 
        fontSize: "1.5rem", 
        display: "inline-block", 
        padding: "5px", 
        borderRadius: "5px" 
      }}>
        {siglum}
      </div>
    );
  };

  return (
    <Table style={{ padding: "8px" }}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeader}>ID da Conta</TableCell>
          {user_data.business_group_key &&
            <TableCell className={classes.tableHeader}>Companhia</TableCell>}
          <TableCell className={classes.tableHeader}>Alertas em Aberto</TableCell>
          <TableCell className={classes.tableHeader}>Status da Conta</TableCell>
          <TableCell className={classes.tableHeader}>
            Próxima Atualização
          </TableCell>
          <TableCell className={classes.tableHeader}>Data de Criação</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {dataList.map((natural_person_account, index) => (
          <React.Fragment key={index}>
            <S.StyledTableRow>
              <TableCell className={classes.tableText}>
                <Link
                  className={["listCardItem", "link", "subtitleSize", "bold"].join(
                    " "
                  )}
                  style={{ flex: "2 1 120px", textAlign: "center" }}
                  to={"/natural-person-account/" + natural_person_account.natural_person_account_key}
                >
                  {natural_person_account.account_id}
                </Link>
              </TableCell>
              {user_data.business_group_key &&
                <TableCell className={classes.tableText}>
                  {natural_person_account.company_name}
                </TableCell>}
              <TableCell className={classes.tableText}>
                <Tooltip
                  title={
                    <p className="tooltipText">
                      {translateAlertAccountStatus(natural_person_account.have_active_alerts)}
                    </p>
                  }
                >
                  <div style={{ display: "flex", margin: "auto 0px" }}>
                    {accountAlertStatusSiglum(natural_person_account.have_active_alerts)}
                  </div>
                </Tooltip>
              </TableCell>
              <TableCell className={classes.tableText}>
                <Tooltip
                  title={
                    <p className="tooltipText">
                      {translateAccountStatus(natural_person_account.account_status)}
                    </p>
                  }
                >
                  <div>
                    <AccountStatus status={natural_person_account.account_status} />
                  </div>
                </Tooltip>
              </TableCell>
              <TableCell className={classes.tableText}>
                {moment(natural_person_account.next_update).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
              </TableCell>
              <TableCell className={classes.tableText}>
                {moment(natural_person_account.created_at).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
              </TableCell>
            </S.StyledTableRow>
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  );
}

export default NaturalPersonAccountTable
