import React, {useContext, useState} from 'react'
import TextField from "@material-ui/core/TextField";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { getAlertStatusText } from "../../../utils/utilsAlert"
import {setAllowChanges} from "../../../utils/utils"
import AuthContext from "../../../../../context/auth-context"
import TreatedDialog from "./Dialogs/TreatedDialog"
import clockIcon from '../../../../../assets/cardEntryIcons/Clock.svg';
import DataField from "../../../utils/DataField"
import moment from "moment"

const theme = createMuiTheme({
    overrides: { MuiInputBase: { root:{
        fontFamily: "Open Sans",
        fontSize: "14px",
        lineHeight: "19px"

    }}},
    palette:{
        primary: {
            main:"#0b1f82"
        },
    }
  });

function AccountMonitoringObservationCard(props) {

    let [openTreatedDialog, setOpenTreatedDialog] = useState(false);
    let [isValidAlert, setIsValidAlert] = useState(null);
    let [observationBox, setObservationBox] = useState();

    

    const handleDialogOpenClick = (isValid) => {
        setIsValidAlert(isValid)
        setOpenTreatedDialog(true)
    };

    const handleDialogClose = () => {
        setOpenTreatedDialog(false)
        setTimeout(function (){setIsValidAlert(null)}, 300)    
    };

    const handleObservationBoxChange = (event) => {
        if (event.target.value.length === 0){
            setObservationBox(null)
        }
        else{
            setObservationBox(event.target.value)
        }
    };

    let roles = useContext(AuthContext).user_data.roles

    let permissionRole = "update_account_monitoring_alerts"

    let allowChanges = setAllowChanges(props.alert.alert_status, roles, permissionRole)

    let observation = null
    let analyst = null
    let event_date = null
    let new_status = null

    if (!allowChanges){
        let last_event = props.alert.alert_events == null || props.alert.alert_events.length ===0? null :
            props.alert.alert_events[props.alert.alert_events.length-1]
        if (last_event){
            observation = last_event.observation
            analyst     = last_event.analyst_name
            event_date  = last_event.event_date
            new_status  = last_event.new_status
        }
    }

    return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize"].join(" ")}>Revisão</span>
            </div>
            {allowChanges ? 
            <div>
                <ThemeProvider theme={theme} >
                <TextField         
                    fullWidth
                    multiline
                    variant="outlined"
                    margin="dense"
                    rows="5"
                    color="primary"
                    defaultValue={null}
                    onChange={handleObservationBoxChange}
                />
                </ThemeProvider>
                <div style={{display:"flex", marginTop: "10px"}}>
                    <div 
                        className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")}
                        style={{marginLeft:"auto"}}
                        onClick={() => handleDialogOpenClick(false)}
                    >
                        Finalizar - Alerta Inválido
                    </div>
                    <div 
                        className={["button", "approved", "normalText", "normalMediumSize"].join(" ")}
                        onClick={() => handleDialogOpenClick(true)}
                    >
                        Finalizar - Alerta Válido
                    </div>   
                </div>
                <React.Fragment>
                    <TreatedDialog
                        alert={props.alert}
                        observation={observationBox}
                        open={openTreatedDialog}
                        isValidAlert={isValidAlert}
                        onClose={() => handleDialogClose()}
                    />
                </React.Fragment>
            </div> : 
            <div style={{display:"flex", flexDirection:"column"}}>
                <div style={{display: "flex", flexGrow:"1", marginTop: "20px", marginBottom: "13px", marginLeft:"0px", lineHeight: "29px", fontSize: "22px", fontWeight:"500"}}>
                        {observation == null ? ("Nenhum comentário") : ("\"" + observation + "\"")}
                </div>
                <div style={{display:"flex", flexGrow:"1", flexDirection:"row"}}>
                    <DataField
                        title= "Status"
                        alt={"clockIcon Logo"}
                        label={new_status ? getAlertStatusText(new_status) : "Pendente"}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                        marginRight="40px"
                        display={"inline"}
                    />
                    <DataField
                        title= "Analista"
                        alt={"clockIcon Logo"}
                        label={analyst ? analyst : "-" }
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                        marginRight="40px"
                        display={"inline"}
                    />
                    <DataField
                        titleImage={clockIcon}
                        titleTooltipText="Data da Observação"
                        alt={"clockIcon Logo"}
                        label={event_date ?  moment(event_date).format("DD/MM/YYYY - HH:mm") : "-"}
                        size={"normalMediumSize"}
                        titleNoWrap={true}
                        margin="5px"
                        display={"inline"}
                    />
                </div>
            </div>
            }
        </div>
    )
}

export default AccountMonitoringObservationCard