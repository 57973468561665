import React from "react";
import FilterBox from "../../../Utils/FilterBox/FilterBox"
import { getLinkKeyTypesFromEventGroup } from "../../utils/utilsAlert"

function Filter(props) {
	
    const { setPageParams, eventGroup } = props

    const alert_event_type = [ 
        ["PEP", "pep"],
        ["OFAC", "ofac"],
        ["UNSC", "unsc"],
        ["CEIS", "ceis"],
        ["CNEP", "cnep"],
        ["Status Receita Federal", "receita_federal_status"],
        ["IBAMA", "ibama"],
    ]


    let filterTypes = {
        alert_status: {
            type: "enum",
            enumList: [
                ["Em Análise", "in_manual_analysis"],
                ["Tratado - Verdadeiro Positivo", "solved_true_positive"],
                ["Tratado - Falso Positivo", "solved_false_positive"],
            ],
            disabled: false,
            validation:false,
            description: "Status do Alerta"
        },
        irregularity_type:{
            type: "enum",
            enumList: [
                ["Fraude", "fraud"],
                ["Compliance", "compliance"]
            ],
            disabled: false,
            validation:false,
            description: "Tipo de Irregularidade"
        },
        link_key_type:{
            type: "enum",
            enumList: getLinkKeyTypesFromEventGroup(eventGroup),
            disabled: false,
            validation:false,
            description: "Tipo de Entidade"
        },
        link_key:{
            type: "string",
            disabled: false,
            validation:false,
            description: "Chave do Alerta"
        },
        alert_date: {
            type: "date",
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Data"
        },
        minimum_triggers: {
            type: "numeric",
            disabled: false,
            validation: false,
            validation_rule: null,
            description: "Mínimo de Gatilhos"
        },
        event_type : {
            type: "enum",
            enumList: [...alert_event_type],
            disabled: false,
            validation:false,
            description: "Tipo do Evento"
        }
    }
    
    return (
        <FilterBox 
            setPageParams={setPageParams}
            filterTypes={filterTypes}
            local_storage_object_preffix={props.local_storage_object_preffix}
        />
    )

}

export default Filter