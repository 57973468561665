import React, { useState, useEffect, useCallback } from "react";
import { Dialog } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { LabelInput } from "../../../../@GeneralComponents/LabelInput";
import FilterOption from "../../../../@GeneralComponents/FilterOption";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { useTranslation } from "react-i18next";
import { removeSpecialCharacters, replaceSpacesToUnderline, removeDuplicatedUnderscore, justNumbers, isEven, isNotNull } from "../../../utils"
import { countStringInString } from "../../../utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";


export function VariablesDialog (props) {
    
    const { open, onCloseDialog, treeState, userVariablesAvailable, setUserVariablesAvailable } = props

    const { t } = useTranslation()
    const [userVariablesUsed, setUserVariablesUsed] = useState({})

    const variableInitialState = {
        variable_name: "",
        variable_type: "",
        variable_value: ""
    }

    const [treeDataString, setTreeDataString] = useState("")
    const [variable, setVariable] = useState(variableInitialState)

    useEffect(()=>{
        if (treeState.treeData.length !== 0) {
            setTreeDataString(JSON.stringify(treeState.treeData))
        }
    },[treeState.treeData])

    const userVariablesHandler = useCallback(() => {
        let newUserVariablesUsed = {}
        if (treeDataString) {
            Object.keys(userVariablesAvailable).forEach((key)=>{
                let variableName = userVariablesAvailable[key].variable_name
                let searchString = `{"variable_name":"${variableName}"}`
                let countVariable = countStringInString(searchString, treeDataString)
                newUserVariablesUsed = {...newUserVariablesUsed, [variableName]:countVariable}
            })
            setUserVariablesUsed(newUserVariablesUsed)
        }
    }, [userVariablesAvailable, treeDataString])

    useEffect (()=>{
        if (open) {
            userVariablesHandler()
        }
    },[open, userVariablesHandler])

    const [errors, setErrors] = useState({
        variable_name: false,
        variable_type: false,
        variable_value: false
    });

    function formatVariable (text) {
        let cleanedText = removeSpecialCharacters(text)
        cleanedText = replaceSpacesToUnderline(cleanedText)
        cleanedText = removeDuplicatedUnderscore(cleanedText)
        cleanedText = cleanedText.toLowerCase()
        return cleanedText
    }

    function clearInitialVariableValue () {
        setVariable(prevVariableData => ({
            ...prevVariableData,
            variable_value: ""
        }));
    };

    function formatInitialValue (value) {
        if (variable.variable_type==="bool") {
            return value
        } else if (variable.variable_type==="number") {
            return justNumbers(value)
        } else {
            return (value)
        }
    }

    function formatToBackend (value) {
        if (variable.variable_type==="bool") {
            if (value === "true") return true
            else return false
        } else if (variable.variable_type==="number") {
            return Number(justNumbers(value))
        } else {
            return (value)
        }
    }

    function onAddVariable () {
        if (isVariableValid) {
            let variableToAdd = {
                variable_name: variable.variable_name,
                variable_type: variable.variable_type,
                variable_value: formatToBackend(variable.variable_value)
            }
            setUserVariablesAvailable([...userVariablesAvailable, variableToAdd])
            setVariable(variableInitialState)
        } else {
            handleBlur()
        }
    }

    function onRemoveVariable (variableNameToRemove) {
        setUserVariablesAvailable(userVariablesAvailable.filter((variable)=>(variable.variable_name!==variableNameToRemove)))
        setUserVariablesUsed({...userVariablesUsed, [variableNameToRemove]: 0})
    }

    const handleValueChange = (id, value) => {
        setVariable({
            ...variable,
            [id]: value
        })
        setErrors({
            ...errors,
            [id]: ""
        });
    }

    function isNotDuplicatedVariable (value) {
        let isNotDuplicated = userVariablesAvailable.filter((variable)=>(variable.variable_name===value)).length === 0
        return isNotDuplicated
    }

    const validateInput = {
        variable_name: (value) => isNotNull(value)&&isNotDuplicatedVariable(value),
        variable_type: (value) => isNotNull(value),
        variable_value: () => true
    }

    function isVariableValid () {
        const variableNameIsValid = (validateInput.variable_name(variable.variable_name))
        const variableTypeIsValid = (validateInput.variable_type(variable.variable_type))
        const variableInitialValueIsValid = (validateInput.variable_value(variable.variable_value))
        return variableNameIsValid&&variableTypeIsValid&&variableInitialValueIsValid
    }

    const errorMessages = {
        variable_name: "variable_name_error_message",
        variable_value: ""
    }

    const handleBlur = (id, value) => {
        const error = !validateInput[id](value);
        setErrors({
            ...errors,
            [id]: error
        });
    };

    return (
        <Dialog
            open={open}
            onClose={()=>onCloseDialog()}
            fullWidth
            maxWidth="md"
            >
            <div className="dialogContent">
                <header className="dialogContentHeader blueText">
                    <span>{t("manage_variables")}</span>
                    <IconButton
                        onClick={()=>onCloseDialog()}
                    >
                        <CloseIcon/>
                    </IconButton>
                </header>
                <div className="smallerInternalCardContainer">
                    <span className="normalSmallSize normalText bold">{t("create_variables")}</span>
                    <div style={{display:"grid", gridTemplateColumns: "30% 30% 30% 6%", alignItems: "center", gap:"10px"}}>
                        <LabelInput
                            id={"variable_name"}
                            labelValue={"variable_name"}
                            value={variable.variable_name}
                            onChange={(value) => handleValueChange("variable_name", value)}
                            onBlur={(value) => handleBlur("variable_name", value)}
                            formatValue={(value)=>formatVariable(value)}
                            error={errors.variable_name}
                            errorMessage={errorMessages.variable_name}
                        />
                        <FilterOption
                            id={"variable_type"}
                            labelValue={"variable_type"}
                            filters={variable}
                            setFilters={setVariable}
                            options={["string", "number", "bool","datetime"]}
                            onChange={() => clearInitialVariableValue()}
                        />
                        {variable.variable_type === "bool" ? (
                          <FilterOption 
                            id={"variable_value"}
                            labelValue={"variable_value"}
                            filters={variable}
                            setFilters={setVariable}
                            options={[true, false]}
                          />
                        ) : variable.variable_type === "datetime" ? (
                          <div className="filter normalText normalSmallSize">
                            <label htmlFor={"variable_value"}>{t("variable_value")}:</label>
                            <DatePicker
                              selected={variable.variable_value ? new Date(variable.variable_value) : null}
                              onChange={(date) => handleValueChange("variable_value", moment(date?.toISOString()).format("YYYY-MM-DDTHH:mm:ss.SSS"))}
                              dateFormat= "yyyy-MM-dd HH:mm:ss.SSS"
                              showTimeSelect
                              timeIntervals={30}
                            />
                          </div>
                        ) : (
                          <LabelInput 
                            id={"variable_value"}
                            labelValue={"variable_value"}
                            value={variable.variable_value}
                            onChange={(value) => handleValueChange("variable_value", value)}
                            onBlur={(value) => handleBlur("variable_value", value)}
                            formatValue={(value)=>formatInitialValue(value)}
                            error={errors.variable_value}
                            errorMessage={errorMessages.variable_value}
                            isDisabled={!variable.variable_type}
                          />
                        )}
                        <IconButton
                            onClick={()=>onAddVariable()}
                            style={{margin:"auto"}}
                            disabled={!isVariableValid()}
                        >
                            <AddCircleIcon className={"blue"} style={!isVariableValid()?{opacity: "50%", cursor: "not-allowed"}:null}/>
                        </IconButton>
                    </div>               
                </div>
                {userVariablesAvailable.length>0 &&
                <div className="smallerInternalCardContainer">
                    <div className="normalSmallSize normalText bold">{t("created_variables")}</div>
                    <div className={["normalText", "normalSmallSize"].join(" ")}>
                        {t("created_variables_description")}
                    </div>
                    <ul className="normalSmallSize normalText noMargin noPadding">
                        {userVariablesAvailable
                        .sort((a, b) => a.variable_name === b.variable_name ? 0 : a.variable_name > b.variable_name ? 1 : -1)
                        .map((variable,index)=>(
                        <li key={variable.variable_name} className={isEven(index)?"lighterGrayBackground":"lighterGrayBorder"} style={{display:"grid", gridTemplateColumns: "30% 30% 30% 6%", alignItems: "center", gap:"10px"}}>
                            <div className="variableCell">{variable.variable_name}</div>
                            <div className="variableCell">{t(variable.variable_type)}</div>
                            <div className="variableCell">{String(variable.variable_value)}</div>
                            <IconButton
                                onClick={()=>onRemoveVariable(variable.variable_name)}
                                style={{margin:"auto"}}
                                disabled={(userVariablesUsed[variable.variable_name]||0)!==0}
                            >
                                <RemoveCircleIcon className="blue" style={(userVariablesUsed[variable.variable_name]||0)!==0?{opacity: "50%", cursor: "not-allowed"}:null}/>
                            </IconButton>
                        </li>
                        ))}
                    </ul>
                </div>}
            </div>
        </Dialog>
    )
}