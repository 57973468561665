import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';
import axios from 'axios';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import { getTableRowClass, getAnalysisStatusSiglum, getAnalysisStatusColor, getAnalysisStatus} from "../../utils/utils"
import { Tooltip } from '@mui/material';
import { Check, ErrorOutline } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    tableStyle: {
      border: "none",
      boxShadow: "none",
    },
    tableText: {
      fontFamily: "Open Sans",
      fontWeight: 'normal',
      fontSize: "12px",
      lineHeight: "24px",
      color: "#333",
      textAlign: "center",
      margin: "10px",
      padding: "6px",
    },
    tableHeader: {
      textAlign: "center",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      color: '#6F6F6F',
      fontSize: "14px",
      lineHeight: "19px",
      padding: "6px",
    }
  }));

export default function Partners(props) {

    const classes = useStyles();
    useTheme();

    const [naturalPartners, setNaturalPartners] = useState([])
    const [legalPartners, setLegalPartners] = useState([])

    const classifyPartners = useCallback(() => {
        const natural_partners = []
        const legal_partners = []
        for (let i = 0; i < (props.partners || []).length; i++) {
            if (props.partners[i].document_number.length === 14) {
                natural_partners.push(props.partners[i])
            } else if (props.partners[i].document_number.length === 18) {
                legal_partners.push(props.partners[i])
            }
        }
        return {natural_partners, legal_partners}
    }, [props.partners])

    useEffect(() => {
        const data = classifyPartners();
        setNaturalPartners(data.natural_partners);
        setLegalPartners(data.legal_partners);
    }, [classifyPartners])



    return (
        <S.Container>
            <S.Title>Quadro Societário</S.Title>
            {naturalPartners.length > 0 ?
                <>
                    <S.CardTitle>Pessoas Físicas</S.CardTitle>
                    <Paper className={classes.tableStyle}>
                        <Table style={{ padding: "8px" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHeader}>Nome</TableCell>
                                    <TableCell className={classes.tableHeader}>CPF</TableCell>
                                    <TableCell className={classes.tableHeader}>Email</TableCell>
                                    <TableCell className={classes.tableHeader}>Telefone</TableCell>
                                    <TableCell className={classes.tableHeader}>Status na Receita</TableCell>
                                    <TableCell className={classes.tableHeader}>PEP/Relacionado</TableCell>
                                    <TableCell className={classes.tableHeader}>Status da Análise</TableCell>
                                    <TableCell className={classes.tableHeader}>Validação</TableCell>
                                    <TableCell className={classes.tableHeader}>Expandir</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {naturalPartners.map((partner, index) => (
                                    <NaturalPartner natural_person={partner} index={index}/>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </> : <></>
            }
            {legalPartners.length > 0 ?
                <>
                    <S.CardTitle>Pessoas Jurídicas</S.CardTitle>
                    <Paper className={classes.tableStyle}>
                        <Table style={{ padding: "8px" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHeader}>Razão Social</TableCell>
                                    <TableCell className={classes.tableHeader}>CNPJ</TableCell>
                                    <TableCell className={classes.tableHeader}>Email</TableCell>
                                    <TableCell className={classes.tableHeader}>Telefone</TableCell>
                                    <TableCell className={classes.tableHeader}>Status na Receita</TableCell>
                                    <TableCell className={classes.tableHeader}>Status da Análise</TableCell>
                                    <TableCell className={classes.tableHeader}>Validação</TableCell>
                                    <TableCell className={classes.tableHeader}>Expandir</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {legalPartners.map((partner, index) => (
                                    <LegalPartner legal_person={partner} index={index}/>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </> : <></>
            }
            {naturalPartners.length === 0 && legalPartners.length === 0 ?
                <div style={{color: '#6f6f6f', width: '100%', textAlign: 'center', padding: '16px 0'}}>Informação indisponível</div> : <></>
            }
        </S.Container>
    );   
}

function retrieve_latest_valid_analysis_status_event_index(analysis_status_events) {
    for (let i = analysis_status_events.length - 1; i >= 0; i--) {
        if (analysis_status_events[i].decision_metadata && ['in_manual_analysis', 'automatically_approved', 'automatically_reproved'].includes(analysis_status_events[i].new_status)) {
            return i;
        }
    }
    return analysis_status_events.length - 1;
}

function TranslateValidation(props) {
    const {bureau, payload} = props
    if (bureau && payload) {
        return (
            <Tooltip title="Sócio validado na base de dados" placement='left'>
                <Check style={{color: '#73c580', width: "22px", height: "22px", margin: 0}} />
            </Tooltip>
        )
    } else if (bureau && !payload) {
        return (
            <Tooltip title="Sócio não fornecido, mas encontrado na base de dados" placement='left'>
                <ErrorOutline style={{color: '#ddbb08', width: "22px", height: "22px", margin: 0}} />
            </Tooltip>
        )
    } else if (!bureau && payload) {
        return (
            <Tooltip title="Sócio fornecido não encontrado na base de dados" placement='left'>
                <ErrorOutline style={{color: '#ddbb08', width: "22px", height: "22px", margin: 0}} />
            </Tooltip>
        )
    }
    return <></>
}

function NaturalPartner (props) {

    const classes = useStyles();
    useTheme();

    const [partner, setPartner] = useState();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const retrievePartner = useCallback(async () => {
        if (props?.natural_person.natural_person_key) {
            setLoading(true);
            const response = await axios.get(`/dash/onboarding/natural_person/${props.natural_person.natural_person_key}`)
            if (response?.status === 200) {
                setPartner(response.data);
                setLoading(false);
            } else {
                setError(true);
                setLoading(false)
            }
        } else {
            setPartner(props.natural_person);
            setLoading(false);
        }
    }, [props.natural_person])

    useEffect(() => {retrievePartner()}, [retrievePartner]);

    if (error || loading) {
        return (
            <></>
        );
    }

    if (partner) {

        const parsedPartner = parseNaturalPartner(partner, props.natural_person.is_bureau_partner, props.natural_person.is_payload_partner);
        const greater = (parsedPartner.emails || []).length > (parsedPartner.phones || []).length ? parsedPartner.emails : parsedPartner.phones

        return (
            <>
                <TableRow
                    key={props.index}
                    className={getTableRowClass(props.index)}
                    style={expanded ? { border: "none" } : {}}
                >
                    <TableCell className={classes.tableText} style={expanded ? { border: "none" } : {}}>
                        {parsedPartner.natural_person_key !== undefined ?
                            <Tooltip title={`${parsedPartner.has_negative_flags ? 'Há' : 'Não há'} apontamentos para essa análise de sócio`} placement="right">
                                <div style={{display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center', color: "#0b1f82", width: 'fit-content'}}>
                                    {parsedPartner.has_negative_flags ?
                                        <ErrorOutline style={{color: '#D91A1A', width: "16px", height: "16px", margin: 0}} /> :
                                        <Check style={{color: '#73c580', width: "16px", height: "16px", margin: 0}} />
                                    }
                                    <Link className="link" to={"/natural-person/" + parsedPartner.natural_person_key}>
                                        <p style={{margin: 0}}>{parsedPartner.name}</p> 
                                    </Link>
                                </div>
                            </Tooltip> : <p style={{margin: 0}}>{parsedPartner.name}</p>                         
                        }
                    </TableCell>
                    <TableCell className={classes.tableText} style={expanded ? { border: "none" } : {}}>{parsedPartner.document_number}</TableCell>
                    <TableCell className={classes.tableText} style={expanded ? { border: "none" } : {}}>{(parsedPartner.emails || ['-'])[0]}</TableCell>
                    <TableCell className={classes.tableText} style={expanded ? { border: "none" } : {}}>{(parsedPartner.phones || ['-'])[0]}</TableCell>
                    <TableCell className={classes.tableText} style={expanded ? { border: "none" } : {}}>
                        { parsedPartner?.receita_federal_status?.flag === 'positive' &&
                            <Tooltip title={parsedPartner.receita_federal_status.description.replace('Receita Federal - ', '')} placement='right'>
                                <S.GreenBadge>
                                    {capitalize(parsedPartner.receita_federal_status.fiscal_situation)}
                                </S.GreenBadge>
                            </Tooltip>
                        }
                        { parsedPartner?.receita_federal_status?.flag === 'negative' &&
                            <Tooltip title={parsedPartner.receita_federal_status.description.replace('Receita Federal - ', '')} placement='right'>
                                <S.RedBadge>
                                    {capitalize(parsedPartner.receita_federal_status.fiscal_situation)}
                                </S.RedBadge>
                            </Tooltip>
                        }
                        { !parsedPartner?.receita_federal_status ? '-' : <></> }
                    </TableCell>
                    <TableCell className={classes.tableText} style={expanded ? { border: "none" } : {}}>
                        <PepBadge pep={parsedPartner.pep} related_pep={parsedPartner.related_pep} />
                    </TableCell>
                    <TableCell className={classes.tableText} style={expanded ? { border: "none" } : {}}>
                        <Tooltip placement="right" title={getAnalysisStatus(parsedPartner.analysis_status)}>
                            <div className={["circle", "small"].join(" ")} style={{backgroundColor: getAnalysisStatusColor(parsedPartner.analysis_status)}}>
                                {getAnalysisStatusSiglum(parsedPartner.analysis_status)}
                            </div>  
                        </Tooltip>
                    </TableCell>
                    <TableCell className={classes.tableText} style={expanded ? { border: "none" } : {}}>
                        <TranslateValidation bureau={parsedPartner.bureau} payload={parsedPartner.payload} />
                    </TableCell>
                    <TableCell className={classes.tableText} style={expanded ? { border: "none" } : {}} onClick={() => (greater || []).length > 1 ? setExpanded(!expanded) : {}}>
                        { (greater || []).length > 1 ? (expanded ? <S.Toggle>-</S.Toggle> : <S.Toggle>+</S.Toggle>) : <></> }
                    </TableCell>
                </TableRow>
                {expanded && (greater || []).map((_, index) => {
                    
                    if (index === 0) return <></>

                    return (
                        <TableRow
                            key={props.index}
                            className={getTableRowClass(props.index)}
                            style={index === greater.length - 1 ? {} : { border: "none" }}
                        >
                            <TableCell className={classes.tableText} style={index === greater.length - 1 ? {} : { border: "none" }}></TableCell>
                            <TableCell className={classes.tableText} style={index === greater.length - 1 ? {} : { border: "none" }}></TableCell>
                            <TableCell className={classes.tableText} style={index === greater.length - 1 ? {} : { border: "none" }}>{index < (parsedPartner.emails || []).length ? parsedPartner.emails[index] : ''}</TableCell>
                            <TableCell className={classes.tableText} style={index === greater.length - 1 ? {} : { border: "none" }}>{index < (parsedPartner.phones || []).length ? parsedPartner.phones[index] : ''}</TableCell>
                            <TableCell className={classes.tableText} style={index === greater.length - 1 ? {} : { border: "none" }}></TableCell>
                            <TableCell className={classes.tableText} style={index === greater.length - 1 ? {} : { border: "none" }}></TableCell>
                            <TableCell className={classes.tableText} style={index === greater.length - 1 ? {} : { border: "none" }}></TableCell>
                            <TableCell className={classes.tableText} style={index === greater.length - 1 ? {} : { border: "none" }}></TableCell>
                            <TableCell className={classes.tableText} style={index === greater.length - 1 ? {} : { border: "none" }}></TableCell>
                        </TableRow>
                    )
                })}
            </>
        );
    }
    
    return <></>;
}

function capitalize(str) {
    if (typeof str !== 'string' || str.length === 0) {
        return '';
    }
    return str
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.toLowerCase().slice(1))
        .join(' ');
}

function parseNaturalPartner(partner, isBureau, isPayload) {
    
    function parsePhoneNumbers(phone_number_array) {
        const phones = []
        for (let i = 0; i < phone_number_array.length; i++) {
            let formatted_phone_string = '';
            if (phone_number_array[i]?.area_code) {
                if (phone_number_array[i]?.international_dial_code) {
                    formatted_phone_string = formatted_phone_string + `+${phone_number_array[i].international_dial_code} `
                }
                formatted_phone_string = formatted_phone_string + `(${phone_number_array[i].area_code}) `
            }
            if (phone_number_array[i]?.number) {
                let formatted_number_string = phone_number_array[i].number;
                if (phone_number_array[i].number.length === 9) {
                    formatted_number_string = phone_number_array[i].number.slice(0, 5) + '-' + phone_number_array[i].number.slice(5)
                } else if (phone_number_array[i].number.length === 8) {
                    formatted_number_string = phone_number_array[i].number.slice(0, 4) + '-' + phone_number_array[i].number.slice(4)
                }
                formatted_phone_string = formatted_phone_string + formatted_number_string
            }
            phones.push(formatted_phone_string);
        }

        return phones;
    }

    function parseEmails(email_array) {
        const emails = [];
        for (let i = 0; i < email_array.length; i++) {
            emails.push(email_array[i]?.email);
        }
        return emails;
    }
    
    if (!partner.natural_person_key) {
        return {
            document_number: partner.document_number,
            name: partner.name,
            analysis_status: "not_analised",
            bureau: isBureau,
            payload: isPayload
        }
    }

    const valid_index = retrieve_latest_valid_analysis_status_event_index(partner.analysis_status_events || []);

    const parsed = {
        natural_person_key: partner.natural_person_key,
        analysis_status: partner?.analysis_status,
        document_number: partner?.document_number,
        name: capitalize(partner.analysis_status_events[valid_index]?.decision_metadata?.registration_data?.name?.description ?? '-'),
        phones: parsePhoneNumbers(partner.analysis_status_events[valid_index]?.decision_metadata?.registration_data?.phone_number ?? []),
        emails: parseEmails(partner.analysis_status_events[valid_index]?.decision_metadata?.registration_data?.['e-mail'] ?? []),
        receita_federal_status: partner.analysis_status_events[valid_index]?.decision_metadata?.receita_federal_status,
        pep: partner.analysis_status_events[valid_index]?.decision_metadata?.pep,
        related_pep: partner.analysis_status_events[valid_index]?.decision_metadata?.related_pep,
        has_negative_flags: hasNegativeFlags(partner?.analysis_status_events[valid_index]?.decision_metadata),
        bureau: isBureau,
        payload: isPayload
    }

    return parsed;
}

function PepBadge(props) {
    if (props?.pep?.flag === 'negative') {
        return (
            <Tooltip title={props.pep.description} placement='right'>
                <S.RedBadge>
                    Sim
                </S.RedBadge>
            </Tooltip>
        )
    } else if (props?.related_pep?.flag === 'negative') {
        return (
            <Tooltip title={props.related_pep.description} placement='right'>
                <S.RedBadge>
                    Sim
                </S.RedBadge>
            </Tooltip>
        )
    } else if (props?.pep?.flag === 'positive') {
        return (
            <Tooltip title={props.pep.description} placement='right'>
                <S.GreenBadge>
                    Não
                </S.GreenBadge>
            </Tooltip>
        )
    }
    return (<>-</>)
}

function LegalPartner (props) {

    const classes = useStyles();
    useTheme();

    const [partner, setPartner] = useState();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const retrievePartner = useCallback(async () => {
        if (props?.legal_person.legal_person_key) {
            setLoading(true);
            const response = await axios.get(`/dash/onboarding/legal_person/${props.legal_person.legal_person_key}`)
            if (response?.status === 200) {
                setPartner(response.data);
                setLoading(false);
            } else {
                console.error(response);
                setError(true);
                setLoading(false)
            }
        } else {
            setPartner(props.legal_person);
            setLoading(false);
        }
    }, [props.legal_person])

    useEffect(() => {retrievePartner()}, [retrievePartner]);

    if (error || loading) {
        return (
            <></>
        );
    }

    if (partner) {

        const parsedPartner = parseLegalPartner(partner, props.legal_person.is_bureau_partner, props.legal_person.is_payload_partner);
        const greater = (parsedPartner.emails || []).length > (parsedPartner.phones || []).length ? parsedPartner.emails : parsedPartner.phones

        return (
            <>
                <TableRow
                    key={props.index}
                    className={getTableRowClass(props.index)}
                    style={expanded ? { border: "none" } : {}}
                >
                    <TableCell className={classes.tableText} style={expanded ? { border: "none" } : {}}>
                        {parsedPartner.legal_person_key !== undefined ?
                            <Tooltip title={`${parsedPartner.has_negative_flags ? 'Há' : 'Não há'} apontamentos para essa análise de sócio`} placement="right">
                                <div style={{display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center', color: "#0b1f82", width: 'fit-content'}}>
                                    {parsedPartner.has_negative_flags ?
                                        <ErrorOutline style={{color: '#D91A1A', width: "16px", height: "16px", margin: 0}} /> :
                                        <Check style={{color: '#73c580', width: "16px", height: "16px", margin: 0}} />
                                    }
                                    <Link className="link" to={"/legal-person/" + parsedPartner.legal_person_key}>
                                        <p style={{margin: 0}}>{parsedPartner.legal_name}</p> 
                                    </Link>
                                </div>
                            </Tooltip> : <p style={{margin: 0}}>{parsedPartner.legal_name}</p>
                        }
                    </TableCell>
                    <TableCell className={classes.tableText} style={expanded ? { border: "none" } : {}}>{parsedPartner.document_number}</TableCell>
                    <TableCell className={classes.tableText} style={expanded ? { border: "none" } : {}}>{(parsedPartner.emails || ['-'])[0]}</TableCell>
                    <TableCell className={classes.tableText} style={expanded ? { border: "none" } : {}}>{(parsedPartner.phones || ['-'])[0]}</TableCell>
                    <TableCell className={classes.tableText} style={expanded ? { border: "none" } : {}}>
                        { parsedPartner?.receita_federal_status?.flag === 'positive' &&
                            <Tooltip title={parsedPartner.receita_federal_status.description.replace('Receita Federal - ', '')} placement='right'>
                                <S.GreenBadge>
                                    {capitalize(parsedPartner.receita_federal_status.fiscal_situation)}
                                </S.GreenBadge>
                            </Tooltip>
                        }
                        { parsedPartner?.receita_federal_status?.flag === 'negative' &&
                            <Tooltip title={parsedPartner.receita_federal_status.description.replace('Receita Federal - ', '')} placement='right'>
                                <S.RedBadge>
                                    {capitalize(parsedPartner.receita_federal_status.fiscal_situation)}
                                </S.RedBadge>
                            </Tooltip>
                        }
                        { !parsedPartner?.receita_federal_status ? '-' : <></> }
                    </TableCell>
                    <TableCell className={classes.tableText} style={expanded ? { border: "none" } : {}}>
                        <Tooltip placement="right" title={getAnalysisStatus(parsedPartner.analysis_status)}>
                            <div className={["circle", "small"].join(" ")} style={{backgroundColor: getAnalysisStatusColor(parsedPartner.analysis_status)}}>
                                {getAnalysisStatusSiglum(parsedPartner.analysis_status)}
                            </div>  
                        </Tooltip>
                    </TableCell>
                    <TableCell className={classes.tableText} style={expanded ? { border: "none" } : {}}>
                        <TranslateValidation bureau={parsedPartner.bureau} payload={parsedPartner.payload} />
                    </TableCell>
                    <TableCell className={classes.tableText} style={expanded ? { border: "none" } : {}} onClick={() => (greater || []).length > 1 ? setExpanded(!expanded) : {}}>
                        { (greater || []).length > 1 ? (expanded ? <S.Toggle>-</S.Toggle> : <S.Toggle>+</S.Toggle>) : <></> }
                    </TableCell>
                </TableRow>
                {expanded && (greater || []).map((_, index) => {
                    
                    if (index === 0) return <></>

                    return (
                        <TableRow
                            key={props.index}
                            className={getTableRowClass(props.index)}
                            style={index === greater.length - 1 ? {} : { border: "none" }}
                        >
                            <TableCell className={classes.tableText} style={index === greater.length - 1 ? {} : { border: "none" }}></TableCell>
                            <TableCell className={classes.tableText} style={index === greater.length - 1 ? {} : { border: "none" }}></TableCell>
                            <TableCell className={classes.tableText} style={index === greater.length - 1 ? {} : { border: "none" }}>{index < parsedPartner.emails.length ? parsedPartner.emails[index] : ''}</TableCell>
                            <TableCell className={classes.tableText} style={index === greater.length - 1 ? {} : { border: "none" }}>{index < parsedPartner.phones.length ? parsedPartner.phones[index] : ''}</TableCell>
                            <TableCell className={classes.tableText} style={index === greater.length - 1 ? {} : { border: "none" }}></TableCell>
                            <TableCell className={classes.tableText} style={index === greater.length - 1 ? {} : { border: "none" }}></TableCell>
                            <TableCell className={classes.tableText} style={index === greater.length - 1 ? {} : { border: "none" }}></TableCell>
                            <TableCell className={classes.tableText} style={index === greater.length - 1 ? {} : { border: "none" }}></TableCell>
                        </TableRow>
                    )
                })}
            </>
        );
    }
    
    return <></>;
}

function parseLegalPartner(partner, isBureau, isPayload) {
    
    function parsePhoneNumbers(phone_number_array) {
        const phones = []
        for (let i = 0; i < phone_number_array.length; i++) {
            let formatted_phone_string = '';
            if (phone_number_array[i]?.area_code) {
                if (phone_number_array[i]?.international_dial_code) {
                    formatted_phone_string = formatted_phone_string + `+${phone_number_array[i].international_dial_code} `
                }
                formatted_phone_string = formatted_phone_string + `(${phone_number_array[i].area_code}) `
            }
            if (phone_number_array[i]?.number) {
                let formatted_number_string = phone_number_array[i].number;
                if (phone_number_array[i].number.length === 9) {
                    formatted_number_string = phone_number_array[i].number.slice(0, 5) + '-' + phone_number_array[i].number.slice(5)
                } else if (phone_number_array[i].number.length === 8) {
                    formatted_number_string = phone_number_array[i].number.slice(0, 4) + '-' + phone_number_array[i].number.slice(4)
                }
                formatted_phone_string = formatted_phone_string + formatted_number_string
            }
            phones.push(formatted_phone_string);
        }

        return phones;
    }

    function parseEmails(email_array) {
        const emails = [];
        for (let i = 0; i < email_array.length; i++) {
            emails.push(email_array[i]?.email);
        }
        return emails;
    }

    if (!partner.legal_person_key) {
        return {
            document_number: partner.document_number,
            legal_name: partner.name,
            analysis_status: "not_analised",
            bureau: isBureau,
            payload: isPayload
        }
    }

    const valid_index = retrieve_latest_valid_analysis_status_event_index(partner.analysis_status_events || []);
    
    const parsed = {
        legal_person_key: partner.legal_person_key,
        analysis_status: partner?.analysis_status,
        document_number: partner?.document_number,
        legal_name: capitalize(partner.analysis_status_events[valid_index]?.decision_metadata?.registration_data?.legal_name?.description ?? '-'),
        phones: parsePhoneNumbers(partner.analysis_status_events[valid_index]?.decision_metadata?.registration_data?.phone_number ?? []),
        emails: parseEmails(partner.analysis_status_events[valid_index]?.decision_metadata?.registration_data?.['e-mail'] ?? []),
        foundation_date: partner.analysis_status_events[valid_index]?.decision_metadata?.registration_data?.foundation_date?.description ?? '-',
        receita_federal_status: partner.analysis_status_events[valid_index]?.decision_metadata?.receita_federal_status,
        has_negative_flags: hasNegativeFlags(partner.analysis_status_events[valid_index]?.decision_metadata),
        bureau: isBureau,
        payload: isPayload
    }

    return parsed;
}

function hasNegativeFlags(metadata) {
    if (typeof metadata !== 'object' || metadata === null) {
        return false;
      }
    
      for (const key in metadata) {
        if (key === 'flag' && metadata[key] === 'negative') {
          return true;
        }
        if (typeof metadata[key] === 'object' && hasNegativeFlags(metadata[key])) {
          return true;
        }
      }
    
      return false;
    }