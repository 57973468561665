import React, {useContext} from "react";
import FilterBox from "../../../Utils/FilterBox/FilterBox"
import CompaniesContext from "../../../../context/companies-context"

function Filter(props) {
	
    const { setPageParams } = props

    const companies= useContext(CompaniesContext)?.pix_companies?.companies

    let filterTypes = {
        event_date: {
            type: "date",
            disabled: false,
            validation:false,
            validation_rule: null,
            description: "Data"
        },
        analysis_status: {
            type: "enum",
            enumList: [
                ["Pendente", "pending"],
                ["Aprovado Manualmente", "manually_approved"],
                ["Reprovado Manualmente", "manually_reproved"],
                ["Aprovado Automaticamente", "automatically_approved"],
                ["Reprovado Automaticamente", "automatically_reproved"],
                ["Aprovado por tempo", "approved_by_time"],
                ["Reprovado por tempo", "reproved_by_time"]
            ],
            disabled: false,
            validation:false,
            description: "Status da Análise"
        },
        order_by: {
            type: "enum",
            enumList: [
                  ["Data", "event_date"],
              ],
            description: "Ordenação por"
          },
        sort_order: {
            type: "enum",
            enumList: [
                ["Ascendente", "asc"],
                ["Descendente", "desc"]
            ],
            description: "Tipo de Ordenação"
        }
    }

    if (companies){
        let enumList = companies
                            .sort((a, b) => a.name === b.name ? 0 : a.name > b.name ? 1 : -1)
                            .map(company =>{return [company.name, company.company_key]})

        filterTypes['company_key'] = {
            type: "enum",
            enumList: [
                ...enumList
            ],  
            disabled: false,
            validation:false,
            description: "Cliente"
        }
    }

    return (
        <FilterBox 
            setPageParams={setPageParams}
            filterTypes={filterTypes}
            local_storage_object_preffix={props.local_storage_object_preffix}
        />
    )
}

export default Filter