import React, { useState, useEffect, Suspense } from "react"
import MainRouter from "./containers/MainRouter/MainRouter"
import Keycloak from "keycloak-js"
import AuthContext from "./context/auth-context"
import axios from "axios"
import Favicon from "./containers/Favicon/Favicon"
import "./i18n"
import { datadogRum } from '@datadog/browser-rum'
import OfflineWarningScreen from "./components/common/OfflineWarningScreen"

function App() {

  function setUserInDatadog(id, name, email) {
    datadogRum.setUser({
      id,
      name,
      email,
    });
  }

  axios.defaults.baseURL = process.env.REACT_APP_DASH_API_URL + ":" + process.env.REACT_APP_DASH_API_PORT
  const [keyCloakState, setKeycloakState] = useState({ keycloak: null, authenticated: false })

  if (process.env.REACT_APP_ENV !== 'local') {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'client-dashboard',
      env: process.env.REACT_APP_ENV,
      //  version: '1.0.0',
      sampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackInteractions: true
    })

    datadogRum.startSessionReplayRecording()
  }

  const [language, setLanguage] = useState()
  const [maxSessionTime, setMaxSessionTime] = useState(null)

  useEffect(() => {
    setMaxSessionTime(Date.now() + (12 * 3600 * 1000)) // 12 hours
    localStorage.removeItem("@kyc_session");
    sessionStorage.clear();
  }, [])


  useEffect(() => {
    if (keyCloakState.keycloak) {
      setInterval(() => {
        if (Date.now() > maxSessionTime) {
          localStorage.removeItem("@kyc_session");
          sessionStorage.clear();
          keyCloakState.keycloak.logout()
        } else if (Date.now() - (40 * 1000) >= keyCloakState.keycloak.tokenParsed.exp) {
          keyCloakState.keycloak.updateToken(40).then((updated) => { // If the token will expire in 30 seconds
            if (updated) {
              setKeycloakState({ keycloak: keyCloakState.keycloak, authenticated: updated, user_data: keyCloakState.user_data })
            }
          })
        }
      }, 5 * 1000); // At each 5 seconds
    }
  }, [keyCloakState, maxSessionTime])

  const [authorized, setAuthorized] = useState(false);
  // const [offlineTicks, setOfflineTicks] = useState(0);

  useEffect(() => {
    if (keyCloakState.keycloak) {
      axios.defaults.headers = { "Authorization": keyCloakState.keycloak.token };
      setAuthorized(true);
    } else {
      axios.defaults.headers = { "Authorization": null };
      setAuthorized(false);
    }
  }, [keyCloakState])

  useEffect(() => {
    const keycloak = Keycloak("/keycloak.json")
    keycloak
      .init({ onLoad: "login-required" })
      .then(authenticated => {
        let user_data = {}
        user_data["username"] = keycloak.tokenParsed.preferred_username
        user_data["company_key"] = keycloak.tokenParsed.company_key
        user_data["business_group_key"] = keycloak.tokenParsed.business_group_key
        user_data["email"] = keycloak.tokenParsed.email
        user_data["name"] = keycloak.tokenParsed.name
        user_data["first_name"] = keycloak.tokenParsed.given_name
        user_data["last_name"] = keycloak.tokenParsed.family_name
        user_data["roles"] = keycloak.tokenParsed.realm_access.roles
        user_data["user_key"] = keycloak.tokenParsed.sub
        user_data["locale"] = keycloak.tokenParsed.locale
        user_data["rental_store"] = keycloak.tokenParsed.rental_store;

        setUserInDatadog(user_data?.user_key, user_data?.first_name + ' ' + user_data?.last_name, user_data?.email)

        keycloak.tokenParsed.locale ? setLanguage(keycloak.tokenParsed.locale) : setLanguage('pt-BR')
        setKeycloakState({
          keycloak: keycloak,
          authenticated: authenticated,
          user_data: { ...user_data }
        })
      })
      .catch(() => {
        console.log("Keycloak Authentication catch")

        setKeycloakState({ keycloak: null, authenticated: null, user_data: null })
      })
  }, [])

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     fetch('https://httpbin.org/get', { method: 'GET' }).then(() => {
  //       setOfflineTicks(0);
  //     }).catch((error) => {
  //       if (error.status === null || error.status === undefined) {
  //         setOfflineTicks(Math.min(offlineTicks + 1, 3));
  //       } else {
  //         setOfflineTicks(0);
  //       }
  //     });
  //   }, 2000);
  //   return () => clearInterval(intervalId);
  // });

  let main = null
  if (!keyCloakState.keycloak) {
    main = (
      <div />
    )
  } else if (keyCloakState.authenticated && authorized) {
    main = (
      <AuthContext.Provider
        value={{
          keycloak_adapter: keyCloakState.keycloak,
          authenticated: keyCloakState.authenticated,
          user_data: keyCloakState.user_data,
        }}
      >
        <Favicon />
        <Suspense falllback={<div>Loading ...</div>}>
            <OfflineWarningScreen $hide={true/*offlineTicks < 3*/}/>
            <MainRouter language={language} setLanguage={setLanguage}/>
        </Suspense>
      </AuthContext.Provider>
    )
  } else {
    main = (
      <div
        style={{
          backgroundColor: "#000000",
          height: "auto",
          width: "auto",
          color: "#FFFFFF"
        }}
      >
        Autenticação falhou!!!
      </div>
    )
  }

  return (
    <div className="App">
      {main}
    </div>
  )
}
export default App
