import React, {useState} from 'react'
import Account from "./Account/Account"
import Tooltip from "@material-ui/core/Tooltip"
import peopleGreen from '../../../../assets/cardEntryIcons/peopleGreen.svg';
import ErrorBoundary from '../../../Utils/ErrorBoundary'
import StatisticsTableV1 from './StatisticsTable/StatisticsTableV1';
import StatisticsTableV2 from './StatisticsTable/StatisticsTableV2';

function EnvolvedParties(props) {
    const [statisticsType, setStatisticsType] = useState("key")

    return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <span className={["blueText", "subtitleSize"].join(" ")}>Partes Envolvidas</span>      
            </div>
            <div className="internalCardContainer" style={{display: "flex"}}>
                <div style={{width: "50%", display: "flex", flexDirection: "column", margin: "5px"}}>
                    <div style={{display: "flex"}}>
                        <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>Pagador</span>
                        {props.transaction.event_data.transaction_direction === "sent"?
                        <Tooltip title={<p className="tooltipText">Cliente</p>}>
                            <div style={{display: "flex", margin: "auto auto auto 10px"}}>
                                <img className="pinIconStyle" src={peopleGreen} alt="clientIcon Logo" />  
                            </div>
                        </Tooltip> 
                        :null}        
                    </div>
                    <Account transaction={props.transaction.event_data} account_type={"source"} />
                </div>
                <div style={{width: "50%", display: "flex", flexDirection: "column", margin: "5px"}}>
                    <div style={{display: "flex"}}>
                        <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>Recebedor</span>
                        {props.transaction.event_data.transaction_direction === "received"?
                        <Tooltip title={<p className="tooltipText">Cliente</p>}>
                            <div style={{display: "flex", margin: "auto auto auto 10px"}}>
                                <img className="pinIconStyle" src={peopleGreen} alt="clientIcon Logo" />  
                            </div>
                        </Tooltip> 
                        :null}        
                    </div>
                    <Account transaction={props.transaction.event_data} account_type={"destination"} />
                </div>
            </div>
            {props.transaction.event_data.destination_statistics ? 
            <div className="internalCardContainer">
                <div className={["blueText", "subtitleSize"].join(" ")}>Estatísticas do Recebedor</div>
                <ErrorBoundary>
                <div className="internalCardContainer" style={{display:"flex", justifyContent: "center"}}>
                        <div 
                            className={["blueText", "normalMediumSize", "link"].join(" ")}
                            style={statisticsType === "key" ? {margin:"10px", padding: "0 5px"} : {color: "#C4C4C4", margin:"10px", padding: "0 5px"}}
                            onClick={()=>setStatisticsType("key")}
                        >
                            Chave
                        </div>
                        {props.transaction.event_data.destination_statistics.account ?
                            <div 
                                className={["blueText", "normalMediumSize", "link"].join(" ")}
                                style={statisticsType === "account" ? {margin:"10px", padding: "0 5px"} : {color: "#C4C4C4", margin:"10px", padding: "0 5px"}}
                                onClick={()=>setStatisticsType("account")}
                            >
                                Conta
                            </div>
                        : null}
                        <div 
                            className={["blueText", "normalMediumSize", "link"].join(" ")}
                            style={statisticsType === "owner" ? {margin:"10px", padding: "0 5px"} : {color: "#C4C4C4", margin:"10px", padding: "0 5px"}}
                            onClick={()=>setStatisticsType("owner")}
                        >
                            Proprietário
                        </div>
                    </div>
                    <div className="internalCardContainer" style={{display: "flex", justifyContent: "center"}}>
                        {props.transaction.event_data.destination_statistics.account ?
                        <StatisticsTableV1 statisticsType={statisticsType} destination_statistics={props.transaction.event_data.destination_statistics}/>
                        : <StatisticsTableV2 statisticsType={statisticsType} destination_statistics={props.transaction.event_data.destination_statistics}/> }
                    </div>
                </ErrorBoundary>
            </div> : null}


        </div>
    )
}

export default EnvolvedParties