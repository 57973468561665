import React, {useState} from "react";
import EmailOtherAccountsTable from "./EmailOtherAccountsTable/EmailOtherAccountsTable";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import IconButton from "@material-ui/core/IconButton";
import {getCardIcon} from "../../utils/utils";
import { useTranslation } from "react-i18next";

function EmailOtherAccounts(props) {
    const { t } = useTranslation()

    const [expanded, setExpanded] = useState(false);
    const [firstTime, setFirstTime] = useState(true);

    const {email_other_accounts_data} = props
    let initialExpand = email_other_accounts_data.flag === "positive"? false : true
    if (initialExpand !== expanded && firstTime===true){
        setExpanded(initialExpand);
    }
    const handleExpand = (event) => {
        event.stopPropagation();
        setFirstTime(false)
        setExpanded(!expanded);
    };

    return (
        <div className="analysisCard">
            <div style={{display:"flex"}}>
                <div 
                    className={["blueText", "subtitleSize", "bold"].join(" ")}
                    style={{margin:"auto 0px"}}
                >
                    {t("email_other_accounts")}
                </div>
                <div style={{margin:"auto 10px"}}>
                    {getCardIcon(email_other_accounts_data.flag)}
                </div>
                {email_other_accounts_data.data.length === 0 ? null :
                <IconButton style={{marginLeft: "auto"}} onClick={handleExpand}>
                    {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>             
                }                    
            </div>
            {email_other_accounts_data.data.length === 0 ? null :
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <div 
                    className={["normalText", "normalMediumSize", "internalCardContainer"].join(" ")}
                >
                    <EmailOtherAccountsTable email_other_accounts_data={email_other_accounts_data.data}/>
                </div>
            </Collapse> 
            }     
        </div>
        );
}

export default EmailOtherAccounts