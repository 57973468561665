import React from 'react'
import {getSeverityColor,getSeverityStatus} from "../../../../utils/utilsAlert"
import moment from "moment"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {Tooltip, IconButton} from "@material-ui/core";

function AccountMonitoringTrigger(props){

    let {triggers} = props

    if (triggers == null || triggers.length ===0){
        return (
            <div style={{display: "flex", flexGrow: "1"}}>
                <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                    Nenhum gatilho disponível
                </div>
            </div>
        )
    }
    
    if (triggers){
        return (
            <div className="internalCardContainer">
                {triggers.map((trigger,index)=>(
                <div key={index} style={{display: "flex", margin: "5px auto"}}>
                        <Tooltip title={<p className="tooltipText">{getSeverityStatus(trigger.risk_level)}</p>}>
                            <div style={{display:"flex", margin:"auto 0px"}}>
                                <ErrorOutlineIcon style={{color:getSeverityColor(trigger.risk_level)}}/>
                            </div>
                        </Tooltip>
                    <div className="labelSpace">
                            <div className={["normalText", "normalMediumSize3"].join(" ")}>
                                {"Cliente com Documento de N° " + trigger.trigger_group.search_data.client_document_number + " | " + moment(trigger.trigger_group.occurrence_date).format("DD/MM/YYYY - HH:mm:ss")}
                            </div>
                        <div className={["labelText", "normalSmallSize"].join(" ")} >
                            {trigger.description}
                        </div>
                    </div>   
                    <Tooltip title={<p className="tooltipText">{"Quantidade de gatilhos por tipo "}</p>}>
                        <div>
                            <IconButton size="small" >
                                {trigger.category_quantity}
                            </IconButton>
                        </div> 
                    </Tooltip>  
                </div>
                ))}
            </div>
        )        
    }
    return null

}

export default AccountMonitoringTrigger