import React, { useEffect, useContext, useReducer, useState } from "react";
import { dataFetchReducer } from "../../../Utils/requestUtils"
import { useParams } from "react-router-dom";
import axios from "axios";
import ErrorBoundary from "../../../Utils/ErrorBoundary"
import AuthContext from "../../../../context/auth-context"
import { useHistory } from "react-router-dom"
import moment from 'moment'
import { formatAccountType } from "../../utils/utilsAlert";
import AccountMonitoringAlertsCard from "./AlertsCard/AccountMonitoringAlertsCard";
import AccountMonitoringObservationCard from "./AccountMonitoringObservationCard/AccountMonitoringObservationCard";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Tooltip from "@material-ui/core/Tooltip";
import * as S from "./styles"

function AccountMonitoringAlert(props) {

    let { alert_key } = useParams();

    let history = useHistory()

    let roles = useContext(AuthContext).user_data.roles

    if (!roles.includes("read_account_monitoring_alerts")) {
        history.push("/account-monitoring-alerts-inbox/")
    }

    const [alert, dispatchAlert] = useReducer(
        dataFetchReducer,
        { fetchedData: null, isLoading: true, isError: false }
    )

    useEffect(() => {
        dispatchAlert({ type: "data_fetch_init" })
        const timer = setTimeout(() => {
            axios.get('/dash/alerts/alert/' + alert_key).then(response => {
                dispatchAlert({
                    type: "data_fetch_success",
                    payload: response.data
                })
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchAlert({ type: "data_fetch_failure_403" })
                else if ((error.response || {}).status === 404) dispatchAlert({ type: "data_fetch_failure_404" })
                else dispatchAlert({ type: "data_fetch_failure" })
            })
        }, 500);
        return () => {
            clearTimeout(timer)
        }
    }, [alert_key])

    const alertsArray = JSON.parse(sessionStorage.getItem('alertsArray')) || []

    const [indexState, setIndexState] = useState(alertsArray.findIndex(alert => alert === alert_key))

    const handleNextAlertButtonClick = () => {
        let index = alertsArray.findIndex(alert => alert === alert_key)
        setIndexState(index + 1)
        alert_key = alertsArray[index + 1]
        history.push("/account-monitoring-alert/" + alert_key)
    }

    const handlePreviousAlertButtonClick = () => {
        let index = alertsArray.findIndex(alert => alert === alert_key)
        setIndexState(index - 1)
        alert_key = alertsArray[index - 1]
        history.push("/account-monitoring-alert/" + alert_key)
    }

    if (alert.fetchedData) {
        return (
            <div style={{ minWidth: "900px", paddingBottom: "30px" }}>
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <div>
                            <div className={["blueText", "titleSize2"].join(" ")} style={{ margin: "5px 0px" }}>
                                {"Alerta " + alert.fetchedData.link_key}
                            </div>
                            <div className={["labelText", "subtitleSize"].join(" ")} style={{ margin: "5px 0px" }}>
                                {moment(alert.fetchedData.occurrence_date).format("DD/MM/YYYY - HH:mm:ss")}
                            </div>
                        </div>
                    </ErrorBoundary>
                </div>
                <div className="analysisCardContainer" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div>
                        {indexState !== 0 &&
                            <Tooltip title={<p className="tooltipText">Alerta Anterior</p>}>
                                <div
                                    className={["circle", 'small'].join(" ")}
                                    style={{ backgroundColor: 'rgb(211, 206, 206)', cursor: "pointer" }}
                                    onClick={handlePreviousAlertButtonClick}
                                >
                                    <ArrowBack />
                                </div>
                            </Tooltip>}
                    </div>
                    <div>
                        {indexState !== (alertsArray.length - 1) &&
                            <Tooltip title={<p className="tooltipText">Próximo Alerta</p>}>
                                <div
                                    className={["circle", 'small'].join(" ")}
                                    style={{ backgroundColor: 'rgb(211, 206, 206)', cursor: "pointer", marginRight: "1px" }}
                                    onClick={handleNextAlertButtonClick}
                                >
                                    <ArrowForward />
                                </div>
                            </Tooltip>}
                        {indexState === (alertsArray.length - 1) &&
                            <div
                                className={["button", "onlyboarder", "normalMediumSize"].join(" ")}
                                style={{ cursor: "pointer", marginRight: "1px", width: "150px" }}
                                onClick={() => history.push("/account-monitoring-alerts-inbox")}
                            >
                                Voltar para Alertas
                            </div>
                        }
                    </div>
                </div>
                <div>
                    <div className="analysisCardContainer">
                        <ErrorBoundary>
                            <AccountMonitoringAlertsCard alert={alert.fetchedData} />
                        </ErrorBoundary>
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <div className="analysisCardContainer" style={{ minHeight: "170px", display: "flex", flexGrow: 1 }}>
                        <ErrorBoundary>
                            <AccountMonitoringObservationCard alert={alert.fetchedData} />
                        </ErrorBoundary>
                    </div>
                </div>
                <div className="analysisCardContainer">
                    <ErrorBoundary>
                        <S.AccessAccountButton
                            onClick={() => history.push("/" + formatAccountType(alert.fetchedData.triggers[0].category) + "/" + alert.fetchedData.link_key)}>
                            Acessar Conta
                        </S.AccessAccountButton>
                    </ErrorBoundary>
                </div>
            </div>
        )
    }
    else if (alert.isError) {
        return (
            <div style={{ height: "80vh", display: "flex" }}>
                <p className={["blueText", "titleSize"].join(" ")} style={{ margin: "auto", textAlign: "center" }}>
                    {alert.errorMessage}
                </p>
            </div>
        )
    }
    else return null;
}

export default AccountMonitoringAlert